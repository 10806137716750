.orders-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;  background: @c-grey-default;
  min-height: 80%;
  min-width: 70rem;

  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;
}