.finished-phase {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img.image {
    width: 7rem;
  }

  span.title-text {
    text-transform: uppercase;
    margin-top: 3rem;
    font-weight: 700;
    font-size: @font-22;
    color: @c-text-primary;
    text-align: center;
  }
  span.subtitle-text {
    padding-top: 1.5rem;
    font-weight: 400;
    font-size: @font-16;
    color: @c-text-primary;
    text-align: center;
    margin-bottom: 1rem;
  }
  button {
    margin-top: 0.5rem !important;
  }
}
