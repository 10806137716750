.admin-contact-login-container {
  background: @c-grey-default;
  margin: 1.5rem 3rem;
  height: 80%;

  border-radius: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .selected-user-data-card {
    width: min(80%, 40rem);
    padding: 1rem 2rem;
    border-radius: 16px;

    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: @c-white;

    display: flex;
    flex-direction: column;

    .text-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin-bottom: 1rem;

      .title {
        text-align: center;
        font-weight: 600;
        font-size: @font-22;
        color: @c-text-primary;
        line-height: 1.5;
      }
    }

    .ui.selection.dropdown {
      width: 100%;
    }

    &-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;

      font-weight: 500;
      font-size: @font-16;
      color: @c-text-primary;

      svg {
        margin-right: 1rem;
      }
    }
  }

  .contact-type-switch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .single-contact-type {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .MuiCheckbox-colorPrimary.Mui-checked {
        color: @c-secondary !important;
      }

      .MuiCheckbox-colorPrimary.Mui-disabled {
        color: rgba(0, 0, 0, 0.06);
      }
    }
  }
}
