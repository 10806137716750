div.page-header {
  // Display flex on a row and align intems at vertical center
  align-items: center;
  display: flex;
  flex-direction: row;

  margin: 0rem 2rem;
  padding: 1.5rem 0rem;

  // Title style
  span.title {
    font-family: @sans-serif;
    color: @c-white;
    font-size: @font-28;
    font-weight: 500;
  }

  img.image.title-icon {
    height: 80%;
    padding-left: 1rem;
  }

  div.right-icon {
    // Align element on right side
    margin-left: auto;
  }
}

div.page-header.border-bottom {
  border-bottom: 1px solid rgba(255,255,255,.25);
}
