/* 
 * MODAL
 * General settings for Semantic's modal component
 * For set a specific style to a modal assign a new class to the element 
 */
div.ui.page.modals {
  // Blurry background
  background-color: linear-gradient(
    287.15deg,
    #3c3c3b 0%,
    rgba(60, 60, 59, 0.88) 100%
  );
  font-family: @sans-serif !important;

  // Standard modal style
  div.ui.modal {
    border-radius: 2px;
    padding: 1.5rem 2rem;
    min-width: 25rem;

    // Set fixed position and dimension
    div.close-button {
      position: fixed;
      top: 0.8rem;
      right: 0.8rem;

      width: 1.5rem;
      height: 1.5rem;

      cursor: pointer;

      display: flex;
      text-align: center;
      justify-content: center;

      img.image {
        width: 1rem;
      }
    }

    > div.header {
      // FONT
      color: @c-primary;
      font-weight: 600;
      font-size: @font-22;

      // BORDER AND BACKGROUND
      background-color: transparent;
      border-bottom: none;

      display: flex;

      // Set margin to image for divide from modal title
      img.image {
        margin-right: 1rem;
      }
    }

    div.actions {
      // Allign items in the horizontal center
      display: flex;
      justify-content: center;

      background-color: @c-white;
      border: 0;

      // Divide reset button from submit button
      #formik-semantic-ui-react-reset-button {
        margin-right: 1rem;
      }

      button {
        margin: 0;
      }
    }
  }
}
