#app {
  .contact-origins-list {
    &-page-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin: 0rem 2rem;
      padding: 1.5rem 0rem;

      // Title style
      span.title {
        font-family: @sans-serif;
        color: @c-white;
        font-size: @font-28;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
    }

    &-container {
      margin: 0rem 3rem;
    }
  }

  .contact-origins-list-segment {
    background-color: @c-grey-default;
    padding: 1.5rem !important;
    min-width: 50rem;
  }

  .contact-origins-subtitle {
    color: @c-text-primary;
    font-size: @font-18;
    font-weight: 500;
    padding-bottom: 0.5rem;
  }
}
