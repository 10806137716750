div.ui.segment.card-grid-header {
  padding: 0rem 1rem 0rem 1rem !important;
}

// Small cards
.ui.segment.card {
  min-height: 15rem;
  color: @c-primary;
  width: auto;
  height: auto;
  background-color: rgba(242, 242, 242, 1) !important;
  margin: 0 !important;
  padding: 0.8rem !important;
  border-radius: 0.5rem;

  // Round images on the bottom
  .img.ui.image.card-image {
    width: 15rem !important;
    height: 8rem !important;
  }
}

// Switch color of the card and the image when hover
div.ui.segment.card:hover {
  background-color: #fdc300 !important;
  .ui.inline.right.floated.image {
    // White
    filter: brightness(0) invert(1);
  }
}

// Table inside big card
.ui.segment.cardGrid {
  display: inline-flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 1) !important;
  width: 100%;
  height: auto;

  padding: 2.5rem;
  border-radius: 0.5rem;
  right: auto !important;
  .ui.image.card-image {
    display: flex;
    width: 5rem !important;
    height: 8rem !important;
  }
}

table.ui.celled.collapsing.very.basic.table {
  width: 100% !important;
}

img.ui.mini.image {
  padding: 0 !important;
  margin: 0 !important;
}
div.cardGrid {
  display: inline-flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 1) !important;
  width: 100%;
  height: auto;

  border-radius: 0.5rem;
  right: auto !important;
  .ui.image.card-image {
    display: flex;
    width: 5rem !important;
    height: 8rem !important;
  }
}

div.card {
  color: @c-primary;
  width: auto;
  height: auto;
  margin: 0 !important;
  border-radius: 0.5rem;

  .img.ui.image.card-image {
    width: 15rem !important;
    height: 8rem !important;
  }
}

div.cardIcons {
  display: inline-flex;
  flex-direction: row;
  white-space: nowrap !important;
  width: 8rem;
  height: auto;

  border-radius: 0.5rem;
  right: auto !important;
  .ui.image.card-image {
    display: flex;
    width: 5rem !important;
    height: 8rem !important;
  }
  .cardItem {
    display: grid;
    width: auto;
    padding-left: 0rem;
  }
}

div.column {
  flex-grow: 0.2 !important;
}

div.center.aligned.row {
  background-color: white !important;
}

h4.ui.header.cell {
  white-space: normal !important;
}

span.cell {
  display: block;
}

.ui.image.header {
  margin-top: 0rem !important;
}

.ui.segment.card.loading-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
