div.reservation-container {
  background: @c-white;
  min-height: 80%;
  min-width: 70rem;

  margin: 1rem 3rem;
  border-radius: 2px;

  display: flex;
  flex-direction: column;

  .reservation {
    &-content {
      flex: 1 0 auto;
      background: @c-grey-default;

      display: flex;

      &-sidebar {
        max-width: 16rem;
        min-width: 16rem;
        background: white;
        display: flex;
        justify-content: center;
        border-right: 1px solid @c-border-default;
      }

      &-form {
        display: flex;
        justify-content: center;
        padding: 2rem 4rem;
        width: 100%;
      }
    }

    &-footer {
      border: 1px solid @c-border-default;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      padding: 1rem 2rem;
    }
  }

  /*
   * Standard style for the header of the various reservation's pages
   * 
   * For use it add the following code
   * <div className="reservation-header">
   *   <div className="reservation-header-title">Your title</div>
   *   <div className="reservation-header-title">Your subtitle</div>
   * </div>
   */
  .reservation-header {
    display: flex;
    flex-direction: column;

    color: @c-text-primary;

    padding-left: 0.2rem;
    margin-bottom: 1rem;

    &-title {
      font-weight: 600;
      font-size: @font-24;
      margin-bottom: 0.5rem;
    }

    &-subtitle {
      font-weight: 500;
      font-size: @font-18;
    }
  }

  /*
   * Standard style for single row with two input fields with icon
   *
   * An example of implementation
   * <div className="reservation-fields-row">
   *   <div className="fields-with-icon">
   *     <SvgIcon ... />
   *     <div className="fields-content">
   *       <Input//Select ... />
   *     </div>
   *   </div>
   *   <div className="fields-with-icon">
   *     <SvgIcon ... />
   *     <div className="fields-content">
   *       <Input//Select ... />
   *     </div>
   *   </div>
   * </div>
   */
  .reservation-fields-row {
    display: flex;
    align-items: center;

    margin-bottom: 1.5rem;

    .fields-with-icon {
      display: flex;
      align-items: flex-start;

      padding: 0.5rem 0;
      width: 50%;

      // Allign SVG icon in the correct position
      svg {
        margin-top: 2.2rem;
      }

      .disabled.field {
        max-width: 35rem;
        opacity: 1;

        label {
          color: @c-text-primary !important;
          font-weight: 500 !important;
          opacity: 1;
        }
      }

      .fields-content {
        width: 100%;
        padding-left: 0.5rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;

        text-align: left;

        .field {
          max-width: 35rem;

          label {
            color: @c-text-primary !important;
            font-weight: 500 !important;
          }

          .ui.disabled.input {
            opacity: 1 !important;
          }
        }

        .input-uppercase {
          input {
            text-transform: uppercase;
          }

          ::placeholder {
            text-transform: none;
          }
        }

        .disabled.field {
          max-width: 35rem;
          opacity: 1;

          label {
            color: @c-text-primary !important;
            font-weight: 500 !important;
            opacity: 1;
          }
        }
      }
    }
  }
}
