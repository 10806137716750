.delivery-information {
  width: 100%;

  &-content {
    .orders-filters-bar-container {
      margin: 0;
    }

    .reservation-form-divider {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid @c-border-default;
      margin: 1.5rem 0;
      padding: 0;
    }
  }

  .delivery-fields-description {
    font-size: @font-14;
    color: @c-text-primary !important;
    font-weight: 400;
  }

  input:read-only {
    background: @c-white !important;
    cursor: pointer;
  }

  .reservation-border-top {
    border-top: 1px solid @c-grey-border;
    padding-top: 0.5rem;
  }

  .reservation-timeslot-description {
    margin-left: 2rem;
    padding: 1.5rem;
    border-left: 2px solid @c-secondary;
    width: 50%;

    color: @c-text-primary;
    font-weight: 500;
    font-size: @font-16;
  }
}