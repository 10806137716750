div.sidebar.menu {
  position: fixed;
  width: @width-sidebar;
  max-width: @max-sidebar;
  left: 0px;
  overflow: hidden;
  z-index: 999;
  visibility: visible !important;
  display: block;

  // Colors and style
  background-color: @c-primary;
  box-shadow: 4px 0px 8px rgba(42, 48, 65, 0.04);
  filter: drop-shadow(4px 0px 12px rgba(42, 48, 65, 0.18));

  // Content
  padding-top: 3rem;

  // Size and position
  height: 100%;

  div.menu-section {
    padding: 1rem 1.5rem;
    padding-top: 2.5rem;
    width: 100%;

    text-transform: uppercase;
    color: @c-text-secondary;
    font-size: @font-12;
  }

  div.menu-element {
    display: flex;
    padding: 1rem 1.5rem;
    width: 100%;

    div.element-text {
      position: relative;
      margin-left: 1rem;
      padding-top: 0.3rem;
      font-weight: 400;
      color: @c-white;
      opacity: 0.5;
      font-size: @font-16;
    }
  }

  // Current menu voice item selected
  div.menu-element.selected {
    div.element-text {
      // Remove opacity
      opacity: 1;
    }

    // Border shorter than div
    div.element-text:after {
      content: '';
      background: @c-secondary;
      position: absolute;
      left: 0.2rem;
      width: 2.5rem;
      height: 3px;
      bottom: -0.4rem;
    }
  }

  div.menu-element:hover {
    cursor: pointer;
    background: @c-menu-hover;
  }

  .agent-select-customer {
    position: absolute;
    font-weight: 400;
    color: @c-white !important;
    font-size: @font-16;
    bottom: 0;
    width: 100%;

    opacity: 0.5;

    cursor: pointer;

    display: flex;
    align-items: center;

    padding: 1rem 1.5rem;

    svg {
      margin-right: 1rem;
    }
  }

  .agent-select-customer:hover {
    background: @c-menu-hover;
  }

  .contact-select-profile {
    position: absolute;
    font-weight: 400;
    color: @c-white !important;
    font-size: @font-12;
    bottom: 0;
    width: 100%;

    opacity: 0.8;

    cursor: pointer;

    display: flex;
    align-items: center;

    padding: 1rem 1.5rem;

    svg {
      margin-right: 1rem;
    }
  }

  .contact-select-profile:hover {
    background: @c-menu-hover;
  }
}
