div.emptyReservations {
  padding-top: 3rem;
  text-align: center;
  margin-right: auto;

  img.ui.image {
    display: unset;
    align-items: 'center' !important;
    margin-top: 1rem;
  }
  .ui.header {
    color: @c-primary;
    margin-top: 1.5rem !important;
  }
  .sub.header {
    margin-top: 1.5rem !important;
    margin-bottom: 3rem !important;
  }

  // Button
  .ui.button {
    margin-bottom: 7.8% !important;
    margin-top: 1% !important;
  }
}
