.step-menu-container {
  width: 100%; // Take all the width of the parent
  padding-top: 4rem;

  .step-menu-element {
    display: flex;
    justify-content: flex-end;
    position: relative;

    height: fit-content;
    margin-bottom: 4rem;

    &-text {
      // Align vertical center text
      display: flex;
      align-items: center;
      height: 3rem; // Must be the same as the height of the icon span

      padding-left: 1rem;
      padding-right: 3rem;

      // Font settings
      text-align: end;
      font-size: @font-18;
      font-weight: 500;
      color: @c-text-primary;
    }

    &-icon {
      // Make square with border
      background-color: @c-white;
      border: 1px solid @c-border-default;
      border-radius: 2px;
      height: 3rem;
      width: 3rem;

      // Align items over the line in the center middle
      position: absolute;
      right: -1.5rem; // Must be half of icon width for correct align

      // Align content of this section in the middle
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: @font-16;
      font-weight: 500;
      color: @c-text-primary;
    }
  }

  // Step active style
  .step-menu-element.step-active {
    .step-menu-element-text {
      font-weight: 600;
    }
    .step-menu-element-icon {
      background-color: @c-secondary;
      font-weight: 600;
    }
  }
}
