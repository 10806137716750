.metal-resume-filters-bar {
    width: 95%;
  
    .ui.form {
      display: flex;
      align-items: center;
      justify-content: flex-start;
  
      .field,
      .field:last-child {
        margin-bottom: 0 !important;
      }
  
      #formik-semantic-ui-react-submit-button {
        height: unset !important;
        width: unset !important;
      }
  
      .metal-resume {
        &-number-input {
          background-color: @c-white;
          width: 13rem !important;
          margin-right: 1rem;
          > input[type='text'] {
            border: 1px solid rgba(120, 120, 120, 0.24) !important;
            border-radius: 2px !important;
            padding: 0.8rem 1rem 0.65rem 1rem !important;
          }
        }
  
        &-date-input {
          display: flex;
          align-items: center;
          background-color: @c-white;
          width: 10rem !important;
          margin-right: 1rem;
          > input[type='text'] {
            border: 1px solid rgba(120, 120, 120, 0.24) !important;
            border-radius: 2px !important;
            padding: 0.8rem 1rem 0.65rem 1rem !important;
          }
          .calendar-modal-button {
            cursor: pointer;
            position: absolute;
            right: 0.5rem;
  
            display: flex;
            align-items: center;
  
            border-radius: 100%;
            padding: 0.5rem;
          }
  
          .calendar-modal-button:hover {
            background: @c-grey-light-darker;
          }
        }
  
        .date-input {
          position: fixed;
          display: flex;
          align-items: center;
  
          .calendar-modal-button {
            cursor: pointer;
            position: absolute;
            right: 0.5rem;
  
            border-radius: 100%;
            padding: 0.5rem;
          }
  
          .calendar-modal-button:hover {
            background: @c-grey-light-darker;
          }
        }
  
        &-filters-search,
        &-filters-search:hover {
          button {
            margin-right: 0.5 !important;
            background-color: @c-success !important;
            border-radius: 2px !important;
            color: @c-white !important;
          }
        }
  
        &-filters-search:hover {
          button {
            background-color: @c-success-hover !important;
          }
        }
  
        &-filters-reset {
          padding: 0.5rem !important;
        }
      }
    }
  }
  