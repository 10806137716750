div.footer-layout {
  height: 100%;
  background: @c-white-background;
  text-align: center;

  display: flex;
  justify-content: center;

  div.footer-main-content {
    overflow-y: auto;
    padding-bottom: 5rem;
    background: @c-white;
    height: 100%;
    width: min(70%, 80rem);
  }
}