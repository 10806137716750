.article-phase {
  width: 100%;

  &-content {
    .article-phase-filter-bar {
      margin: 0.5rem;
      width: 95%;
    
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div.field {
        margin: 0;
        margin-right: 2rem;
      }

      &-input {
        background-color: @c-white;
        width: 11rem;
        margin-right: 1rem;
        > input[type='text'] {
          border: 1px solid rgba(120, 120, 120, 0.24) !important;
          border-radius: 2px !important;
          padding: 0.8rem 1rem 0.65rem 1rem !important;
        }
      }
  
      &-search,
      &-search:hover {
        margin-right: 1.5rem !important;
        background-color: @c-success !important;
        border-radius: 2px !important;
        color: @c-white !important;
      }
  
      &-search:hover {
        background-color: @c-success-hover !important;
      }
    }

    .article-phase-paginator {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5rem;

      &-orderLines {
        font-weight: 600;
          font-size: @font-16;
          color: @c-text-primary;
      }
    }
  } 
}
