.base-information {
  width: 100%;

  &-content {
    .orders-filters-bar-container {
      margin: 0;
    }

    .reservation-form-divider {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid @c-border-default;
      margin: 1.5rem 0;
      padding: 0;
    }

    .max-quantity {
      text-align: left;
      font-size: @font-14;
      color: @c-text-primary;
      font-weight: 400;
    }
  }

  .reservation-description-field {
    margin-left: 2rem;
    padding: 1.5rem;
    border-left: 4px solid @c-secondary;

    width: 50%;

    color: @c-text-primary;
    font-weight: 600;
    font-size: @font-16;
  }
}
