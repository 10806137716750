.ui.grid.filterBar {
  padding-bottom: 2rem;
  padding-top: 1rem;
  padding-left: 1rem;

  // Datepicker

  // Filter button
  .filterButton {
    button {
      margin-inline: 1em;
      height: 100%;
    }
  }

  .ui.input {
    input {
      background: white !important;
    }

    margin-right: 1rem;
  }

  // Search button
  .mainButton {
    border-radius: 0.2rem !important;
    background: @c-success !important;
    color: @c-white;
  }
}
