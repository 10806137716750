.document-phase {
  width: 100%;

  &-content {
    .orders-filters-bar-container {
      margin: 0;
    }

    .reservation-form-divider {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid @c-border-default;
      margin: 1.5rem 0;
      padding: 0;
    }
  }

  .document-phase-content {
    .document-phase-horizontal-divider {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @c-success;
      color: @c-white;
      font-weight: 500;
      padding: 1rem;
      border-radius: 2px;

      svg {
        margin-right: 1rem;
      }
    }

    .document-phase-dropzone {
      margin-top: 1.5rem;
      min-height: 6rem;

      .dropzone {
        min-height: 6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        background: @c-white;
        border: 2px dashed @c-primary;
        border-radius: 2px;

        cursor: pointer;

        &-text-section {
          display: flex;
          align-items: center;
          flex-direction: row;

          padding: 1rem 0;

          .image {
            margin: 1rem 2rem;
          }

          span {
            color: @c-primary;
            font-size: @font-22;
            font-weight: 400;
            line-height: 2rem;
          }
        }

        &-button-section {
          button.dropzone-upload-button {
            margin: 1rem 2rem;
            border-radius: 2px !important;
            background-color: @c-grey-dark-dark;
            color: @c-white;
          }
        }
      }
    }

    .document-phase-document-list {
      margin-top: 1rem;
      font-size: @font-18;
      font-weight: 600;
      color: @c-primary;

      padding-bottom: 1rem;
      border-bottom: 1px solid @c-grey-border;

      .document-phase-document-list-container {
        font-size: @font-16;
        font-weight: 500;
        padding: 0.5rem;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .document-phase-uploaded-document {
      margin-top: 1rem;
      font-size: @font-18;
      font-weight: 600;
      color: @c-primary;

      .document-phase-uploaded-document-list {
        padding: 1rem;
        font-size: @font-16;
        font-weight: 400;

        .single-uploaded-document {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0.3rem;
          margin-bottom: 0.2rem;

          cursor: pointer;
          border-radius: 2px;
        }

        .accordion.optional-document-accordion {
          width: 100%;

          img.accordion-open {
            transform: rotate(180deg);
          }

          .optional-document-accordion-content {
            padding: 0.5rem 2rem 1rem !important;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
      
            .optional-document-loading {
              font-size: @font-12;
              color: @c-text-primary;
              text-transform: uppercase;
              font-weight: 400;
              margin-top: 1rem;
            }
        }

          .title {
            padding: 0;

            .single-uploaded-document-name {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 1rem;
            }
    
            .single-uploaded-document-name:hover {
              background-color: @c-grey-light-darker;
            }    
          }
        }

        .standard-dropdown-menu {
          display: flex;
          align-items: top;
          justify-content: center;
        }
      }
    }
  }
}
