.documents-table {
  &-empty {
    padding: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-weight: 500;
    font-size: @font-12;
  }

  &-header {
    text-align: center;
    font-size: @font-12;
    color: @c-white;
    text-transform: uppercase;
    font-weight: 600;
  }

  table.ui.celled.table {
    border: 0;
  }

  .ui.celled.table tr th,
  .ui.celled.table tr td {
    border-left: 0 !important;
  }
  th.ui.celled.table {
    border-bottom: 1px solid rgba(120, 120, 120, 0.24);
  }

  th {
    background: @c-primary !important;
    padding: 0.5rem 0.5rem 0.3rem !important;
  }

  &-expirationDate {
    display: flex;
    align-items: center;
    justify-content: center;

    &-container {
      width: 8.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0.5rem;
      border-radius: 20px;

      background: @c-grey-default;
    }
  }

  &-state {
    display: flex;
    align-items: center;
    justify-content: center;

    &-container {
      width: 9rem;
      display: flex;
      justify-content: space-around;
      align-items: center;

      padding: 0.5rem;
      border-radius: 20px;

      background: @c-grey-default;
    }
  }

  &-attachmentsNumber {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .attachments-dropdown {
      display: flex !important;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .text {
        padding-left: 0.5rem;
      }
    }
  }
}
