.select-contact-modal {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 1rem;
    }
  }

  div.content {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}