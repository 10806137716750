#app,
div.ui.page.modals {
  /* BUTTONS
    ----------------------------- */
  #formik-semantic-ui-react-submit-button,
  #formik-semantic-ui-react-reset-button,
  button.ui.button:not(.medium) {
    font-size: @font-14;
    padding: 0.9rem 2rem;
    letter-spacing: 0.01rem;
    border-radius: 0.5rem;
    position: relative;

    &.margin-right {
      margin-right: 0.5rem;
    }

    &.transparent { 
      background-color: transparent;
    }
  }

  /* MAIN BUTTON
    - C-cta background color
    - C-primary content color
    ----------------------------- */
  #formik-semantic-ui-react-submit-button,
  .main-button:hover,
  .main-button:focus,
  .main-button {
    background: @c-secondary;
    color: @c-text-primary;
    border-radius: 2px !important;
  }

  /* UNDERLINE BUTTON
    - Transparent background and border
    - Underline and c-primary color content
    ----------------------------- */
  .underlineButton:hover,
  .underlineButton:focus,
  .underlineButton {
    text-decoration: underline;
    background: transparent;
    color: @c-primary;
  }

  /* EMPTY&BORDER BUTTON
    - Transparent background
    - C-primary color for content and border
    ----------------------------- */
  .emptyWithBorderButton:hover,
  .emptyWithBorderButton:focus,
  .emptyWithBorderButton {
    background: transparent;
    color: @c-primary;
    border-radius: 0.5rem;
    border-color: @c-primary !important;
    border: 1px solid @c-primary !important;
    margin-right: 10px !important;
  }

  #formik-semantic-ui-react-reset-button {
    background: transparent;
    color: @c-text-primary;
    border-radius: 0.5rem;
    width: auto;
    text-decoration-line: underline;
  }

  /* ROUND BUTTON
    - Rounded border
    - c-primary color background and white font
    ----------------------------- */
  .roundButton:hover,
  .roundButton:focus,
  .roundButton {
    border-radius: 2.5rem !important;
    background: @c-primary;
    color: @c-white;
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
    font-weight: 600;
  }

  /** INSERT BUTTON */
  .insertButton,
  .insertButton:hover,
  .insertButton:focus {
    background-color: @c-success;
    color: @c-white;
    border-radius: 0 !important;
  }

  /** DELTE BUTTON */
  .deleteButton,
  .deleteButton:hover,
  .deleteButton:focus {
    background-color: @c-error;
    color: @c-white;
    border-radius: 0 !important;
  }

  /* FORMIK SEMANTIC BUTTON
    - Handle the formik semantic library's button for allign
      them to general application style
    ----------------------------- */
  #formik-semantic-ui-react-submit-button,
  #formik-semantic-ui-react-reset-button {
    height: 3rem;
    width: 100%;
    border: 0;
  }

  /* TRANSPARENT AND ONLY IMAGE BUTTON
    - Transparent background and no border
    ----------------------------- */
  button.ui.icon.transparentImageButtton {
    background-color: transparent;
    white-space: nowrap;
    padding: 0;
  }

  /* MAIN TABLE BUTTON
    - Transparent background and no border
    ----------------------------- */
  button.ui.mainTableButton {
    background-color: @c-grey-light;
    color: @c-primary;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    border: 0.5px solid rgba(31, 82, 100, 0.5);
    width: auto;

    img {
      margin-left: 0.5rem;
    }
  }

  .buttonWithIcon {
    display: flex;
    align-items: center;

    img {
      max-width: 1rem;
      max-height: 1rem;
      margin-right: 0.5rem;
    }

    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  /* BUTTONS ONLY ICON
    ----------------------------- */
  button.ui.tiny.icon {
    padding: 0.6rem 0.5rem !important;
    border-radius: 0.25rem;
  }

  /* BUTTONS ICON COMPRESS + EXPAND
    ----------------------------- */
  button.ui.icon.soloIcon {
    padding: 0.75rem;
    background-color: @c-grey-light !important;

    i {
      color: @c-primary !important;
    }

    &:hover {
      background-color: @c-secondary !important;

      i {
        color: #fff !important;
      }
    }
  }
}
