div.footer {
  bottom: 0;
  position: fixed;

  width: 100%;
  height: max(3.5rem, 5%);

  background-color: @c-secondary;

  padding: 0 3rem;

  // Allign footer elements to center
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  // Copyright footer text
  div.footer-text {
    font-weight: 400;
    font-size: @font-14;
    color: @c-text-primary;

    // Used for align the text at center while
    // the language selector is aligned on the left
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  div.dropdown.footer-select-language {
    margin-right: auto;

    div.menu.transition.visible {
      border-radius: 0 !important;
    }
    
    img.image {
      width: 1.5rem;
    }
  }
}
