/* MAIN TAB PANE STYLE
   Standard style for all the tab panes their contents
   like table, filter bar, footer, header
  ----------------------------- */
#app,
.modal {
  /* TABLE FOOTER
    - Dropdown style with white backround
    ----------------------------- */
  div.tableFooter {
    // Padding for footer
    margin-top: 1rem;
    text-align: right;

    // Dropdown
    .ui.selection.dropdown {
      background-color: @c-white;
      border-color: @c-grey-border;
      border-radius: 4px;
      height: 100%;
      margin-right: 1rem;

      .dropdown.icon {
        top: auto;
      }

      .divider.text {
        vertical-align: middle;
        color: @c-primary !important;
      }
    }
  }

  div.ui.bottom.attached.segment.active.tab {
    margin-bottom: 2rem;
  }

  // Force all the tabs to zero radius on boder
  .ui.tabular.menu {
    a.item {
      border-radius: 0 !important;
    }
  }
}
