.to-approve {
  width: 100%;

  &-content {
    .orders-filters-bar-container {
      margin: 0;
    }

    .reservation-form-divider {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid @c-border-default;
      margin: 1.5rem 0;
      padding: 0;
    }

    .max-quantity {
      text-align: left;
      font-size: @font-14;
      color: @c-text-primary;
      font-weight: 400;
    }
  }

  .reservation-lines-title {
    font-weight: 600;
    font-size: @font-18;
    color: @c-text-primary;
  }

  .reservation-description-field {
    margin-left: 2rem;
    padding: 1.5rem;
    border-left: 4px solid @c-secondary;

    color: @c-text-primary;
    font-weight: 600;
    font-size: @font-16;
  }

  .reservation-form-qrcode {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    svg {
      width: 10rem;
      height: 10rem;
    }
  }
}
