.calendar {
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 0rem 2rem;
    padding: 1.5rem 0rem;

    border-bottom: 1px solid #7a7a78;

    // Title style
    span {
      font-family: @sans-serif;
      color: @c-white;
      font-size: @font-28;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    &-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      width: 100%;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0.7rem;
      margin-right: 1rem;
      border-radius: 4px;
      background-color: @c-secondary;

      cursor: pointer;
    }

    &-buttons {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: auto;

      .only-icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        height: 2.5rem;
        width: 2.5rem;
        padding: 0rem !important;
        border-radius: 50% !important;
        background-color: transparent !important;
      }

      .second-button {
        padding-right: 0rem !important;
        margin: 0 !important;
      }

      .only-icon-button:hover {
        background-color: @c-grey-dark !important;
      }
    }
  }

  &-container {
    min-height: 88%;
    min-width: 70rem;

    margin: 0rem 2rem;
    padding: 1rem;

    align-items: stretch;

    display: flex;

    &-morningslot, &-afternoonslot {
      width: 50%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      &-title {
        font-weight: 500;
        font-size: @font-20;
        color: @c-white;
      }

      &-content {
        height: 100%;
        background-color: @c-primary;
        border-radius: 4px;
        margin-top: 0.5rem;
        padding: 0.5rem;
      }

      &-subcontent {
        display: flex;
        flex-wrap: wrap;          
      }
    }
  }
}
