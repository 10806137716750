.dataCollection-container {
  background: @c-grey-default;
  min-height: 35em;
  min-width: 70rem;

  margin: 1rem 3rem;
  border-radius: 2px;
  position: relative;
  padding-top: 3rem;

  .dataColletionForm {
    overflow-y: scroll;
    max-height: 35em;
    margin-bottom: 3rem !important;

    .content {
      width: 80%;
      left: 0;
      right: 0;

      margin: auto;

      .subtitle {
        font-weight: 600;
        font-size: @font-18;
        padding-left: 0;
        margin-top: 3rem;
      }
    }
    width: 70rem;
    height: 100%;
    background-color: white;

    margin: auto;
    padding-top: 2rem;

    .ui.celled.grid {
      .eight.wide.column {
        text-align: center;
      }
    }

    .ui.grid.generalDataGrid {
      margin-left: 0;
      margin-right: 0;

      .column.noPaddingLeft {
        padding-left: 0;
      }

      .column.noPaddingRight {
        padding-right: 0;
      }
    }
    .row.allRowInput {
      .field {
        width: 100%;
      }
    }

    .contact-origin-details-footer {
      bottom: 0;
      position: absolute;

      background: @c-white;

      height: 5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      //padding: 1rem 2rem;

      div.footer-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: min(85%, 100rem);
      }
    }
  }
}
div.contact-origin-details-footer {
  margin-top: auto;
  bottom: 0;

  background: @c-white;

  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem 2rem;

  div.footer-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: min(85%, 100rem);
  }
}
