.metalAvailability-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: @c-grey-default;
  min-height: 80%;
  min-width: 70rem;

  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;

  .metalAvailability-loading {
    padding: 0.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-weight: 500;
    font-size: @font-12;
  }

  .metalAvailability-section-title {
    color: @c-text-primary;
    font-size: @font-24;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 0rem 1rem;
    margin-top: 1rem;
  }

  .metalAvailability-totalAlloyQuantity-footer {
    width: 95%;
    margin-bottom: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: right;
    font-size: @font-18;
  }

  .semantic-table-grid-standard {
    margin-bottom: 1rem;
  }
}
