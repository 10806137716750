/* DATA TABLE
  ----------------------------- */
#app .contentGrid {
  // TABLE
  table.ui.table {
    border: 0;
    margin-top: 0;
    font-size: @font-14;
    background: transparent;
    border-radius: 2rem;

    thead,
    tbody {
      th,
      td {
        background: transparent;
        font-weight: 400;
        padding: 1rem 1.625rem 0.8rem;
      }
    }

    thead {
      background: @c-primary;

      th {
        border: none;
        color: #fff;
        text-align: center;
        white-space: nowrap;

        label {
          border: none;
          color: #fff;
          text-align: center;
          white-space: nowrap;
        }
      }
    }

    tbody {
      background: #fff;

      tr:hover {
        background: linear-gradient(
          to bottom,
          rgba(86, 91, 108, 0) 50%,
          rgba(86, 91, 108, 0.08) 100%
        );
      }

      td {
        border-top: 0;
        border-bottom: 1px solid #e0e0e0;
        font-weight: 500;
        text-align: center;
        margin-left: 5em;

        &:first-child {
          font-size: @font-16;
        }

        &:nth-child(1),
        &:nth-child(2) {
          white-space: break-spaces;
        }
      }
    }

    tfoot th {
      background: transparent;
      padding: 1rem 0;
    }
  }

  // ACTIONS
  [class*='ActionButtons__ActionButtonContainer'] {
    button.ui.medium.button {
      margin-right: 0.5rem;
      width: 2.5rem;
      height: 2.5rem;

      &.blue:hover {
        background: @c-main;
      }

      &.green:hover {
        background: @c-success;
      }

      &.yellow:hover {
        background: @c-cta;
      }

      &.red:hover {
        background: @c-error;
      }
    }
  }

  // PAGINATION
  div.ui.pagination.menu {
    a.item {
      &.active {
        font-weight: 700;
        background: @c-secondary;
        color: #fff;
      }
    }
  }
}
