.contact-documents {
  background: @c-grey-default;
  min-height: 80%;
  min-width: 70rem;

  margin: 1rem 3rem;
  padding: 2rem;
  border-radius: 2px;

  &-title {
    font-weight: 500;
    font-size: @font-24;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }

  &-subtitle {
    font-weight: 500;
    font-size: @font-18;
  }

  .documents-table-footer {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    .button {
      border-radius: 16px;
    }
  }
}
