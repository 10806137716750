.doc-viewer {
  background: @c-grey-default;
  min-height: 80%;

  margin: 1rem 3rem;
  padding: 2rem;
  border-radius: 2px;
  min-width: 70rem;

  display: flex;

  &-data {
    width: 40%;
    padding: 2rem;

    div.ui.label {
      font-size: @font-14;
      font-weight: 400;
      color: @c-text-primary;
      margin-left: 2px;
    }

    &-action {
      margin-top: 2rem;
      width: 100%;
      display: flex;
      justify-content: center;

      button.back-button:hover,
      button.back-button:focus,
      button.back-button {
        text-decoration: underline;
        background: transparent;
        color: @c-primary;
        padding: 0 !important;
        margin-right: 1.5rem;
      }
    }
  }

  &-file {
    width: 60%;
    background: @c-grey-light-darker;
    display: flex;
    justify-content: center;
    position: relative;

    .react-pdf__Document {
      display: flex;
      align-items: center;
    }

    &-pages {
      position: absolute;
      bottom: 3rem;

      display: flex;
      align-items: center;
      justify-content: space-between;

      background: @c-white;
      padding: 0.5rem 1rem;
      border-radius: 24px;
      border: solid 1px black;
      z-index: 1;

      opacity: 0;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left-button {
        margin-right: 0.5rem;
        padding: 1rem;
        border-radius: 100%;
        cursor: pointer;
      }

      .right-button {
        margin-left: 0.5rem;
        padding: 1rem;
        border-radius: 100%;
        cursor: pointer;
      }

      .left-button-disable, .right-button-disable {
        cursor: default;
      }

      .left-button:hover, .right-button:hover {
        background-color: #f2f2f2;
      }

      .left-button-disable:hover, .right-button-disable:hover {
        background-color: transparent;
      }
    }
  }
  
  &-file:hover {
    .doc-viewer-file-pages {
      opacity: 1;
    }
  }
}
