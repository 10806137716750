.years-filterBar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .years-filter-button {
    text-transform: uppercase;
    font-size: @font-14;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    padding: 1rem 2rem;

    border: 1px solid @c-grey-border;
    cursor: pointer;
  }

  .years-filter-button:hover:not(.years-filter-button-active) {
    background-color: @c-grey-light-darker;
  }

  .years-filter-button-active {
    background-color: @c-white;
    border: 2px solid @c-primary;
  }
}
