.no-contact-selected-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: @c-grey-default;
  min-height: 80%;
  min-width: 70rem;

  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;

  .no-contact-selected-title {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: @font-22;

    svg {
      margin-right: 2rem;
    }
  }

  .no-contact-selected-subtitle {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-weight: 500;
    font-size: @font-16;
    
    span {
      margin-top: 0.5rem;
    }
  }
}
