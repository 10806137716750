.myself-container {
  background: @c-grey-default;
  min-height: 80%;
  min-width: 70rem;

  margin: 1rem 3rem;
  border-radius: 2px;
  position: relative;

  .contact-origin-details-form {
    height: 100%;
    padding: 2rem 6rem;
  }

  div.contact-origin-details-footer {
    bottom: 0;
    position: absolute;

    background: @c-white;

    height: 5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    //padding: 1rem 2rem;

    div.footer-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding-right: 6rem;
    }
  }

  .myself-contact-description {
    font-weight: 500;
    font-size: @font-14;
  }
}

