.reservationsLine-list {
  width: 100%;

  table.ui.celled.table {
    border: 0;
  }

  .ui.celled.table tr th,
  .ui.celled.table tr td {
    border-left: 0 !important;
  }
  th.ui.celled.table {
    border-bottom: 1px solid rgba(120, 120, 120, 0.24);
  }

  th {
    background: @c-primary !important;
    padding: 0.5rem 0.5rem 0.3rem !important;

    .reservation-table-header {
      text-align: center;
      font-size: @font-12;
      color: @c-white;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .reservations-table {
    &-supplier {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      font-size: @font-14;
      text-align: center;
      color: @c-primary;

      &-name {
        font-weight: 600;
      }
    }

    &-deliveryDate {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      text-transform: uppercase;
      color: @c-primary;

      &-date {
        font-weight: 600;
      }

      &-timeslot {
        font-weight: 400;
        font-size: @font-12;
      }
    }

    &-orderNumber {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .order-number {
        font-weight: 500;
        color: @c-text-primary;
      }

      .line-no {
        font-weight: 400;
        font-size: @font-14;
        color: @c-text-primary;
      }
    }

    &-cerCode,
    &-environmentalClass {
      font-weight: 500;
      font-size: @font-14;
      color: @c-primary;
      text-align: center;
    }

    &-vehiclePlate {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      &-text {
        font-weight: 600;
        text-transform: uppercase;
        color: @c-primary;
        font-size: @font-14;
      }
    }

    &-actionMenu {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-weight {
      color: @c-text-primary;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .weight-icon {
        display: flex;
        align-items: baseline;
        justify-content: center;

        text-align: center;
        font-size: @font-12;
        color: @c-text-primary;
        text-transform: uppercase;
        font-weight: 400;
        svg {
          margin-right: 0.5rem;
        }

        .weight-text {
          text-align: center;
          font-size: @font-16;
          color: @c-text-primary;
          font-weight: 700;
        }
      }
    }

    &-description {
      border-left: 2px solid @c-grey-border;
      font-weight: 500;
      font-size: @font-16;
      color: @c-primary;
      padding-left: 0.5rem;
      text-align: center;
    }

    &-amount {
      &-value {
        text-align: center;
        font-size: @font-16;
        color: @c-text-primary;
        font-weight: 500;
      }
    }
  }
}
