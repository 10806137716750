div.access-page {
  height: 100%;

  .ui.grid.access-grid {
    height: 97%;

    div.column {
      height: 100%;
    }

    div.column.access-empty-column {
      background: linear-gradient(35.88deg, rgba(32, 15, 0, 0.88) 0%, rgba(32, 15, 0, 0) 100.61%), url(../../assets/images/background-login.png);
      background-size: cover;
    }
    
    div.column.access-form-column {
      background-color: @c-white;

      // Allign footer elements to center
      display: flex;
      justify-content: center;
      align-items: center;

      
      div.access-form-container {
        max-width: 50%;
        margin-top: 2rem;

        .row {
          padding-bottom: 0;
        }

      img.ui.image {
        max-width: 50%;
        margin-bottom: 2em;
      }

        .field {
          width: 100%;
          > label {
            font-size: @font-14;
            font-weight: 400;
            color: @c-text-primary;
            margin-left: 2px;
          }
        }
      }
    }
  }
  
}
