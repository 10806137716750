/* TOOLTIP BASIC
  ----------------------------- */
.ui.popup {
  font-size: @font-12;
  font-weight: 500;
  color: #FFF;
  background: @c-secondary;
  border: 0;
  padding: .5rem 1rem;

  &:before {
    border: 0;
  }
}