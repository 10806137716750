div.empty-supplier-dashboard {
  background: @c-grey-default;
  margin: 1.5rem 3rem;
  height: 80%;

  border-radius: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img.image {
    width: 7rem;
    margin-bottom: 3rem;
  }

  div.text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: 60%;

    margin-bottom: 2rem;

    div.title {
      text-align: center;
      font-weight: 700;
      font-size: @font-24;
      color: @c-text-primary;
      line-height: 1.5;
    }

    div.subtitle {
      text-align: center;
      font-weight: 500;
      font-size: @font-20;
      color: @c-text-primary;
      margin-top: 1rem;
    }
  }

  button.ui.button {
    margin: 0rem;
    height: 3.5rem;
  }
}

div.supplier-dashboard {
  margin: 1.5rem 3rem;

  .dashboard-reservation-card {
    cursor: pointer;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.5rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;

    border-bottom: 1px solid #d6d6d3;

    span {
      display: flex;
      align-items: center;
    }

    .dashboard-reservation-card-date {
      width: 30%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .delivery-date {
        font-weight: 600;
        font-size: @font-16;
      }

      .delivery-timeslot {
        font-weight: 500;
        font-size: @font-14;
      }
    }

    .dashboard-reservation-card-vehcilePlate {
      width: 30%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;

      font-weight: 600;
      font-size: @font-16;
    }

    .dashboard-reservation-card-bookingNumber {
      width: 40%;
      font-weight: 500;
      font-size: @font-14;
      text-decoration: underline;
      display: flex;
      justify-content: center;
    }
  }

  .booking-calendar-segment {
    position: relative;

    .link-approved-reservation {
      margin-top: 1rem;
      padding: 0.3rem;
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;

      font-weight: 600;
      color: @c-primary;
      text-decoration: underline;

      cursor: pointer;
    }
  }

  div.calendar-header-dashboard {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 1rem;
    margin-bottom: 1rem;

    img.ui.image {
      width: 3rem;
    }

    span.title-text {
      color: @c-primary;
      font-size: @font-22;
      font-weight: 700;
      margin-left: 0.5rem;
    }

    .calendar-icon {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .booking-calendar-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-top: 3rem;

    &-text {
      padding-top: 1rem;
      font-size: @font-16;
      font-weight: 600;
    }
  }

  .calendar-content {
    margin-bottom: 2.5rem;
  }
}

.right-download-button {
  margin-left: auto;

  .dashboard-button {
    padding: 0.9rem 1rem !important;
  }

  .dashboard-button:first-child {
    margin-right: 0.5rem;
  }

  .dashboard-download-button {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: @font-14;

    svg {
      margin-right: 0.3rem;
    }
  }
}
