#app [class*='App__Wrapper'] {
  padding-top: 4.625rem;
  font-family: 'Futura Std', 'Helvetica', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
}
#app [class*='App__Wrapper'] > div:not(.topbar-menu.menu) {
  padding: 0 !important;
}
#app [class*='App__Wrapper'] .contentGrid,
#app [class*='App__Wrapper'] .executionContent {
  margin-top: 1rem;
  padding-bottom: 1rem;
}
body {
  margin: 0;
}
div#app {
  height: 100%;
  overflow-y: auto;
}
.default-error-message {
  font-size: 0.875rem;
  color: #EB5757;
  font-weight: 400;
}
div.ui.page.dimmer.loading-page {
  background: linear-gradient(360deg, #F1F1F1 0%, rgba(241, 241, 241, 0) 88.54%);
}
div.ui.page.dimmer.loading-page img.image {
  padding-bottom: 13rem;
}
div.ui.page.dimmer.loading-page .ui.dimmer,
div.ui.page.dimmer.loading-page .ui.loader:after {
  border-color: #FDC300 transparent transparent;
}
div.ui.page.dimmer.loading-page div.main-text {
  margin-top: 0.5rem;
  color: #3C3C3B;
  font-weight: 400;
  font-size: 1.375rem;
}
div.ui.page.dimmer.loading-page div.subtitle-text {
  margin-top: 0.5rem;
  color: #3C3C3B;
  font-weight: 400;
  font-size: 0.875rem;
}
iframe {
  pointer-events: none;
}
@font-face {
  font-family: 'Futura Std';
  src: url('/src/assets/fonts/FuturaStd-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura Std';
  src: url('/src/assets/fonts/FuturaStd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura Std';
  src: url('/src/assets/fonts/FuturaStd-Heavy.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura Std';
  src: url('/src/assets/fonts/FuturaStd-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/* FONT TYPE
  ----------------------------- */
/* SIDEBAR SIZE
    ----------------------------- */
/* TOPBAR SIZE
    ----------------------------- */
/* CHECKBOX SIZE
    ----------------------------- */
/* FONT SIZE
    ----------------------------- */
/* COLORS
  ----------------------------- */
#app,
div.ui.page.modals {
  /* BUTTONS
    ----------------------------- */
  /* MAIN BUTTON
    - C-cta background color
    - C-primary content color
    ----------------------------- */
  /* UNDERLINE BUTTON
    - Transparent background and border
    - Underline and c-primary color content
    ----------------------------- */
  /* EMPTY&BORDER BUTTON
    - Transparent background
    - C-primary color for content and border
    ----------------------------- */
  /* ROUND BUTTON
    - Rounded border
    - c-primary color background and white font
    ----------------------------- */
  /** INSERT BUTTON */
  /** DELTE BUTTON */
  /* FORMIK SEMANTIC BUTTON
    - Handle the formik semantic library's button for allign
      them to general application style
    ----------------------------- */
  /* TRANSPARENT AND ONLY IMAGE BUTTON
    - Transparent background and no border
    ----------------------------- */
  /* MAIN TABLE BUTTON
    - Transparent background and no border
    ----------------------------- */
  /* BUTTONS ONLY ICON
    ----------------------------- */
  /* BUTTONS ICON COMPRESS + EXPAND
    ----------------------------- */
}
#app #formik-semantic-ui-react-submit-button,
div.ui.page.modals #formik-semantic-ui-react-submit-button,
#app #formik-semantic-ui-react-reset-button,
div.ui.page.modals #formik-semantic-ui-react-reset-button,
#app button.ui.button:not(.medium),
div.ui.page.modals button.ui.button:not(.medium) {
  font-size: 0.875rem;
  padding: 0.9rem 2rem;
  letter-spacing: 0.01rem;
  border-radius: 0.5rem;
  position: relative;
}
#app #formik-semantic-ui-react-submit-button.margin-right,
div.ui.page.modals #formik-semantic-ui-react-submit-button.margin-right,
#app #formik-semantic-ui-react-reset-button.margin-right,
div.ui.page.modals #formik-semantic-ui-react-reset-button.margin-right,
#app button.ui.button:not(.medium).margin-right,
div.ui.page.modals button.ui.button:not(.medium).margin-right {
  margin-right: 0.5rem;
}
#app #formik-semantic-ui-react-submit-button.transparent,
div.ui.page.modals #formik-semantic-ui-react-submit-button.transparent,
#app #formik-semantic-ui-react-reset-button.transparent,
div.ui.page.modals #formik-semantic-ui-react-reset-button.transparent,
#app button.ui.button:not(.medium).transparent,
div.ui.page.modals button.ui.button:not(.medium).transparent {
  background-color: transparent;
}
#app #formik-semantic-ui-react-submit-button,
div.ui.page.modals #formik-semantic-ui-react-submit-button,
#app .main-button:hover,
div.ui.page.modals .main-button:hover,
#app .main-button:focus,
div.ui.page.modals .main-button:focus,
#app .main-button,
div.ui.page.modals .main-button {
  background: #FDC300;
  color: #3C3C3B;
  border-radius: 2px !important;
}
#app .underlineButton:hover,
div.ui.page.modals .underlineButton:hover,
#app .underlineButton:focus,
div.ui.page.modals .underlineButton:focus,
#app .underlineButton,
div.ui.page.modals .underlineButton {
  text-decoration: underline;
  background: transparent;
  color: #3C3C3B;
}
#app .emptyWithBorderButton:hover,
div.ui.page.modals .emptyWithBorderButton:hover,
#app .emptyWithBorderButton:focus,
div.ui.page.modals .emptyWithBorderButton:focus,
#app .emptyWithBorderButton,
div.ui.page.modals .emptyWithBorderButton {
  background: transparent;
  color: #3C3C3B;
  border-radius: 0.5rem;
  border-color: #3C3C3B !important;
  border: 1px solid #3C3C3B !important;
  margin-right: 10px !important;
}
#app #formik-semantic-ui-react-reset-button,
div.ui.page.modals #formik-semantic-ui-react-reset-button {
  background: transparent;
  color: #3C3C3B;
  border-radius: 0.5rem;
  width: auto;
  text-decoration-line: underline;
}
#app .roundButton:hover,
div.ui.page.modals .roundButton:hover,
#app .roundButton:focus,
div.ui.page.modals .roundButton:focus,
#app .roundButton,
div.ui.page.modals .roundButton {
  border-radius: 2.5rem !important;
  background: #3C3C3B;
  color: #FFFFFF;
  padding-left: 1.2rem !important;
  padding-right: 1.2rem !important;
  font-weight: 600;
}
#app .insertButton,
div.ui.page.modals .insertButton,
#app .insertButton:hover,
div.ui.page.modals .insertButton:hover,
#app .insertButton:focus,
div.ui.page.modals .insertButton:focus {
  background-color: #27AE60;
  color: #FFFFFF;
  border-radius: 0 !important;
}
#app .deleteButton,
div.ui.page.modals .deleteButton,
#app .deleteButton:hover,
div.ui.page.modals .deleteButton:hover,
#app .deleteButton:focus,
div.ui.page.modals .deleteButton:focus {
  background-color: #EB5757;
  color: #FFFFFF;
  border-radius: 0 !important;
}
#app #formik-semantic-ui-react-submit-button,
div.ui.page.modals #formik-semantic-ui-react-submit-button,
#app #formik-semantic-ui-react-reset-button,
div.ui.page.modals #formik-semantic-ui-react-reset-button {
  height: 3rem;
  width: 100%;
  border: 0;
}
#app button.ui.icon.transparentImageButtton,
div.ui.page.modals button.ui.icon.transparentImageButtton {
  background-color: transparent;
  white-space: nowrap;
  padding: 0;
}
#app button.ui.mainTableButton,
div.ui.page.modals button.ui.mainTableButton {
  background-color: #ebeff2;
  color: #3C3C3B;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  border: 0.5px solid rgba(31, 82, 100, 0.5);
  width: auto;
}
#app button.ui.mainTableButton img,
div.ui.page.modals button.ui.mainTableButton img {
  margin-left: 0.5rem;
}
#app .buttonWithIcon,
div.ui.page.modals .buttonWithIcon {
  display: flex;
  align-items: center;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
#app .buttonWithIcon img,
div.ui.page.modals .buttonWithIcon img {
  max-width: 1rem;
  max-height: 1rem;
  margin-right: 0.5rem;
}
#app button.ui.tiny.icon,
div.ui.page.modals button.ui.tiny.icon {
  padding: 0.6rem 0.5rem !important;
  border-radius: 0.25rem;
}
#app button.ui.icon.soloIcon,
div.ui.page.modals button.ui.icon.soloIcon {
  padding: 0.75rem;
  background-color: #ebeff2 !important;
}
#app button.ui.icon.soloIcon i,
div.ui.page.modals button.ui.icon.soloIcon i {
  color: #3C3C3B !important;
}
#app button.ui.icon.soloIcon:hover,
div.ui.page.modals button.ui.icon.soloIcon:hover {
  background-color: #FDC300 !important;
}
#app button.ui.icon.soloIcon:hover i,
div.ui.page.modals button.ui.icon.soloIcon:hover i {
  color: #fff !important;
}
/* DATA TABLE
  ----------------------------- */
#app .contentGrid table.ui.table {
  border: 0;
  margin-top: 0;
  font-size: 0.875rem;
  background: transparent;
  border-radius: 2rem;
}
#app .contentGrid table.ui.table thead th,
#app .contentGrid table.ui.table tbody th,
#app .contentGrid table.ui.table thead td,
#app .contentGrid table.ui.table tbody td {
  background: transparent;
  font-weight: 400;
  padding: 1rem 1.625rem 0.8rem;
}
#app .contentGrid table.ui.table thead {
  background: #3C3C3B;
}
#app .contentGrid table.ui.table thead th {
  border: none;
  color: #fff;
  text-align: center;
  white-space: nowrap;
}
#app .contentGrid table.ui.table thead th label {
  border: none;
  color: #fff;
  text-align: center;
  white-space: nowrap;
}
#app .contentGrid table.ui.table tbody {
  background: #fff;
}
#app .contentGrid table.ui.table tbody tr:hover {
  background: linear-gradient(to bottom, rgba(86, 91, 108, 0) 50%, rgba(86, 91, 108, 0.08) 100%);
}
#app .contentGrid table.ui.table tbody td {
  border-top: 0;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 500;
  text-align: center;
  margin-left: 5em;
}
#app .contentGrid table.ui.table tbody td:first-child {
  font-size: 1rem;
}
#app .contentGrid table.ui.table tbody td:nth-child(1),
#app .contentGrid table.ui.table tbody td:nth-child(2) {
  white-space: break-spaces;
}
#app .contentGrid table.ui.table tfoot th {
  background: transparent;
  padding: 1rem 0;
}
#app .contentGrid [class*='ActionButtons__ActionButtonContainer'] button.ui.medium.button {
  margin-right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
}
#app .contentGrid [class*='ActionButtons__ActionButtonContainer'] button.ui.medium.button.blue:hover {
  background: #00a6da;
}
#app .contentGrid [class*='ActionButtons__ActionButtonContainer'] button.ui.medium.button.green:hover {
  background: #27AE60;
}
#app .contentGrid [class*='ActionButtons__ActionButtonContainer'] button.ui.medium.button.yellow:hover {
  background: #0e71b4;
}
#app .contentGrid [class*='ActionButtons__ActionButtonContainer'] button.ui.medium.button.red:hover {
  background: #EB5757;
}
#app .contentGrid div.ui.pagination.menu a.item.active {
  font-weight: 700;
  background: #FDC300;
  color: #fff;
}
div.MuiDialog-root .MuiPaper-rounded {
  border-radius: 0;
}
div.MuiDialog-root div.MuiPickersSlideTransition-transitionContainer div div.MuiPickersCalendar-week div[role='presentation'] {
  border-right: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
}
div.MuiDialog-root div.MuiPickersSlideTransition-transitionContainer div div.MuiPickersCalendar-week div[role='presentation']:first-child {
  border-left: 1px solid #dfdfdf;
}
div.MuiDialog-root div.MuiPickersSlideTransition-transitionContainer div div.MuiPickersCalendar-week:last-child div[role='presentation'] {
  border-bottom: 1px solid #dfdfdf;
}
.dropdown.standard-dropdown-menu div.menu.transition.visible {
  box-shadow: 4px 4px 32px rgba(120, 120, 120, 0.4);
}
.dropdown.standard-dropdown-menu img.ui.image {
  margin: 1rem;
}
.dropdown.standard-dropdown-menu div.tab {
  margin: 1rem 0 !important;
}
/* COMMON FORM ELEMENTS
  ----------------------------- */
div.ui.label.dropdown[role='listbox'],
div:not(.select__input) > input[type='number'],
div:not(.select__input) > input[type='text'],
div:not(.select__input) > input[type='password'],
.select__control,
textarea {
  position: relative;
  background: transparent;
  border: 1px solid rgba(128, 144, 160, 0.65) !important;
  border-radius: 0 !important;
  line-height: normal !important;
}
div.ui.label.dropdown[role='listbox'] .text,
div:not(.select__input) > input[type='number'] .text,
div:not(.select__input) > input[type='text'] .text,
div:not(.select__input) > input[type='password'] .text,
.select__control .text,
textarea .text {
  font-size: 1.125rem;
  font-weight: 500;
  color: #3C3C3B;
}
textarea:read-only,
input:read-only,
.ui.disabled.search {
  background: transparent !important;
  cursor: default;
}
.ui.form .disabled.field,
.ui.form .field.disabled > label,
.ui.disabled.search {
  opacity: 1 !important;
}
.ui.form textarea {
  resize: none;
}
input[type='number'],
input[type='text'],
input[type='password'] {
  padding: 0.8rem 2rem 0.65rem 1rem !important;
  line-height: normal !important;
}
.ui.form .field > .hQllqh {
  width: 100%;
  padding: 0 !important;
}
div.error-message {
  font-size: 0.75rem;
  color: #EB5757;
  margin-top: -0.5rem;
}
div.ui.selection.dropdown {
  padding: 0.8rem 1rem 0.65rem 1rem !important;
  line-height: normal !important;
  border: 1px solid rgba(128, 144, 160, 0.65) !important;
  border-radius: 0 !important;
}
div.ui.selection.dropdown i.dropdown.icon {
  height: 100%;
  display: flex;
  align-items: center;
}
div.ui.selection.dropdown div.divider.text:not(.default) {
  color: #3C3C3B;
}
div.ui.selection.dropdown div.visible.menu {
  border-radius: 0;
  border: 1px solid rgba(128, 144, 160, 0.65);
  border-top: 0;
}
div.ui.selection.dropdown div.active.selected.item span.text {
  font-weight: 600;
}
/* LABEL
  ----------------------------- */
.ui.form .field label[for] {
  font-size: 0.875rem;
  font-weight: 500;
  color: #737a8f;
  opacity: 1;
}
/* INPUT
  ----------------------------- */
input[type='number'],
input[type='text'],
input[type='hidden'],
input[type='password'] {
  color: #2b3c4a !important;
}
input[type='number']:disabled,
input[type='text']:disabled,
input[type='hidden']:disabled,
input[type='password']:disabled,
.field.disabled input[type='number'],
.field.disabled input[type='text'],
.field.disabled input[type='hidden'],
.field.disabled input[type='password'] {
  background: #ebeff2 !important;
  opacity: 0.75;
}
/* TEXTAREA
  ----------------------------- */
textarea {
  font-family: 'Futura Std', 'Helvetica', sans-serif;
}
/* SELECT
  ----------------------------- */
.field.disabled .select__control {
  background: #ebeff2 !important;
  opacity: 0.75;
}
.select__control .select__value-container {
  padding: 0;
}
.select__control .select__value-container .select__single-value {
  padding: 0.8rem 2rem 0.65rem 1rem;
  width: 100%;
}
.select__control .select__value-container > div {
  margin: 0;
  padding: 0;
}
.select__control .select__value-container > div input {
  border: 0;
  width: 100% !important;
}
.select__menu {
  height: 12.5rem;
  overflow: hidden;
}
.select__menu > * {
  height: 100%;
}
/* DATEPICKER
  ----------------------------- */
.formik-date-wrapper {
  border: 1px solid rgba(128, 144, 160, 0.65) !important;
  border-radius: 0 !important;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  font-size: 14px;
  width: 100%;
  padding: 0 !important;
}
.formik-date-wrapper .MuiTextField-root {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}
.formik-date-wrapper .MuiTextField-root .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
  padding: 0.8rem 2rem 0.65rem 1rem !important;
  border: 0 !important;
}
.formik-date-wrapper .MuiTextField-root .MuiButtonBase-root.MuiIconButton-root {
  padding: 0 0.25rem;
}
.formik-date-wrapper .MuiTextField-root input {
  width: '85px';
  border: 0px solid !important;
}
.formik-date-wrapper .MuiTextField-root button {
  border-radius: 0;
}
.formik-date-wrapper .MuiTextField-root button:hover {
  background: transparent;
}
.formik-date-wrapper .MuiTextField-root button:focus {
  background: transparent;
}
.formik-date-wrapper .MuiTextField-root .MuiTouchRipple-root {
  display: none;
}
.formik-date-wrapper .MuiTextField-root label {
  display: none;
}
.formik-date-wrapper .MuiTextField-root .MuiInput-formControl {
  margin-top: 0;
}
.formik-date-wrapper .MuiTextField-root p.Mui-error {
  position: absolute;
  bottom: -38px;
}
/* SEARCH ICON
  ----------------------------- */
i.icon.search.link.inverted.grey {
  opacity: 1;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: #ebeff2 !important;
  color: #3C3C3B !important;
  border-radius: 0.25rem !important;
}
/* DROPDOWN
  ----------------------------- */
.ui.selection.dropdown {
  border: 1px solid rgba(128, 144, 160, 0.65);
  border-radius: 0;
  color: #000;
}
.ui.selection.dropdown:hover {
  border-color: rgba(128, 144, 160, 0.65);
}
.ui.selection.dropdown.active {
  background: #fff;
  border-color: rgba(128, 144, 160, 0.65);
}
.ui.selection.dropdown.active i {
  color: #000;
}
.pageHeader .ui.selection.dropdown {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.4);
  border-radius: 0;
  color: #fff;
}
.pageHeader .ui.selection.dropdown .text {
  font-size: 1.125rem;
  font-weight: 500;
  color: #3C3C3B;
}
.pageHeader .ui.selection.dropdown:hover {
  border-color: rgba(255, 255, 255, 0.6);
}
.pageHeader .ui.selection.dropdown.active {
  background: #fff;
}
.pageHeader .ui.selection.dropdown.active i {
  color: #000;
}
.fileUploadField {
  border: 1px solid rgba(128, 144, 160, 0.65);
  cursor: pointer;
}
.fileUploadField button {
  padding: 0.7rem !important;
  background-color: transparent !important;
}
.fileUploadField .labelGrid {
  font-family: 'Futura Std', 'Helvetica', sans-serif;
  font-size: 1.125rem;
  color: black;
  opacity: 0.5;
}
div.field > label {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  color: #3C3C3B !important;
  margin-left: 2px !important;
}
/* CHECKBOX

  ----------------------------- */
.ui.checkbox.tableCheckbox {
  height: 1.375rem;
  width: 1.375rem;
}
.ui.checkbox.tableCheckbox label:before {
  background: #ebeff2 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.25rem;
  height: 1.375rem;
  width: 1.375rem;
}
.ui.checkbox.tableCheckbox.checked label:after {
  content: '' !important;
  height: 0.875rem;
  width: 0.875rem;
  background: #3C3C3B;
  border-radius: 0.2rem;
  top: 0.25rem;
  left: 0.25rem;
}
.ui.checkbox.tableCheckbox.indeterminate label:after {
  left: 50%;
  transform: translate(-50%, 20%);
  color: #3C3C3B !important;
}
form.ui.form .field + span {
  font-size: 0.75rem;
}
form.ui.form .field.error + span {
  color: #EB5757;
  margin-top: -0.5rem;
}
div.ui.selection.dropdown.disabled {
  opacity: 1 !important;
  background: transparent !important;
}
.ui.label {
  /** Set label background to transparent */
  /** The label will file the container space */
}
.ui.label.labelTransparent {
  background-color: transparent;
}
.ui.label.maxWidthHeight {
  width: 100%;
  height: 100%;
}
/* 
 * MODAL
 * General settings for Semantic's modal component
 * For set a specific style to a modal assign a new class to the element 
 */
div.ui.page.modals {
  background-color: linear-gradient(287.15deg, #3c3c3b 0%, rgba(60, 60, 59, 0.88) 100%);
  font-family: 'Futura Std', 'Helvetica', sans-serif !important;
}
div.ui.page.modals div.ui.modal {
  border-radius: 2px;
  padding: 1.5rem 2rem;
  min-width: 25rem;
}
div.ui.page.modals div.ui.modal div.close-button {
  position: fixed;
  top: 0.8rem;
  right: 0.8rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  display: flex;
  text-align: center;
  justify-content: center;
}
div.ui.page.modals div.ui.modal div.close-button img.image {
  width: 1rem;
}
div.ui.page.modals div.ui.modal > div.header {
  color: #3C3C3B;
  font-weight: 600;
  font-size: 1.375rem;
  background-color: transparent;
  border-bottom: none;
  display: flex;
}
div.ui.page.modals div.ui.modal > div.header img.image {
  margin-right: 1rem;
}
div.ui.page.modals div.ui.modal div.actions {
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  border: 0;
}
div.ui.page.modals div.ui.modal div.actions #formik-semantic-ui-react-reset-button {
  margin-right: 1rem;
}
div.ui.page.modals div.ui.modal div.actions button {
  margin: 0;
}
/** Put the semantic table grid into a div with this class to set the standard layout */
.semantic-table-grid-standard {
  width: 95%;
}
.semantic-table-grid-standard table.ui.celled.table {
  border: 0;
}
.semantic-table-grid-standard .ui.celled.table tr th,
.semantic-table-grid-standard .ui.celled.table tr td {
  border-left: 0 !important;
}
.semantic-table-grid-standard th.ui.celled.table {
  border-bottom: 1px solid rgba(120, 120, 120, 0.24);
}
.semantic-table-grid-standard tr:nth-child(even) td {
  background: #ebeff2;
}
.semantic-table-grid-standard th {
  background: #3C3C3B !important;
  padding: 0.5rem 0.5rem 0.3rem !important;
  text-align: center !important;
  font-size: 0.75rem !important;
  color: #FFFFFF !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.semantic-table-grid-standard th .sortable-header {
  cursor: pointer;
}
.semantic-table-grid-standard .sort-icon {
  margin-left: 0.3rem;
}
.semantic-table-grid-standard .sort-icon-rotate {
  margin-left: 0.3rem;
  transform: rotate(180deg);
}
.semantic-table-grid-standard td {
  text-align: center !important;
  font-size: 0.875rem;
  color: #3C3C3B;
  font-weight: 400;
}
/* MAIN TAB PANE STYLE
   Standard style for all the tab panes their contents
   like table, filter bar, footer, header
  ----------------------------- */
#app,
.modal {
  /* TABLE FOOTER
    - Dropdown style with white backround
    ----------------------------- */
}
#app div.tableFooter,
.modal div.tableFooter {
  margin-top: 1rem;
  text-align: right;
}
#app div.tableFooter .ui.selection.dropdown,
.modal div.tableFooter .ui.selection.dropdown {
  background-color: #FFFFFF;
  border-color: rgba(128, 144, 160, 0.65);
  border-radius: 4px;
  height: 100%;
  margin-right: 1rem;
}
#app div.tableFooter .ui.selection.dropdown .dropdown.icon,
.modal div.tableFooter .ui.selection.dropdown .dropdown.icon {
  top: auto;
}
#app div.tableFooter .ui.selection.dropdown .divider.text,
.modal div.tableFooter .ui.selection.dropdown .divider.text {
  vertical-align: middle;
  color: #3C3C3B !important;
}
#app div.ui.bottom.attached.segment.active.tab,
.modal div.ui.bottom.attached.segment.active.tab {
  margin-bottom: 2rem;
}
#app .ui.tabular.menu a.item,
.modal .ui.tabular.menu a.item {
  border-radius: 0 !important;
}
/* TOOLTIP BASIC
  ----------------------------- */
.ui.popup {
  font-size: 0.75rem;
  font-weight: 500;
  color: #FFF;
  background: #FDC300;
  border: 0;
  padding: 0.5rem 1rem;
}
.ui.popup:before {
  border: 0;
}
div.access-page {
  height: 100%;
}
div.access-page .ui.grid.access-grid {
  height: 97%;
}
div.access-page .ui.grid.access-grid div.column {
  height: 100%;
}
div.access-page .ui.grid.access-grid div.column.access-empty-column {
  background: linear-gradient(35.88deg, rgba(32, 15, 0, 0.88) 0%, rgba(32, 15, 0, 0) 100.61%), url(../../assets/images/background-login.png);
  background-size: cover;
}
div.access-page .ui.grid.access-grid div.column.access-form-column {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.access-page .ui.grid.access-grid div.column.access-form-column div.access-form-container {
  max-width: 50%;
  margin-top: 2rem;
}
div.access-page .ui.grid.access-grid div.column.access-form-column div.access-form-container .row {
  padding-bottom: 0;
}
div.access-page .ui.grid.access-grid div.column.access-form-column div.access-form-container img.ui.image {
  max-width: 50%;
  margin-bottom: 2em;
}
div.access-page .ui.grid.access-grid div.column.access-form-column div.access-form-container .field {
  width: 100%;
}
div.access-page .ui.grid.access-grid div.column.access-form-column div.access-form-container .field > label {
  font-size: 0.875rem;
  font-weight: 400;
  color: #3C3C3B;
  margin-left: 2px;
}
div.footer {
  bottom: 0;
  position: fixed;
  width: 100%;
  height: max(3.5rem, 5%);
  background-color: #FDC300;
  padding: 0 3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
div.footer div.footer-text {
  font-weight: 400;
  font-size: 0.875rem;
  color: #3C3C3B;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
div.footer div.dropdown.footer-select-language {
  margin-right: auto;
}
div.footer div.dropdown.footer-select-language div.menu.transition.visible {
  border-radius: 0 !important;
}
div.footer div.dropdown.footer-select-language img.image {
  width: 1.5rem;
}
div.footer-layout {
  height: 100%;
  background: linear-gradient(360deg, #F1F1F1 0%, rgba(241, 241, 241, 0) 88.54%);
  text-align: center;
  display: flex;
  justify-content: center;
}
div.footer-layout div.footer-main-content {
  overflow-y: auto;
  padding-bottom: 5rem;
  background: #FFFFFF;
  height: 100%;
  width: min(70%, 80rem);
}
div.sidebar.menu {
  position: fixed;
  width: 15.5rem;
  max-width: 30rem;
  left: 0px;
  overflow: hidden;
  z-index: 999;
  visibility: visible !important;
  display: block;
  background-color: #3C3C3B;
  box-shadow: 4px 0px 8px rgba(42, 48, 65, 0.04);
  filter: drop-shadow(4px 0px 12px rgba(42, 48, 65, 0.18));
  padding-top: 3rem;
  height: 100%;
}
div.sidebar.menu div.menu-section {
  padding: 1rem 1.5rem;
  padding-top: 2.5rem;
  width: 100%;
  text-transform: uppercase;
  color: #787878;
  font-size: 0.75rem;
}
div.sidebar.menu div.menu-element {
  display: flex;
  padding: 1rem 1.5rem;
  width: 100%;
}
div.sidebar.menu div.menu-element div.element-text {
  position: relative;
  margin-left: 1rem;
  padding-top: 0.3rem;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.5;
  font-size: 1rem;
}
div.sidebar.menu div.menu-element.selected div.element-text {
  opacity: 1;
}
div.sidebar.menu div.menu-element.selected div.element-text:after {
  content: '';
  background: #FDC300;
  position: absolute;
  left: 0.2rem;
  width: 2.5rem;
  height: 3px;
  bottom: -0.4rem;
}
div.sidebar.menu div.menu-element:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
}
div.sidebar.menu .agent-select-customer {
  position: absolute;
  font-weight: 400;
  color: #FFFFFF !important;
  font-size: 1rem;
  bottom: 0;
  width: 100%;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
}
div.sidebar.menu .agent-select-customer svg {
  margin-right: 1rem;
}
div.sidebar.menu .agent-select-customer:hover {
  background: rgba(0, 0, 0, 0.3);
}
div.sidebar.menu .contact-select-profile {
  position: absolute;
  font-weight: 400;
  color: #FFFFFF !important;
  font-size: 0.75rem;
  bottom: 0;
  width: 100%;
  opacity: 0.8;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
}
div.sidebar.menu .contact-select-profile svg {
  margin-right: 1rem;
}
div.sidebar.menu .contact-select-profile:hover {
  background: rgba(0, 0, 0, 0.3);
}
/** TOPBAR */
#app .topbar.menu {
  background-color: #FFFFFF;
  border-bottom: 2px solid #FDC300;
  border-radius: 0px;
  height: 4rem !important;
  left: 15.5rem;
  margin: 0;
  position: fixed !important;
  right: 0;
  z-index: 1;
}
#app .topbar.menu .header {
  padding: 1rem 1.5rem;
  width: 250px;
}
#app .topbar.menu .header img.image.topbar-logo {
  max-height: 3.5rem;
}
#app .topbar.menu .button.topbar-button {
  background-color: transparent;
  border-radius: 0 !important;
  padding: 1rem 1.5rem;
  margin-right: 0 !important;
  margin-left: 0.3rem;
}
#app .topbar.menu .button.topbar-button:hover {
  background: rgba(0, 0, 0, 0.1);
}
#app .topbar.menu .dropdown.topbar-dropdown div.divider.text {
  font-weight: 500;
  color: #3C3C3B;
  font-size: 1rem;
}
#app .topbar.menu .dropdown.topbar-dropdown img.ui.image {
  margin-left: 1rem;
}
#app .topbar.menu .dropdown.topbar-dropdown div.menu.transition.visible {
  border-radius: 0;
}
#app .topbar.menu .dropdown.topbar-dropdown div.menu.transition.visible .item {
  display: flex;
  align-items: center;
}
#app .topbar.menu .dropdown.topbar-dropdown div.menu.transition.visible .item span.text {
  font-weight: 400 !important;
  color: #3C3C3B !important;
  font-size: 1rem !important;
}
#app .topbar.menu .dropdown.topbar-dropdown:hover {
  background: rgba(0, 0, 0, 0.1);
}
#app .topbar.menu .menu-right-element {
  padding: 0;
}
#app .topbar.menu .menu-right-element .right.menu {
  height: 100%;
}
#app .topbar.menu .menu-right-element .menu-documentation-download {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
}
#app .topbar.menu .menu-right-element .menu-documentation-download:hover {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
#app .topbar.menu div.dropdown.footer-select-language {
  padding: 0.35rem 0.6rem !important;
}
#app .topbar.menu div.dropdown.footer-select-language img.image {
  width: 1.6rem;
}
.admin-contact-login-container {
  background: #f2f2f2;
  margin: 1.5rem 3rem;
  height: 80%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.admin-contact-login-container .selected-user-data-card {
  width: min(80%, 40rem);
  padding: 1rem 2rem;
  border-radius: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}
.admin-contact-login-container .selected-user-data-card .text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.admin-contact-login-container .selected-user-data-card .text-section .title {
  text-align: center;
  font-weight: 600;
  font-size: 1.375rem;
  color: #3C3C3B;
  line-height: 1.5;
}
.admin-contact-login-container .selected-user-data-card .ui.selection.dropdown {
  width: 100%;
}
.admin-contact-login-container .selected-user-data-card-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1rem;
  color: #3C3C3B;
}
.admin-contact-login-container .selected-user-data-card-row svg {
  margin-right: 1rem;
}
.admin-contact-login-container .contact-type-switch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.admin-contact-login-container .contact-type-switch .single-contact-type {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.admin-contact-login-container .contact-type-switch .single-contact-type .MuiCheckbox-colorPrimary.Mui-checked {
  color: #FDC300 !important;
}
.admin-contact-login-container .contact-type-switch .single-contact-type .MuiCheckbox-colorPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.06);
}
.calendar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0rem 2rem;
  padding: 1.5rem 0rem;
  border-bottom: 1px solid #7a7a78;
}
.calendar-header span {
  font-family: 'Futura Std', 'Helvetica', sans-serif;
  color: #FFFFFF;
  font-size: 1.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.calendar-header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}
.calendar-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem;
  margin-right: 1rem;
  border-radius: 4px;
  background-color: #FDC300;
  cursor: pointer;
}
.calendar-header-buttons {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.calendar-header-buttons .only-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0rem !important;
  border-radius: 50% !important;
  background-color: transparent !important;
}
.calendar-header-buttons .second-button {
  padding-right: 0rem !important;
  margin: 0 !important;
}
.calendar-header-buttons .only-icon-button:hover {
  background-color: #3e3e3e !important;
}
.calendar-container {
  min-height: 88%;
  min-width: 70rem;
  margin: 0rem 2rem;
  padding: 1rem;
  align-items: stretch;
  display: flex;
}
.calendar-container-morningslot,
.calendar-container-afternoonslot {
  width: 50%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.calendar-container-morningslot-title,
.calendar-container-afternoonslot-title {
  font-weight: 500;
  font-size: 1.25rem;
  color: #FFFFFF;
}
.calendar-container-morningslot-content,
.calendar-container-afternoonslot-content {
  height: 100%;
  background-color: #3C3C3B;
  border-radius: 4px;
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.calendar-container-morningslot-subcontent,
.calendar-container-afternoonslot-subcontent {
  display: flex;
  flex-wrap: wrap;
}
.contact-documents {
  background: #f2f2f2;
  min-height: 80%;
  min-width: 70rem;
  margin: 1rem 3rem;
  padding: 2rem;
  border-radius: 2px;
}
.contact-documents-title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}
.contact-documents-subtitle {
  font-weight: 500;
  font-size: 1.125rem;
}
.contact-documents .documents-table-footer {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.contact-documents .documents-table-footer .button {
  border-radius: 16px;
}
div.contact-origin-header {
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
}
div.contact-origin-header span.contact-origin-complete-name {
  color: #FFFFFF;
  font-size: 1.75rem;
  font-weight: 500;
  margin-right: 1rem;
}
div.contact-origin-details-body {
  background: #f2f2f2;
  min-height: 80%;
  margin: 1rem 3rem;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.contact-origin-details-body div.contact-origin-details-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-bottom: 1rem;
}
div.contact-origin-details-body div.contact-origin-details-main-content span.form-title {
  color: #3C3C3B;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
div.contact-origin-details-body div.contact-origin-details-main-content .contact-origin-details-form {
  padding: 2rem;
  max-width: min(85%, 100rem);
}
div.contact-origin-details-body div.contact-origin-details-main-content .contact-origin-details-form .row {
  padding: 0.5rem 0;
}
div.contact-origin-details-body div.contact-origin-details-main-content .contact-origin-details-form div.field label {
  text-align: left;
}
div.contact-origin-details-body div.contact-origin-details-main-content .contact-origin-details-form .documents-table {
  width: 100%;
}
div.contact-origin-details-body div.contact-origin-details-main-content div.ui.selection.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
div.contact-origin-details-body div.contact-origin-details-main-content .document-row {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
div.contact-origin-details-body div.contact-origin-details-main-content .document-row .input-field-error-message {
  padding-top: 0.5rem;
}
div.contact-origin-details-body div.contact-origin-details-footer {
  margin-top: auto;
  background: #FFFFFF;
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
}
div.contact-origin-details-body div.contact-origin-details-footer div.footer-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: min(85%, 100rem);
}
div.contact-origin-details-body .origin-document-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: #3C3C3B;
  font-weight: 700;
  font-size: 1.5rem;
}
div.contact-origin-details-body .new-origin-required-field {
  font-weight: 600;
  font-size: 1rem;
  color: #EB5757;
  text-decoration: underline;
}
#app .contact-origins-list-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0rem 2rem;
  padding: 1.5rem 0rem;
}
#app .contact-origins-list-page-header span.title {
  font-family: 'Futura Std', 'Helvetica', sans-serif;
  color: #FFFFFF;
  font-size: 1.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
#app .contact-origins-list-container {
  margin: 0rem 3rem;
}
#app .contact-origins-list-segment {
  background-color: #f2f2f2;
  padding: 1.5rem !important;
  min-width: 50rem;
}
#app .contact-origins-subtitle {
  color: #3C3C3B;
  font-size: 1.125rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
}
.credits-limit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  min-height: 80%;
  min-width: 70rem;
  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;
}
.credits-limit-container .customerCreditsLimit-loading {
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
}
.credits-limit-container .credits-limit-main-data {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.credits-limit-container .credits-limit-card {
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  min-width: 20rem;
  border-radius: 20px;
}
.credits-limit-container .credits-limit-card .card-title {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.credits-limit-container .credits-limit-card .card-data {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.813rem;
}
.credits-limit-container .expired-creditsLimit {
  font-weight: 600;
  color: #EB5757;
}
.customer-documents-table-empty {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
}
.customer-documents-footer {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.customer-documents-footer-totalElements {
  font-size: 0.875rem;
  color: #3C3C3B;
  font-weight: 600;
}
.customer-documents-container,
.customer-documents-modal {
  width: 100%;
}
.customer-documents-container table.ui.celled.table,
.customer-documents-modal table.ui.celled.table {
  border: 0;
}
.customer-documents-container .ui.celled.table tr th,
.customer-documents-modal .ui.celled.table tr th,
.customer-documents-container .ui.celled.table tr td,
.customer-documents-modal .ui.celled.table tr td {
  border-left: 0 !important;
}
.customer-documents-container th.ui.celled.table,
.customer-documents-modal th.ui.celled.table {
  border-bottom: 1px solid rgba(120, 120, 120, 0.24);
}
.customer-documents-container th,
.customer-documents-modal th {
  background: #3C3C3B !important;
  padding: 0.5rem 0.5rem 0.3rem !important;
}
.customer-documents-container th .customer-documents-container-header,
.customer-documents-modal th .customer-documents-container-header {
  text-align: center;
  font-size: 0.75rem;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 400;
}
.customer-documents-container .sortable-header,
.customer-documents-modal .sortable-header {
  cursor: pointer;
}
.customer-documents-container .sort-icon,
.customer-documents-modal .sort-icon {
  margin-left: 0.3rem;
}
.customer-documents-container .sort-icon-rotate,
.customer-documents-modal .sort-icon-rotate {
  margin-left: 0.3rem;
  transform: rotate(180deg);
}
.customer-documents-container .customer-documents-table-documentNumber,
.customer-documents-modal .customer-documents-table-documentNumber {
  text-align: center;
  font-weight: 500;
  color: #3C3C3B;
  font-size: 0.875rem;
}
.customer-documents-container .customer-documents-table-standard,
.customer-documents-modal .customer-documents-table-standard {
  text-align: center;
  color: #3C3C3B;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.customer-documents-container .customer-documents-table-payment,
.customer-documents-modal .customer-documents-table-payment {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.customer-documents-container .customer-documents-table-actions,
.customer-documents-modal .customer-documents-table-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer-documents-container .customer-documents-table-actions .actionMenu-button,
.customer-documents-modal .customer-documents-table-actions .actionMenu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebeff2;
  padding: 0.6rem;
  border-radius: 8px;
  border: 1px solid #737a8f;
  cursor: pointer;
}
.customer-documents-container .customer-documents-table-actions .margin-right,
.customer-documents-modal .customer-documents-table-actions .margin-right {
  margin-right: 0.5rem;
}
.customer-documents-container .customer-documents-table-residual-quantity,
.customer-documents-modal .customer-documents-table-residual-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.customer-documents-container .customer-documents-table-residual-quantity-icon,
.customer-documents-modal .customer-documents-table-residual-quantity-icon {
  display: flex;
  align-items: baseline;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 400;
}
.customer-documents-container .customer-documents-table-residual-quantity-icon svg,
.customer-documents-modal .customer-documents-table-residual-quantity-icon svg {
  margin-right: 0.5rem;
}
.customer-documents-container .customer-documents-table-residual-quantity-value,
.customer-documents-modal .customer-documents-table-residual-quantity-value {
  text-align: center;
  font-size: 1rem;
  color: #3C3C3B;
  font-weight: 700;
}
.customer-documents-container .customer-documents-table-item,
.customer-documents-modal .customer-documents-table-item {
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
}
.customer-documents-container .customer-documents-table-date,
.customer-documents-modal .customer-documents-table-date {
  text-align: center;
  font-size: 0.875rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 500;
}
.customer-documents-container .customer-documents-table-action,
.customer-documents-modal .customer-documents-table-action {
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer-documents-container .customer-documents-table-action .MuiCheckbox-colorPrimary.Mui-checked,
.customer-documents-modal .customer-documents-table-action .MuiCheckbox-colorPrimary.Mui-checked {
  color: #FDC300 !important;
}
.customer-documents-container .customer-documents-table-action .MuiCheckbox-colorPrimary.Mui-disabled,
.customer-documents-modal .customer-documents-table-action .MuiCheckbox-colorPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.06);
}
/** DETAILS MODAL */
.customer-documents-modal {
  /** Header */
  /** Subtitle text */
  /** crossIcon */
}
.customer-documents-modal-header {
  display: flex;
  align-items: center;
}
.customer-documents-modal-header svg {
  margin-right: 1rem;
}
.customer-documents-modal div.noPaddingTop.content {
  padding-top: 0rem !important;
}
.customer-documents-modal-loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.customer-documents-modal button.ui.button.crossIcon {
  float: right;
  background-color: transparent;
  padding: 0.5rem !important;
}
.customer-documents-modal .customer-documents-field-title {
  text-align: center;
  font-size: 1rem;
  color: #3C3C3B;
  font-weight: 600;
}
.customer-documents-modal .customer-documents-field-data {
  text-align: center;
  font-size: 1rem;
  color: #3C3C3B;
  font-weight: 500;
}
.customer-documents-modal li {
  margin-bottom: 0.4rem;
}
.customer-documents-modal .customer-documents-table {
  margin-top: 1rem;
}
.customer-personal-documents {
  background: #f2f2f2;
  min-height: 80%;
  min-width: 70rem;
  padding: 1.5rem;
  border-radius: 2px;
}
.customer-personal-documents-title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}
.customer-personal-documents-subtitle {
  font-weight: 500;
  font-size: 1.125rem;
}
.customer-personal-documents .documents-table-footer {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.customer-personal-documents .documents-table-footer .button {
  border-radius: 16px;
}
.metalAvailability-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  min-height: 80%;
  min-width: 70rem;
  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;
}
.metalAvailability-container .metalAvailability-loading {
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
}
.metalAvailability-container .metalAvailability-section-title {
  color: #3C3C3B;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0rem 1rem;
  margin-top: 1rem;
}
.metalAvailability-container .metalAvailability-totalAlloyQuantity-footer {
  width: 95%;
  margin-bottom: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  font-size: 1.125rem;
}
.metalAvailability-container .semantic-table-grid-standard {
  margin-bottom: 1rem;
}
.customer-reservation-container {
  background: #FFFFFF;
  min-height: 80%;
  min-width: 70rem;
  margin: 1rem 3rem;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  /*
   * Standard style for the header of the various reservation's pages
   * 
   * For use it add the following code
   * <div className="customer-reservation-header">
   *   <div className="customer-reservation-header-title">Your title</div>
   *   <div className="customer-reservation-header-title">Your subtitle</div>
   * </div>
   */
  /*
   * Standard style for single row with two input fields with icon
   *
   * An example of implementation
   * <div className="reservation-fields-row">
   *   <div className="fields-with-icon">
   *     <SvgIcon ... />
   *     <div className="fields-content">
   *       <Input//Select ... />
   *     </div>
   *   </div>
   *   <div className="fields-with-icon">
   *     <SvgIcon ... />
   *     <div className="fields-content">
   *       <Input//Select ... />
   *     </div>
   *   </div>
   * </div>
   */
}
.customer-reservation-container .reservation-content {
  flex: 1 0 auto;
  background: #f2f2f2;
  display: flex;
}
.customer-reservation-container .reservation-content-sidebar {
  max-width: 16rem;
  min-width: 16rem;
  background: white;
  display: flex;
  justify-content: center;
  border-right: 1px solid #D6D6D3;
}
.customer-reservation-container .reservation-content-form {
  display: flex;
  justify-content: center;
  padding: 2rem 4rem;
  width: 100%;
}
.customer-reservation-container .reservation-footer {
  border: 1px solid #D6D6D3;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2rem;
}
.customer-reservation-container .customer-reservation-header {
  display: flex;
  flex-direction: column;
  color: #3C3C3B;
  padding-left: 0.2rem;
  margin-bottom: 1rem;
}
.customer-reservation-container .customer-reservation-header-title {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.customer-reservation-container .customer-reservation-header-subtitle {
  font-weight: 500;
  font-size: 1.125rem;
}
.customer-reservation-container .reservation-fields-row {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0;
  width: 50%;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon svg {
  margin-top: 2.2rem;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon .disabled.field {
  max-width: 35rem;
  opacity: 1;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon .disabled.field label {
  color: #3C3C3B !important;
  font-weight: 500 !important;
  opacity: 1;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon .fields-content {
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon .fields-content .field {
  max-width: 35rem;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon .fields-content .field label {
  color: #3C3C3B !important;
  font-weight: 500 !important;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon .fields-content .field .ui.disabled.input {
  opacity: 1 !important;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon .fields-content .input-uppercase input {
  text-transform: uppercase;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon .fields-content .input-uppercase ::placeholder {
  text-transform: none;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon .fields-content .disabled.field {
  max-width: 35rem;
  opacity: 1;
}
.customer-reservation-container .reservation-fields-row .fields-with-icon .fields-content .disabled.field label {
  color: #3C3C3B !important;
  font-weight: 500 !important;
  opacity: 1;
}
.dataCollection-container {
  background: #f2f2f2;
  min-height: 35em;
  min-width: 70rem;
  margin: 1rem 3rem;
  border-radius: 2px;
  position: relative;
  padding-top: 3rem;
}
.dataCollection-container .dataColletionForm {
  overflow-y: scroll;
  max-height: 35em;
  margin-bottom: 3rem !important;
  width: 70rem;
  height: 100%;
  background-color: white;
  margin: auto;
  padding-top: 2rem;
}
.dataCollection-container .dataColletionForm .content {
  width: 80%;
  left: 0;
  right: 0;
  margin: auto;
}
.dataCollection-container .dataColletionForm .content .subtitle {
  font-weight: 600;
  font-size: 1.125rem;
  padding-left: 0;
  margin-top: 3rem;
}
.dataCollection-container .dataColletionForm .ui.celled.grid .eight.wide.column {
  text-align: center;
}
.dataCollection-container .dataColletionForm .ui.grid.generalDataGrid {
  margin-left: 0;
  margin-right: 0;
}
.dataCollection-container .dataColletionForm .ui.grid.generalDataGrid .column.noPaddingLeft {
  padding-left: 0;
}
.dataCollection-container .dataColletionForm .ui.grid.generalDataGrid .column.noPaddingRight {
  padding-right: 0;
}
.dataCollection-container .dataColletionForm .row.allRowInput .field {
  width: 100%;
}
.dataCollection-container .dataColletionForm .contact-origin-details-footer {
  bottom: 0;
  position: absolute;
  background: #FFFFFF;
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dataCollection-container .dataColletionForm .contact-origin-details-footer div.footer-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: min(85%, 100rem);
}
div.contact-origin-details-footer {
  margin-top: auto;
  bottom: 0;
  background: #FFFFFF;
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
}
div.contact-origin-details-footer div.footer-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: min(85%, 100rem);
}
div.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.login-container img.image {
  width: 70%;
  margin-bottom: 2em;
}
div.login-container .ui.grid.login-form .column.password-recovery-column {
  padding: 1rem 0 2rem 0;
}
div.login-container .ui.grid.login-form .column.password-recovery-column .password-recovery {
  text-align: right;
  margin-top: -4px;
  margin-bottom: 15px;
  font-family: 'Arial', 'Montserrat', 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 18px;
  text-decoration-line: underline;
}
div.login-container .ui.grid.login-form .column.password-recovery-column .password-recovery > a {
  color: #3C3C3B !important;
}
div.login-container .ui.grid.login-form div.hybridauth-container {
  width: 100%;
}
div.login-container .ui.grid.login-form button.microsoft-button {
  font-family: 'Arial', 'Montserrat', 'Helvetica', sans-serif;
  font-weight: 700;
  text-align: center;
  color: #fff;
  background-color: #3C3C3B;
  border-radius: 0 !important;
  margin: 0;
}
div.login-container .ui.grid.login-form button.microsoft-button:hover,
div.login-container .ui.grid.login-form button.microsoft-button:focus,
div.login-container .ui.grid.login-form button.microsoft-button:active {
  background-color: #3C3C3B;
  color: #fff;
}
div.login-container .ui.grid.login-form .column.new-user-column {
  margin-top: 0.5rem;
}
div.login-container .ui.grid.login-form .column.new-user-column .register {
  text-align: center;
  font-family: 'Arial', 'Montserrat', 'Helvetica', sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 18px;
}
div.login-container .ui.grid.login-form .column.new-user-column .register > a {
  color: #3C3C3B !important;
}
.metal-resume-tables-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  min-width: 70rem;
  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;
}
.metal-resume-tables-container .metal-resume-loading {
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
}
.metal-resume-tables-container .metal-resume-title {
  color: #3C3C3B;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0rem 1rem;
  margin: 1rem 0;
}
.metal-resume-tables-container .second-title {
  margin-top: 2rem;
}
.metal-resume-tables-container .arrow-rotate-up {
  transform: rotate(180deg);
}
.metal-resume-tables-container .actionMenu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebeff2;
  padding: 0.6rem;
  border-radius: 8px;
  border: 1px solid #737a8f;
  cursor: pointer;
}
.metal-resume-tables-container .metal-resume-collapsed tbody > :nth-child(n + 6) {
  visibility: collapse;
}
.metal-resume-tables-container .metal-resume-table-footer {
  margin-top: 1rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.875rem;
  text-decoration: underline;
}
.myself-container {
  background: #f2f2f2;
  min-height: 80%;
  min-width: 70rem;
  margin: 1rem 3rem;
  border-radius: 2px;
  position: relative;
}
.myself-container .contact-origin-details-form {
  height: 100%;
  padding: 2rem 6rem;
}
.myself-container div.contact-origin-details-footer {
  bottom: 0;
  position: absolute;
  background: #FFFFFF;
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.myself-container div.contact-origin-details-footer div.footer-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-right: 6rem;
}
.myself-container .myself-contact-description {
  font-weight: 500;
  font-size: 0.875rem;
}
div.password-recovery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.password-recovery-container img.image {
  width: 70%;
  margin-bottom: 2em;
}
div.password-recovery-container button.home-button {
  font-family: 'Arial', 'Montserrat', 'Helvetica', sans-serif;
  font-weight: 700;
  text-align: center;
  color: #fff;
  background-color: #3C3C3B;
  border-radius: 0 !important;
  margin: 0;
}
div.password-recovery-container button.home-button:hover,
div.password-recovery-container button.home-button:focus,
div.password-recovery-container button.home-button:active {
  background-color: #3C3C3B;
  color: #fff;
}
div.password-recovery-container button.home-button > a {
  color: #fff;
}
div.password-recovery-container .password-recovery-completed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
div.password-recovery-container .password-recovery-completed-container img.image {
  width: 3rem;
}
div.password-recovery-container .password-recovery-completed-container span.title-text {
  font-weight: 400;
  font-size: 1rem;
  color: #3C3C3B;
  white-space: nowrap;
}
div.password-recovery-container .password-recovery-completed-container span.subtitle-text {
  padding-bottom: 1.5rem;
  font-weight: 400;
  font-size: 0.75rem;
  color: #3C3C3B;
  white-space: nowrap;
}
div.reservation-container {
  background: #FFFFFF;
  min-height: 80%;
  min-width: 70rem;
  margin: 1rem 3rem;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  /*
   * Standard style for the header of the various reservation's pages
   * 
   * For use it add the following code
   * <div className="reservation-header">
   *   <div className="reservation-header-title">Your title</div>
   *   <div className="reservation-header-title">Your subtitle</div>
   * </div>
   */
  /*
   * Standard style for single row with two input fields with icon
   *
   * An example of implementation
   * <div className="reservation-fields-row">
   *   <div className="fields-with-icon">
   *     <SvgIcon ... />
   *     <div className="fields-content">
   *       <Input//Select ... />
   *     </div>
   *   </div>
   *   <div className="fields-with-icon">
   *     <SvgIcon ... />
   *     <div className="fields-content">
   *       <Input//Select ... />
   *     </div>
   *   </div>
   * </div>
   */
}
div.reservation-container .reservation-content {
  flex: 1 0 auto;
  background: #f2f2f2;
  display: flex;
}
div.reservation-container .reservation-content-sidebar {
  max-width: 16rem;
  min-width: 16rem;
  background: white;
  display: flex;
  justify-content: center;
  border-right: 1px solid #D6D6D3;
}
div.reservation-container .reservation-content-form {
  display: flex;
  justify-content: center;
  padding: 2rem 4rem;
  width: 100%;
}
div.reservation-container .reservation-footer {
  border: 1px solid #D6D6D3;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2rem;
}
div.reservation-container .reservation-header {
  display: flex;
  flex-direction: column;
  color: #3C3C3B;
  padding-left: 0.2rem;
  margin-bottom: 1rem;
}
div.reservation-container .reservation-header-title {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
div.reservation-container .reservation-header-subtitle {
  font-weight: 500;
  font-size: 1.125rem;
}
div.reservation-container .reservation-fields-row {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
div.reservation-container .reservation-fields-row .fields-with-icon {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0;
  width: 50%;
}
div.reservation-container .reservation-fields-row .fields-with-icon svg {
  margin-top: 2.2rem;
}
div.reservation-container .reservation-fields-row .fields-with-icon .disabled.field {
  max-width: 35rem;
  opacity: 1;
}
div.reservation-container .reservation-fields-row .fields-with-icon .disabled.field label {
  color: #3C3C3B !important;
  font-weight: 500 !important;
  opacity: 1;
}
div.reservation-container .reservation-fields-row .fields-with-icon .fields-content {
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}
div.reservation-container .reservation-fields-row .fields-with-icon .fields-content .field {
  max-width: 35rem;
}
div.reservation-container .reservation-fields-row .fields-with-icon .fields-content .field label {
  color: #3C3C3B !important;
  font-weight: 500 !important;
}
div.reservation-container .reservation-fields-row .fields-with-icon .fields-content .field .ui.disabled.input {
  opacity: 1 !important;
}
div.reservation-container .reservation-fields-row .fields-with-icon .fields-content .input-uppercase input {
  text-transform: uppercase;
}
div.reservation-container .reservation-fields-row .fields-with-icon .fields-content .input-uppercase ::placeholder {
  text-transform: none;
}
div.reservation-container .reservation-fields-row .fields-with-icon .fields-content .disabled.field {
  max-width: 35rem;
  opacity: 1;
}
div.reservation-container .reservation-fields-row .fields-with-icon .fields-content .disabled.field label {
  color: #3C3C3B !important;
  font-weight: 500 !important;
  opacity: 1;
}
#app .reservations-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0rem 2rem;
  padding: 1.5rem 0rem;
}
#app .reservations-page-header span.title {
  font-family: 'Futura Std', 'Helvetica', sans-serif;
  color: #FFFFFF;
  font-size: 1.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
#app .reservations-container {
  margin: 0rem 3rem;
}
#app .reservations-container-segment {
  background-color: #f2f2f2;
  min-width: 75rem;
}
div.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.reset-password-container img.image {
  width: 70%;
  margin-bottom: 2em;
}
.signup-type {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.signup-type-logo {
  width: 12rem;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
}
.signup-type-title {
  font-weight: 700;
  font-size: 1.5rem;
  color: #3C3C3B;
  margin-bottom: 2rem;
}
.signup-type-buttons {
  display: flex;
  flex-direction: row;
}
.signup-type-buttons .type-button {
  width: 15rem;
  height: 4rem;
  border: 1px solid #3C3C3B;
  border-radius: 2px;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.signup-type-buttons .type-button-text {
  font-weight: 600;
  font-size: 1rem;
  color: #3C3C3B;
}
.signup-type-buttons .type-button:hover {
  background-color: #FDC300;
  border: 2px solid #FDC300;
}
.signup-dataform {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup-dataform-logo {
  width: 16rem;
  margin-top: 2.5rem;
  margin-bottom: 4rem;
}
.signup-dataform-title {
  color: #3C3C3B;
  font-weight: 400;
  font-size: 1.5rem;
}
.signup-dataform-title span.bold-text {
  font-weight: 700;
}
.signup-dataform-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 8rem 0rem 8rem;
}
.signup-dataform-form .selection.ui.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}
.signup-dataform-form .signup-input-row {
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.signup-dataform-form .signup-input-container {
  width: 48%;
  text-align: left;
}
.signup-dataform-form .field {
  width: 100%;
  margin-bottom: 0.5rem !important;
}
.signup-dataform-form .field > label {
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  color: #3C3C3B;
  margin-left: 2px;
}
.signup-dataform-form .signup-button-container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.signup-documentform {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup-documentform-logo {
  width: 16rem;
  margin-top: 2.5rem;
  margin-bottom: 4rem;
}
.signup-documentform-title {
  color: #3C3C3B;
  font-weight: 400;
  font-size: 1.5rem;
}
.signup-documentform-title span.bold-text {
  font-weight: 700;
}
.signup-documentform-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 8rem 0rem 8rem;
}
.signup-documentform-form svg {
  margin-top: 1.6rem;
  margin-right: 0.5rem;
}
.signup-completed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.signup-completed-msm-logo {
  position: fixed !important;
  top: 3.5rem;
  max-width: unset !important;
}
.signup-completed-msm-logo img {
  width: 15rem;
}
.signup-completed-title {
  margin-top: 3rem;
  font-weight: 700;
  font-size: 1.375rem;
  color: #3C3C3B;
}
.signup-completed-subtitle {
  padding-top: 1.5rem;
  font-weight: 400;
  font-size: 1rem;
  color: #3C3C3B;
}
div.user-details-header {
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
}
div.user-details-header span.user-complete-name {
  color: #FFFFFF;
  font-size: 1.75rem;
  font-weight: 500;
  margin-right: 1rem;
}
div.user-details-header span.vertical-divider {
  height: 1.5rem;
  margin-left: 1rem;
  border-left: 1px solid rgba(128, 144, 160, 0.65);
  margin-right: 2rem;
}
div.user-details-header span.vat-number-text {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 1rem;
}
div.user-details-header span.vat-number-text span.user-vat-number {
  font-weight: 600;
  margin-left: 1rem;
}
div.user-details-body {
  background: #f2f2f2;
  min-height: 80%;
  margin: 1rem 3rem;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.user-details-body div.user-details-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-bottom: 1rem;
}
div.user-details-body div.user-details-main-content span.form-title {
  color: #3C3C3B;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
div.user-details-body div.user-details-main-content .user-details-form {
  padding: 2rem;
  max-width: min(85%, 100rem);
}
div.user-details-body div.user-details-main-content .user-details-form .row {
  padding: 0.5rem 0;
}
div.user-details-body div.user-details-main-content .user-details-form div.field label {
  text-align: left;
}
div.user-details-body div.user-details-main-content .user-details-form .row.document-loader {
  margin-top: 1rem;
}
div.user-details-body div.user-details-main-content .user-details-form .row.document-loader div.ui.loader::after {
  border-color: #3C3C3B transparent transparent;
}
div.user-details-body div.user-details-main-content .user-details-form div.file-loaded-box {
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.user-details-body div.user-details-main-content .user-details-form div.file-loaded-box span.file-number {
  color: #FFFFFF;
  font-weight: 700;
}
div.user-details-body div.user-details-main-content .user-details-form div.not-loaded {
  background: #EB5757;
}
div.user-details-body div.user-details-main-content .user-details-form div.loaded {
  background: #27AE60;
}
div.user-details-body div.user-details-main-content .user-details-form div.file-name-text {
  height: 2rem;
  width: 25rem;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 1rem;
  font-weight: 500;
  color: #3C3C3B;
  font-size: 1rem;
}
div.user-details-body div.user-details-main-content .user-details-form img.image.download-file-icon {
  width: 2rem;
  border: 1px solid #3C3C3B;
  border-radius: 4px;
  cursor: pointer;
}
div.user-details-body div.user-details-main-content .user-details-form div.empty-image {
  width: 2rem;
}
div.user-details-body div.user-details-main-content button.deleteButton {
  margin: 1rem 0rem;
}
div.user-details-body div.user-details-footer {
  margin-top: auto;
  background: #FFFFFF;
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
}
div.user-details-body div.user-details-footer div.footer-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: min(85%, 100rem);
}
div.user-details-body .documents-table {
  width: 100%;
}
.ui.bottom.attached.segment.active.tab {
  background-color: #f2f2f2;
  min-width: 53rem;
}
.ui.tabular.menu .item {
  background-color: rgba(242, 242, 242, 0.5) !important;
  margin-right: 1rem;
}
.ui.tabular.menu .active.item {
  background-color: #f2f2f2 !important;
}
.ui.attached.tabular.menu {
  border-bottom: 0px;
}
div.user-requests-content {
  margin: 1rem 3rem;
}
div.ui.page.modals .user-details-form .row {
  padding: 0.5rem 0;
}
div.ui.page.modals .user-details-form div.field label {
  text-align: left;
}
div.ui.page.modals .user-details-form span.form-title {
  color: #3C3C3B;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 2rem;
}
div.ui.page.modals .user-details-form .row.document-loader {
  margin-top: 1rem;
}
div.ui.page.modals .user-details-form .row.document-loader div.ui.loader::after {
  border-color: #3C3C3B transparent transparent;
}
div.ui.page.modals .user-details-form div.file-loaded-box {
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.ui.page.modals .user-details-form div.file-loaded-box span.file-number {
  color: #FFFFFF;
  font-weight: 700;
}
div.ui.page.modals .user-details-form div.not-loaded {
  background: #EB5757;
}
div.ui.page.modals .user-details-form div.loaded {
  background: #27AE60;
}
div.ui.page.modals .user-details-form div.file-name-text {
  height: 2rem;
  width: 25rem;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 1rem;
  font-weight: 500;
  color: #3C3C3B;
  font-size: 1rem;
}
div.ui.page.modals .user-details-form img.image.download-file-icon {
  width: 2rem;
  border: 1px solid #3C3C3B;
  border-radius: 4px;
  cursor: pointer;
}
div.ui.page.modals .user-details-form div.empty-image {
  width: 2rem;
}
.ui.dimmer.calendar-picker-dimmer {
  background-color: rgba(0, 0, 0, 0.05);
}
.ui.modal.calendar-picker-modal {
  box-shadow: 0px 0px 32px rgba(60, 60, 59, 0.18);
  width: 460px !important;
  height: 550px !important;
  /** ---- REACT DATE PICKER STYLE OVERRIDE ---- */
}
.ui.modal.calendar-picker-modal .content {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ui.modal.calendar-picker-modal .content .calendar-picker-close {
  padding: 0.5rem;
  position: fixed;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
}
.ui.modal.calendar-picker-modal .content .calendar-picker-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.ui.modal.calendar-picker-modal .content .calendar-picker-header-text {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 1.125rem;
  color: #3C3C3B;
}
.ui.modal.calendar-picker-modal .content .calendar-picker-header-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui.modal.calendar-picker-modal .content .calendar-picker-header-buttons .only-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0rem !important;
  border-radius: 50% !important;
  background-color: transparent !important;
}
.ui.modal.calendar-picker-modal .content .calendar-picker-header-buttons .second-button {
  padding-right: 0rem !important;
  margin: 0 !important;
}
.ui.modal.calendar-picker-modal .content .calendar-picker-header-buttons .only-icon-button:hover {
  background-color: #ebeff2 !important;
}
.ui.modal.calendar-picker-modal .confirm-date-picker {
  margin-top: 1rem;
  width: 97%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background-color: #27AE60;
  font-weight: 600;
  color: #FFFFFF;
  font-size: 1rem;
  cursor: pointer;
}
.ui.modal.calendar-picker-modal .react-datepicker {
  width: 100%;
  border: none;
  border-radius: 0;
  font-family: 'Futura Std', 'Helvetica', sans-serif;
  color: #3C3C3B;
  /** --- BORDER MANAGMENT --- */
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__header {
  border-radius: 0;
  background-color: transparent;
  border: none;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__month-container {
  width: 100%;
  float: none;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__day,
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__time-name {
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #3C3C3B;
  font-weight: 400;
  font-size: 0.875rem;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__day-name {
  width: 3.2rem;
  height: 1.5rem;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #3C3C3B;
  font-weight: 600;
  font-size: 1rem;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__day--disabled {
  text-decoration: line-through;
  background-color: #f2f2f2;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__day:hover {
  background-color: #ebeff2;
  border-radius: 0;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__day-names,
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__week {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__day--selected {
  border-radius: 0;
  background-color: #FDC300;
  font-weight: 600;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__day--selected:hover {
  background-color: #FDC300;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__day--keyboard-selected {
  border-radius: 0rem;
  background-color: transparent;
  color: black;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__month .react-datepicker__week:first-child .react-datepicker__day:first-child {
  border: 1px solid #DFDFDF;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__month .react-datepicker__week:first-child .react-datepicker__day:not(:first-child) {
  border: 1px solid #DFDFDF;
  border-left: none;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__month .react-datepicker__week:not(:first-child) .react-datepicker__day:first-child {
  border: 1px solid #DFDFDF;
  border-top: none;
}
.ui.modal.calendar-picker-modal .react-datepicker .react-datepicker__month .react-datepicker__week:not(:first-child) .react-datepicker__day:not(:first-child) {
  border: 1px solid #DFDFDF;
  border-left: none;
  border-top: none;
}
div.ui.segment.card-grid-header {
  padding: 0rem 1rem 0rem 1rem !important;
}
.ui.segment.card {
  min-height: 15rem;
  color: #3C3C3B;
  width: auto;
  height: auto;
  background-color: #f2f2f2 !important;
  margin: 0 !important;
  padding: 0.8rem !important;
  border-radius: 0.5rem;
}
.ui.segment.card .img.ui.image.card-image {
  width: 15rem !important;
  height: 8rem !important;
}
div.ui.segment.card:hover {
  background-color: #fdc300 !important;
}
div.ui.segment.card:hover .ui.inline.right.floated.image {
  filter: brightness(0) invert(1);
}
.ui.segment.cardGrid {
  display: inline-flex;
  flex-direction: row;
  background-color: #ffffff !important;
  width: 100%;
  height: auto;
  padding: 2.5rem;
  border-radius: 0.5rem;
  right: auto !important;
}
.ui.segment.cardGrid .ui.image.card-image {
  display: flex;
  width: 5rem !important;
  height: 8rem !important;
}
table.ui.celled.collapsing.very.basic.table {
  width: 100% !important;
}
img.ui.mini.image {
  padding: 0 !important;
  margin: 0 !important;
}
div.cardGrid {
  display: inline-flex;
  flex-direction: row;
  background-color: #ffffff !important;
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  right: auto !important;
}
div.cardGrid .ui.image.card-image {
  display: flex;
  width: 5rem !important;
  height: 8rem !important;
}
div.card {
  color: #3C3C3B;
  width: auto;
  height: auto;
  margin: 0 !important;
  border-radius: 0.5rem;
}
div.card .img.ui.image.card-image {
  width: 15rem !important;
  height: 8rem !important;
}
div.cardIcons {
  display: inline-flex;
  flex-direction: row;
  white-space: nowrap !important;
  width: 8rem;
  height: auto;
  border-radius: 0.5rem;
  right: auto !important;
}
div.cardIcons .ui.image.card-image {
  display: flex;
  width: 5rem !important;
  height: 8rem !important;
}
div.cardIcons .cardItem {
  display: grid;
  width: auto;
  padding-left: 0rem;
}
div.column {
  flex-grow: 0.2 !important;
}
div.center.aligned.row {
  background-color: white !important;
}
h4.ui.header.cell {
  white-space: normal !important;
}
span.cell {
  display: block;
}
.ui.image.header {
  margin-top: 0rem !important;
}
.ui.segment.card.loading-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-origins-list {
  width: 100%;
}
.contact-origins-list table.ui.celled.table {
  border: 0;
}
.contact-origins-list .ui.celled.table tr th,
.contact-origins-list .ui.celled.table tr td {
  border-left: 0 !important;
}
.contact-origins-list th.ui.celled.table {
  border-bottom: 1px solid rgba(120, 120, 120, 0.24);
}
.contact-origins-list .ui.table td {
  padding: 0.6rem !important;
}
.contact-origins-list th {
  background: #3C3C3B !important;
  padding: 0.5rem 0.5rem 0.3rem !important;
}
.contact-origins-list th .origins-table-header {
  font-size: 0.75rem;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 600;
}
.contact-origins-list th .origins-table-header-center {
  font-size: 0.75rem;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.contact-origins-list .origins-table-businessName {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 0.875rem;
  color: #3C3C3B;
}
.contact-origins-list .origins-table-businessName-name {
  font-weight: 600;
}
.contact-origins-list .origins-table-approved,
.contact-origins-list .origins-table-email {
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
  color: #3C3C3B;
}
.contact-origins-list .origins-table-approved-check,
.contact-origins-list .origins-table-email-check,
.contact-origins-list .origins-table-approved-cross,
.contact-origins-list .origins-table-email-cross,
.contact-origins-list .origins-table-approved-pending,
.contact-origins-list .origins-table-email-pending {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0.4rem;
}
.contact-origins-list .origins-table-approved-check,
.contact-origins-list .origins-table-email-check {
  background: #27AE60;
}
.contact-origins-list .origins-table-approved-cross,
.contact-origins-list .origins-table-email-cross {
  background: #EB5757;
}
.contact-origins-list .origins-table-approved-pending,
.contact-origins-list .origins-table-email-pending {
  background: #FDC300;
}
.contact-origins-list .origins-table-actionMenu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-origins-list .origins-table-actionMenu-view-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 12px;
  background: #ebeff2;
}
.contact-origins-list .contact-origins-list-paginator-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
}
.origins-filters-bar .ui.form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.origins-filters-bar .ui.form .field,
.origins-filters-bar .ui.form .field:last-child {
  margin-bottom: 0 !important;
}
.origins-filters-bar .ui.form #formik-semantic-ui-react-submit-button {
  height: unset !important;
  width: unset !important;
}
.origins-filters-bar .ui.form .origin-name-input,
.origins-filters-bar .ui.form .origin-vatNumber-input {
  background-color: #FFFFFF;
  width: 13rem !important;
  margin-right: 1rem;
}
.origins-filters-bar .ui.form .origin-name-input > input[type='text'],
.origins-filters-bar .ui.form .origin-vatNumber-input > input[type='text'] {
  border: 1px solid rgba(120, 120, 120, 0.24) !important;
  border-radius: 2px !important;
  padding: 0.8rem 1rem 0.65rem 1rem !important;
}
.origins-filters-bar .ui.form .origin-filters-search button,
.origins-filters-bar .ui.form .origin-filters-search:hover button {
  margin-right: 0.5 !important;
  background-color: #27AE60 !important;
  border-radius: 2px !important;
  color: #FFFFFF !important;
}
.origins-filters-bar .ui.form .origin-filters-search:hover button {
  background-color: #219351 !important;
}
.origins-filters-bar .ui.form .origin-filters-reset {
  padding: 0.5rem !important;
}
.years-filterBar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.years-filterBar-container .years-filter-button {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10rem;
  padding: 1rem 2rem;
  border: 1px solid rgba(128, 144, 160, 0.65);
  cursor: pointer;
}
.years-filterBar-container .years-filter-button:hover:not(.years-filter-button-active) {
  background-color: #e3e6e9;
}
.years-filterBar-container .years-filter-button-active {
  background-color: #FFFFFF;
  border: 2px solid #3C3C3B;
}
.metal-resume-filters-bar {
  width: 95%;
}
.metal-resume-filters-bar .ui.form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.metal-resume-filters-bar .ui.form .field,
.metal-resume-filters-bar .ui.form .field:last-child {
  margin-bottom: 0 !important;
}
.metal-resume-filters-bar .ui.form #formik-semantic-ui-react-submit-button {
  height: unset !important;
  width: unset !important;
}
.metal-resume-filters-bar .ui.form .metal-resume-number-input {
  background-color: #FFFFFF;
  width: 13rem !important;
  margin-right: 1rem;
}
.metal-resume-filters-bar .ui.form .metal-resume-number-input > input[type='text'] {
  border: 1px solid rgba(120, 120, 120, 0.24) !important;
  border-radius: 2px !important;
  padding: 0.8rem 1rem 0.65rem 1rem !important;
}
.metal-resume-filters-bar .ui.form .metal-resume-date-input {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  width: 10rem !important;
  margin-right: 1rem;
}
.metal-resume-filters-bar .ui.form .metal-resume-date-input > input[type='text'] {
  border: 1px solid rgba(120, 120, 120, 0.24) !important;
  border-radius: 2px !important;
  padding: 0.8rem 1rem 0.65rem 1rem !important;
}
.metal-resume-filters-bar .ui.form .metal-resume-date-input .calendar-modal-button {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 100%;
  padding: 0.5rem;
}
.metal-resume-filters-bar .ui.form .metal-resume-date-input .calendar-modal-button:hover {
  background: #e3e6e9;
}
.metal-resume-filters-bar .ui.form .metal-resume .date-input {
  position: fixed;
  display: flex;
  align-items: center;
}
.metal-resume-filters-bar .ui.form .metal-resume .date-input .calendar-modal-button {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  border-radius: 100%;
  padding: 0.5rem;
}
.metal-resume-filters-bar .ui.form .metal-resume .date-input .calendar-modal-button:hover {
  background: #e3e6e9;
}
.metal-resume-filters-bar .ui.form .metal-resume-filters-search button,
.metal-resume-filters-bar .ui.form .metal-resume-filters-search:hover button {
  margin-right: 0.5 !important;
  background-color: #27AE60 !important;
  border-radius: 2px !important;
  color: #FFFFFF !important;
}
.metal-resume-filters-bar .ui.form .metal-resume-filters-search:hover button {
  background-color: #219351 !important;
}
.metal-resume-filters-bar .ui.form .metal-resume-filters-reset {
  padding: 0.5rem !important;
}
.customer-article-phase-container {
  width: 100%;
}
.customer-article-phase-container .customer-orgins-select-container {
  margin-bottom: 2rem;
}
.customer-article-phase-container .customer-orgins-select-container .selection.ui.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 40rem;
}
.customer-article-phase-container .customer-articlePhase-table-empty {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.813rem;
}
.customer-article-phase-container .MuiCheckbox-colorPrimary.Mui-checked {
  color: #FDC300 !important;
}
.customer-article-phase-container .MuiCheckbox-colorPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.06);
}
.customer-article-phase-container .customer-articlePhase-table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.8rem;
  font-weight: 600;
  font-size: 1rem;
  color: #3C3C3B;
}
.typeSelection-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.typeSelection-container .typeSelection-title {
  text-transform: uppercase;
  margin-top: 3rem;
  font-weight: 700;
  font-size: 1.375rem;
  color: #3C3C3B;
  text-align: center;
}
.typeSelection-container .typeSelection-subtitle {
  margin-top: 0.8rem;
  font-weight: 400;
  font-size: 1rem;
  color: #3C3C3B;
  text-align: center;
}
.typeSelection-container .typeSelection-buttonsContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}
.typeSelection-container .typeSelection-buttonsContainer .typeSelection-button {
  width: 15rem;
  height: 4rem;
  border: 1px solid #3C3C3B;
  border-radius: 2px;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  color: #3C3C3B;
}
.typeSelection-container .typeSelection-buttonsContainer .typeSelection-button:hover {
  background-color: #FDC300;
  border: 2px solid #FDC300;
}
div.admin-dashboard {
  margin: 1.5rem 3rem;
}
div.admin-dashboard div.calendar-header-dashboard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}
div.admin-dashboard div.calendar-header-dashboard img.ui.image {
  width: 3rem;
}
div.admin-dashboard div.calendar-header-dashboard span.title-text {
  color: #3C3C3B;
  font-size: 1.375rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
div.admin-dashboard div.calendar-header-dashboard img.ui.image.right-icon {
  margin-left: auto;
}
div.admin-dashboard .booking-calendar-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;
}
div.admin-dashboard .booking-calendar-loading-text {
  padding-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
div.admin-dashboard .dashboard-reservation-card {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  border-bottom: 1px solid #d6d6d3;
}
div.admin-dashboard .dashboard-reservation-card span {
  display: flex;
  align-items: center;
}
div.admin-dashboard .dashboard-reservation-card .dashboard-reservation-card-date {
  width: 30%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
div.admin-dashboard .dashboard-reservation-card .dashboard-reservation-card-date .delivery-date {
  font-weight: 600;
  font-size: 1rem;
}
div.admin-dashboard .dashboard-reservation-card .dashboard-reservation-card-date .delivery-timeslot {
  font-weight: 500;
  font-size: 0.875rem;
}
div.admin-dashboard .dashboard-reservation-card .dashboard-reservation-card-vehcilePlate {
  width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  font-weight: 600;
  font-size: 1rem;
}
div.admin-dashboard .dashboard-reservation-card .dashboard-reservation-card-bookingNumber {
  width: 40%;
  font-weight: 500;
  font-size: 0.875rem;
  text-decoration: underline;
  display: flex;
  justify-content: center;
}
.agent-dashboard-container {
  background: #f2f2f2;
  margin: 1.5rem 3rem;
  height: 80%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.agent-dashboard-container .selected-user-data-card {
  width: min(80%, 40rem);
  padding: 1rem 2rem;
  border-radius: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}
.agent-dashboard-container .selected-user-data-card .text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.agent-dashboard-container .selected-user-data-card .text-section .title {
  text-align: center;
  font-weight: 600;
  font-size: 1.375rem;
  color: #3C3C3B;
  line-height: 1.5;
}
.agent-dashboard-container .selected-user-data-card .ui.selection.dropdown {
  width: 100%;
}
.agent-dashboard-container .selected-user-data-card-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1rem;
  color: #3C3C3B;
}
.agent-dashboard-container .selected-user-data-card-row svg {
  margin-right: 1rem;
}
div.empty-supplier-dashboard {
  background: #f2f2f2;
  margin: 1.5rem 3rem;
  height: 80%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
div.empty-supplier-dashboard img.image {
  width: 7rem;
  margin-bottom: 3rem;
}
div.empty-supplier-dashboard div.text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60%;
  margin-bottom: 2rem;
}
div.empty-supplier-dashboard div.text-section div.title {
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  color: #3C3C3B;
  line-height: 1.5;
}
div.empty-supplier-dashboard div.text-section div.subtitle {
  text-align: center;
  font-weight: 500;
  font-size: 1.25rem;
  color: #3C3C3B;
  margin-top: 1rem;
}
div.empty-supplier-dashboard button.ui.button {
  margin: 0rem;
  height: 3.5rem;
}
div.supplier-dashboard {
  margin: 1.5rem 3rem;
}
div.supplier-dashboard .dashboard-reservation-card {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  border-bottom: 1px solid #d6d6d3;
}
div.supplier-dashboard .dashboard-reservation-card span {
  display: flex;
  align-items: center;
}
div.supplier-dashboard .dashboard-reservation-card .dashboard-reservation-card-date {
  width: 220px;
  display: flex;
  flex-direction: column;
}
div.supplier-dashboard .dashboard-reservation-card .dashboard-reservation-card-date .delivery-date {
  font-weight: 600;
  font-size: 1rem;
}
div.supplier-dashboard .dashboard-reservation-card .dashboard-reservation-card-date .delivery-timeslot {
  font-weight: 500;
  font-size: 0.875rem;
}
div.supplier-dashboard .dashboard-reservation-card .dashboard-reservation-card-vehcilePlate {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 1rem;
}
div.supplier-dashboard .dashboard-reservation-card .dashboard-reservation-card-bookingNumber {
  font-weight: 500;
  font-size: 0.875rem;
  text-decoration: underline;
}
div.supplier-dashboard .booking-calendar-segment {
  position: relative;
}
div.supplier-dashboard .booking-calendar-segment .link-approved-reservation {
  padding: 0.3rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-weight: 600;
  color: #3C3C3B;
  text-decoration: underline;
  cursor: pointer;
}
div.supplier-dashboard div.calendar-header-dashboard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
div.supplier-dashboard div.calendar-header-dashboard img.ui.image {
  width: 3rem;
}
div.supplier-dashboard div.calendar-header-dashboard span.title-text {
  color: #3C3C3B;
  font-size: 1.375rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
div.supplier-dashboard div.calendar-header-dashboard .calendar-icon {
  margin-left: auto;
  cursor: pointer;
}
div.supplier-dashboard .booking-calendar-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;
}
div.supplier-dashboard .booking-calendar-loading-text {
  padding-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.customer-agent-dashboard-container {
  background: #f2f2f2;
  margin: 1.5rem 3rem;
  min-height: 80%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}
.customer-agent-dashboard-container .align-left {
  text-align: left !important;
}
.customer-agent-dashboard-container .actionMenu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebeff2;
  padding: 0.6rem;
  border-radius: 8px;
  border: 1px solid #737a8f;
  cursor: pointer;
}
.customer-agent-dashboard-container .margin-right {
  margin-right: 0.5rem;
}
div.certificates-card {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  border-radius: 4px;
}
div.certificates-card div.resume-left-car {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  width: clamp(20rem, 20%, 50rem);
}
div.certificates-card div.resume-left-car div.subtitle-row {
  margin-top: auto;
}
div.certificates-card div.resume-left-car div.subtitle-row div.subtitle-text {
  font-weight: 700;
  font-size: 1.375rem;
  color: #3C3C3B;
}
div.certificates-card div.resume-left-car div.subtitle-row div.subtitle-link {
  align-items: center;
  display: flex;
}
div.certificates-card div.resume-left-car div.subtitle-row div.subtitle-link span.underline-text {
  margin-right: 2rem;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
  color: #3C3C3B;
}
div.certificates-card div.resume-left-car div.subtitle-row div.subtitle-link span.link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
div.certificates-card div.resume-left-car:after {
  content: "";
  background: rgba(128, 144, 160, 0.65);
  position: absolute;
  right: 0;
  top: 2.5rem;
  bottom: 2.5rem;
  width: 1px;
}
div.certificates-card div.list-certificates-card {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  /**
      * Template GRID, use all the available space with minimum element size (5rem in this case)
      * If the next element cannot respect the minimum size it goes on the next line
      */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
}
div.certificates-card div.list-certificates-card div.certificate-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.5rem;
}
div.certificates-card div.list-certificates-card div.certificate-element img.image {
  width: min(85%, 7rem);
}
div.certificates-card div.list-certificates-card div.certificate-element div.certificate-link {
  margin-top: 1rem;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
  color: #3C3C3B;
}
div.empty-supplier-dashboard {
  background: #f2f2f2;
  margin: 1.5rem 3rem;
  height: 80%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
div.empty-supplier-dashboard img.image {
  width: 7rem;
  margin-bottom: 3rem;
}
div.empty-supplier-dashboard div.text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60%;
  margin-bottom: 2rem;
}
div.empty-supplier-dashboard div.text-section div.title {
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  color: #3C3C3B;
  line-height: 1.5;
}
div.empty-supplier-dashboard div.text-section div.subtitle {
  text-align: center;
  font-weight: 500;
  font-size: 1.25rem;
  color: #3C3C3B;
  margin-top: 1rem;
}
div.empty-supplier-dashboard button.ui.button {
  margin: 0rem;
  height: 3.5rem;
}
div.supplier-dashboard {
  margin: 1.5rem 3rem;
}
div.supplier-dashboard .dashboard-reservation-card {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  border-bottom: 1px solid #d6d6d3;
}
div.supplier-dashboard .dashboard-reservation-card span {
  display: flex;
  align-items: center;
}
div.supplier-dashboard .dashboard-reservation-card .dashboard-reservation-card-date {
  width: 30%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
div.supplier-dashboard .dashboard-reservation-card .dashboard-reservation-card-date .delivery-date {
  font-weight: 600;
  font-size: 1rem;
}
div.supplier-dashboard .dashboard-reservation-card .dashboard-reservation-card-date .delivery-timeslot {
  font-weight: 500;
  font-size: 0.875rem;
}
div.supplier-dashboard .dashboard-reservation-card .dashboard-reservation-card-vehcilePlate {
  width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  font-weight: 600;
  font-size: 1rem;
}
div.supplier-dashboard .dashboard-reservation-card .dashboard-reservation-card-bookingNumber {
  width: 40%;
  font-weight: 500;
  font-size: 0.875rem;
  text-decoration: underline;
  display: flex;
  justify-content: center;
}
div.supplier-dashboard .booking-calendar-segment {
  position: relative;
}
div.supplier-dashboard .booking-calendar-segment .link-approved-reservation {
  margin-top: 1rem;
  padding: 0.3rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-weight: 600;
  color: #3C3C3B;
  text-decoration: underline;
  cursor: pointer;
}
div.supplier-dashboard div.calendar-header-dashboard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
div.supplier-dashboard div.calendar-header-dashboard img.ui.image {
  width: 3rem;
}
div.supplier-dashboard div.calendar-header-dashboard span.title-text {
  color: #3C3C3B;
  font-size: 1.375rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
div.supplier-dashboard div.calendar-header-dashboard .calendar-icon {
  margin-left: auto;
  cursor: pointer;
}
div.supplier-dashboard .booking-calendar-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;
}
div.supplier-dashboard .booking-calendar-loading-text {
  padding-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
div.supplier-dashboard .calendar-content {
  margin-bottom: 2.5rem;
}
.right-download-button {
  margin-left: auto;
}
.right-download-button .dashboard-button {
  padding: 0.9rem 1rem !important;
}
.right-download-button .dashboard-button:first-child {
  margin-right: 0.5rem;
}
.right-download-button .dashboard-download-button {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.875rem;
}
.right-download-button .dashboard-download-button svg {
  margin-right: 0.3rem;
}
.doc-viewer {
  background: #f2f2f2;
  min-height: 80%;
  margin: 1rem 3rem;
  padding: 2rem;
  border-radius: 2px;
  min-width: 70rem;
  display: flex;
}
.doc-viewer-data {
  width: 40%;
  padding: 2rem;
}
.doc-viewer-data div.ui.label {
  font-size: 0.875rem;
  font-weight: 400;
  color: #3C3C3B;
  margin-left: 2px;
}
.doc-viewer-data-action {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.doc-viewer-data-action button.back-button:hover,
.doc-viewer-data-action button.back-button:focus,
.doc-viewer-data-action button.back-button {
  text-decoration: underline;
  background: transparent;
  color: #3C3C3B;
  padding: 0 !important;
  margin-right: 1.5rem;
}
.doc-viewer-file {
  width: 60%;
  background: #e3e6e9;
  display: flex;
  justify-content: center;
  position: relative;
}
.doc-viewer-file .react-pdf__Document {
  display: flex;
  align-items: center;
}
.doc-viewer-file-pages {
  position: absolute;
  bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  padding: 0.5rem 1rem;
  border-radius: 24px;
  border: solid 1px black;
  z-index: 1;
  opacity: 0;
}
.doc-viewer-file-pages span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.doc-viewer-file-pages .left-button {
  margin-right: 0.5rem;
  padding: 1rem;
  border-radius: 100%;
  cursor: pointer;
}
.doc-viewer-file-pages .right-button {
  margin-left: 0.5rem;
  padding: 1rem;
  border-radius: 100%;
  cursor: pointer;
}
.doc-viewer-file-pages .left-button-disable,
.doc-viewer-file-pages .right-button-disable {
  cursor: default;
}
.doc-viewer-file-pages .left-button:hover,
.doc-viewer-file-pages .right-button:hover {
  background-color: #f2f2f2;
}
.doc-viewer-file-pages .left-button-disable:hover,
.doc-viewer-file-pages .right-button-disable:hover {
  background-color: transparent;
}
.doc-viewer-file:hover .doc-viewer-file-pages {
  opacity: 1;
}
.documents-table-empty {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
}
.documents-table-header {
  text-align: center;
  font-size: 0.75rem;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 600;
}
.documents-table table.ui.celled.table {
  border: 0;
}
.documents-table .ui.celled.table tr th,
.documents-table .ui.celled.table tr td {
  border-left: 0 !important;
}
.documents-table th.ui.celled.table {
  border-bottom: 1px solid rgba(120, 120, 120, 0.24);
}
.documents-table th {
  background: #3C3C3B !important;
  padding: 0.5rem 0.5rem 0.3rem !important;
}
.documents-table-expirationDate {
  display: flex;
  align-items: center;
  justify-content: center;
}
.documents-table-expirationDate-container {
  width: 8.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 20px;
  background: #f2f2f2;
}
.documents-table-state {
  display: flex;
  align-items: center;
  justify-content: center;
}
.documents-table-state-container {
  width: 9rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  border-radius: 20px;
  background: #f2f2f2;
}
.documents-table-attachmentsNumber {
  display: flex;
  align-items: center;
  justify-content: center;
}
.documents-table-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.documents-table-actions .attachments-dropdown {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.documents-table-actions .attachments-dropdown .text {
  padding-left: 0.5rem;
}
div.emptyReservations {
  padding-top: 3rem;
  text-align: center;
  margin-right: auto;
}
div.emptyReservations img.ui.image {
  display: unset;
  align-items: 'center' !important;
  margin-top: 1rem;
}
div.emptyReservations .ui.header {
  color: #3C3C3B;
  margin-top: 1.5rem !important;
}
div.emptyReservations .sub.header {
  margin-top: 1.5rem !important;
  margin-bottom: 3rem !important;
}
div.emptyReservations .ui.button {
  margin-bottom: 7.8% !important;
  margin-top: 1% !important;
}
.file-input-field {
  text-align: left;
  width: 30rem;
}
.file-input-field .input-field-label {
  color: #3C3C3B;
  text-align: left !important;
  font-weight: 400;
  font-size: 0.875rem;
  padding-bottom: 4px !important;
  text-align: left;
}
.file-input-field .field {
  background-color: #FFFFFF;
}
.file-input-field .fileNotSet {
  cursor: pointer;
}
.file-input-field .fileUploadField {
  border: 1px solid rgba(128, 144, 160, 0.65);
  text-align: left !important;
}
.file-input-field .fileUploadField button {
  height: 46px;
  padding: 0.7rem !important;
  background-color: transparent !important;
}
.file-input-field .fileUploadField .crossButton {
  padding: 0 !important;
}
.file-input-field .fileUploadField .crossButton .crossIcon {
  padding: 0 !important;
  color: #3C3C3B;
  opacity: 1 !important;
}
.file-input-field .fileUploadField .labelGrid {
  font-family: 'Futura Std', 'Helvetica', sans-serif;
  padding-left: 1rem;
}
.file-input-field .fileUploadField .labelGrid .placeholder {
  font-size: 1rem;
  color: #737a8f;
  opacity: 0.5;
  vertical-align: middle;
}
.file-input-field .fileUploadField .labelGrid .fileUploaded {
  font-size: 1rem;
  font-weight: 500;
  color: #3C3C3B;
}
.file-input-field .disabled.field.fileUploadField button {
  opacity: 0.5;
}
.ui.grid.filterBar {
  padding-bottom: 2rem;
  padding-top: 1rem;
  padding-left: 1rem;
}
.ui.grid.filterBar .filterButton button {
  margin-inline: 1em;
  height: 100%;
}
.ui.grid.filterBar .ui.input {
  margin-right: 1rem;
}
.ui.grid.filterBar .ui.input input {
  background: white !important;
}
.ui.grid.filterBar .mainButton {
  border-radius: 0.2rem !important;
  background: #27AE60 !important;
  color: #FFFFFF;
}
div.ui.page.modals div.ui.modal.language-update-modal div.header {
  justify-content: center;
}
div.ui.page.modals div.ui.modal.language-update-modal div.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-contact-selected-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  min-height: 80%;
  min-width: 70rem;
  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;
}
.no-contact-selected-container .no-contact-selected-title {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.375rem;
}
.no-contact-selected-container .no-contact-selected-title svg {
  margin-right: 2rem;
}
.no-contact-selected-container .no-contact-selected-subtitle {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 1rem;
}
.no-contact-selected-container .no-contact-selected-subtitle span {
  margin-top: 0.5rem;
}
div.page-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0rem 2rem;
  padding: 1.5rem 0rem;
}
div.page-header span.title {
  font-family: 'Futura Std', 'Helvetica', sans-serif;
  color: #FFFFFF;
  font-size: 1.75rem;
  font-weight: 500;
}
div.page-header img.image.title-icon {
  height: 80%;
  padding-left: 1rem;
}
div.page-header div.right-icon {
  margin-left: auto;
}
div.page-header.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.ui.pagination.menu.paginator {
  color: #3C3C3B;
  min-height: auto;
  background-color: transparent;
  border: 0px;
  box-shadow: none;
}
.ui.pagination.menu.paginator .item {
  box-shadow: none;
  background-color: #FFFFFF;
  border-radius: 2px !important;
  max-height: 2.5rem;
  margin-left: 0.4rem;
  color: #3C3C3B;
  font-size: 0.875rem;
  font-weight: 500;
}
.ui.pagination.menu.paginator .item::before {
  background: none;
}
.ui.pagination.menu.paginator .active.item {
  background-color: #3C3C3B;
  color: #FFFFFF;
  font-weight: 600;
}
div.ui.page.modals div.ui.modal.password-update-modal div.content {
  padding: 1rem 2.5rem;
}
div.ui.page.modals div.ui.modal.password-update-modal div.content div.field {
  width: 100%;
}
div.ui.page.modals div.ui.modal.password-update-modal div.content div.field div.ui.input {
  width: 100% !important;
}
.select-order-table-container {
  width: 100%;
}
.select-order-table-container table.ui.celled.table {
  border: 0;
}
.select-order-table-container .ui.celled.table tr th,
.select-order-table-container .ui.celled.table tr td {
  border-left: 0 !important;
}
.select-order-table-container th.ui.celled.table {
  border-bottom: 1px solid rgba(120, 120, 120, 0.24);
}
.select-order-table-container th {
  background: #3C3C3B !important;
  padding: 0.5rem 0.5rem 0.3rem !important;
}
.select-order-table-container th .select-order-table-header {
  text-align: center;
  font-size: 0.75rem;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 400;
}
.select-order-table-container .select-order-table-orderNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.select-order-table-container .select-order-table-orderNumber .order-number {
  font-weight: 500;
  color: #3C3C3B;
}
.select-order-table-container .select-order-table-orderNumber .line-no {
  font-weight: 400;
  font-size: 0.875rem;
  color: #3C3C3B;
}
.select-order-table-container .select-order-table-residual-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.select-order-table-container .select-order-table-residual-quantity-icon {
  display: flex;
  align-items: baseline;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 400;
}
.select-order-table-container .select-order-table-residual-quantity-icon svg {
  margin-right: 0.5rem;
}
.select-order-table-container .select-order-table-residual-quantity-value {
  text-align: center;
  font-size: 1rem;
  color: #3C3C3B;
  font-weight: 700;
}
.select-order-table-container .select-order-table-item {
  font-weight: 600;
  font-size: 0.875rem;
  text-align: center;
}
.select-order-table-container .select-order-table-delivery-date {
  text-align: center;
  font-size: 0.875rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 400;
}
.select-order-table-container .select-order-table-action {
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-order-table-container .select-order-table-action .MuiCheckbox-colorPrimary.Mui-checked {
  color: #FDC300 !important;
}
.select-order-table-container .select-order-table-action .MuiCheckbox-colorPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.06);
}
.select-order-table-container .select-order-table-empty {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
}
.article-phase {
  width: 100%;
}
.article-phase-content .article-phase-filter-bar {
  margin: 0.5rem;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.article-phase-content .article-phase-filter-bar div.field {
  margin: 0;
  margin-right: 2rem;
}
.article-phase-content .article-phase-filter-bar-input {
  background-color: #FFFFFF;
  width: 11rem;
  margin-right: 1rem;
}
.article-phase-content .article-phase-filter-bar-input > input[type='text'] {
  border: 1px solid rgba(120, 120, 120, 0.24) !important;
  border-radius: 2px !important;
  padding: 0.8rem 1rem 0.65rem 1rem !important;
}
.article-phase-content .article-phase-filter-bar-search,
.article-phase-content .article-phase-filter-bar-search:hover {
  margin-right: 1.5rem !important;
  background-color: #27AE60 !important;
  border-radius: 2px !important;
  color: #FFFFFF !important;
}
.article-phase-content .article-phase-filter-bar-search:hover {
  background-color: #219351 !important;
}
.article-phase-content .article-phase-paginator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.article-phase-content .article-phase-paginator-orderLines {
  font-weight: 600;
  font-size: 1rem;
  color: #3C3C3B;
}
.base-information {
  width: 100%;
}
.base-information-content .orders-filters-bar-container {
  margin: 0;
}
.base-information-content .reservation-form-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #D6D6D3;
  margin: 1.5rem 0;
  padding: 0;
}
.base-information-content .max-quantity {
  text-align: left;
  font-size: 0.875rem;
  color: #3C3C3B;
  font-weight: 400;
}
.base-information .reservation-description-field {
  margin-left: 2rem;
  padding: 1.5rem;
  border-left: 4px solid #FDC300;
  width: 50%;
  color: #3C3C3B;
  font-weight: 600;
  font-size: 1rem;
}
.delivery-information {
  width: 100%;
}
.delivery-information-content .orders-filters-bar-container {
  margin: 0;
}
.delivery-information-content .reservation-form-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #D6D6D3;
  margin: 1.5rem 0;
  padding: 0;
}
.delivery-information .delivery-fields-description {
  font-size: 0.875rem;
  color: #3C3C3B !important;
  font-weight: 400;
}
.delivery-information input:read-only {
  background: #FFFFFF !important;
  cursor: pointer;
}
.delivery-information .reservation-border-top {
  border-top: 1px solid rgba(128, 144, 160, 0.65);
  padding-top: 0.5rem;
}
.delivery-information .reservation-timeslot-description {
  margin-left: 2rem;
  padding: 1.5rem;
  border-left: 2px solid #FDC300;
  width: 50%;
  color: #3C3C3B;
  font-weight: 500;
  font-size: 1rem;
}
.document-phase {
  width: 100%;
}
.document-phase-content .orders-filters-bar-container {
  margin: 0;
}
.document-phase-content .reservation-form-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #D6D6D3;
  margin: 1.5rem 0;
  padding: 0;
}
.document-phase .document-phase-content .document-phase-horizontal-divider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #27AE60;
  color: #FFFFFF;
  font-weight: 500;
  padding: 1rem;
  border-radius: 2px;
}
.document-phase .document-phase-content .document-phase-horizontal-divider svg {
  margin-right: 1rem;
}
.document-phase .document-phase-content .document-phase-dropzone {
  margin-top: 1.5rem;
  min-height: 6rem;
}
.document-phase .document-phase-content .document-phase-dropzone .dropzone {
  min-height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 2px dashed #3C3C3B;
  border-radius: 2px;
  cursor: pointer;
}
.document-phase .document-phase-content .document-phase-dropzone .dropzone-text-section {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 1rem 0;
}
.document-phase .document-phase-content .document-phase-dropzone .dropzone-text-section .image {
  margin: 1rem 2rem;
}
.document-phase .document-phase-content .document-phase-dropzone .dropzone-text-section span {
  color: #3C3C3B;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 2rem;
}
.document-phase .document-phase-content .document-phase-dropzone .dropzone-button-section button.dropzone-upload-button {
  margin: 1rem 2rem;
  border-radius: 2px !important;
  background-color: #4F4F4F;
  color: #FFFFFF;
}
.document-phase .document-phase-content .document-phase-document-list {
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: #3C3C3B;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(128, 144, 160, 0.65);
}
.document-phase .document-phase-content .document-phase-document-list .document-phase-document-list-container {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.document-phase .document-phase-content .document-phase-uploaded-document {
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: #3C3C3B;
}
.document-phase .document-phase-content .document-phase-uploaded-document .document-phase-uploaded-document-list {
  padding: 1rem;
  font-size: 1rem;
  font-weight: 400;
}
.document-phase .document-phase-content .document-phase-uploaded-document .document-phase-uploaded-document-list .single-uploaded-document {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  border-radius: 2px;
}
.document-phase .document-phase-content .document-phase-uploaded-document .document-phase-uploaded-document-list .accordion.optional-document-accordion {
  width: 100%;
}
.document-phase .document-phase-content .document-phase-uploaded-document .document-phase-uploaded-document-list .accordion.optional-document-accordion img.accordion-open {
  transform: rotate(180deg);
}
.document-phase .document-phase-content .document-phase-uploaded-document .document-phase-uploaded-document-list .accordion.optional-document-accordion .optional-document-accordion-content {
  padding: 0.5rem 2rem 1rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.document-phase .document-phase-content .document-phase-uploaded-document .document-phase-uploaded-document-list .accordion.optional-document-accordion .optional-document-accordion-content .optional-document-loading {
  font-size: 0.75rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 1rem;
}
.document-phase .document-phase-content .document-phase-uploaded-document .document-phase-uploaded-document-list .accordion.optional-document-accordion .title {
  padding: 0;
}
.document-phase .document-phase-content .document-phase-uploaded-document .document-phase-uploaded-document-list .accordion.optional-document-accordion .title .single-uploaded-document-name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}
.document-phase .document-phase-content .document-phase-uploaded-document .document-phase-uploaded-document-list .accordion.optional-document-accordion .title .single-uploaded-document-name:hover {
  background-color: #e3e6e9;
}
.document-phase .document-phase-content .document-phase-uploaded-document .document-phase-uploaded-document-list .standard-dropdown-menu {
  display: flex;
  align-items: top;
  justify-content: center;
}
.finished-phase {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.finished-phase img.image {
  width: 7rem;
}
.finished-phase span.title-text {
  text-transform: uppercase;
  margin-top: 3rem;
  font-weight: 700;
  font-size: 1.375rem;
  color: #3C3C3B;
  text-align: center;
}
.finished-phase span.subtitle-text {
  padding-top: 1.5rem;
  font-weight: 400;
  font-size: 1rem;
  color: #3C3C3B;
  text-align: center;
  margin-bottom: 1rem;
}
.finished-phase button {
  margin-top: 0.5rem !important;
}
.step-menu-container {
  width: 100%;
  padding-top: 4rem;
}
.step-menu-container .step-menu-element {
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: fit-content;
  margin-bottom: 4rem;
}
.step-menu-container .step-menu-element-text {
  display: flex;
  align-items: center;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 3rem;
  text-align: end;
  font-size: 1.125rem;
  font-weight: 500;
  color: #3C3C3B;
}
.step-menu-container .step-menu-element-icon {
  background-color: #FFFFFF;
  border: 1px solid #D6D6D3;
  border-radius: 2px;
  height: 3rem;
  width: 3rem;
  position: absolute;
  right: -1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  color: #3C3C3B;
}
.step-menu-container .step-menu-element.step-active .step-menu-element-text {
  font-weight: 600;
}
.step-menu-container .step-menu-element.step-active .step-menu-element-icon {
  background-color: #FDC300;
  font-weight: 600;
}
.to-approve {
  width: 100%;
}
.to-approve-content .orders-filters-bar-container {
  margin: 0;
}
.to-approve-content .reservation-form-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #D6D6D3;
  margin: 1.5rem 0;
  padding: 0;
}
.to-approve-content .max-quantity {
  text-align: left;
  font-size: 0.875rem;
  color: #3C3C3B;
  font-weight: 400;
}
.to-approve .reservation-lines-title {
  font-weight: 600;
  font-size: 1.125rem;
  color: #3C3C3B;
}
.to-approve .reservation-description-field {
  margin-left: 2rem;
  padding: 1.5rem;
  border-left: 4px solid #FDC300;
  color: #3C3C3B;
  font-weight: 600;
  font-size: 1rem;
}
.to-approve .reservation-form-qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.to-approve .reservation-form-qrcode svg {
  width: 10rem;
  height: 10rem;
}
.reservation-card {
  height: 6rem;
  background-color: #FFFFFF;
  border-radius: 3px;
  margin: 8px 6px;
  flex-grow: 1;
  display: inline-block;
  width: calc(100% * (1 / 2) - 12px - 1px);
  max-width: calc(100% * (1 / 2) - 12px - 1px);
  padding: 0.5rem;
  cursor: pointer;
}
.reservation-card-content {
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.reservation-card-content-description {
  display: flex;
  flex-direction: column;
}
.reservation-card-content-description .card-description-orderNumber {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #1c1c1c;
  font-size: 0.875rem;
}
.reservation-card-content-description .card-description-orderNumber svg {
  margin-right: 0.5rem;
}
.reservation-card-content-description .card-description-descriptionText {
  padding-top: 0.5rem;
  height: 3.5rem;
  font-weight: 600;
  color: #3C3C3B;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@supports (-webkit-line-clamp: 2) {
  .reservation-card-content-description .card-description-descriptionText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.reservation-card-content-weight {
  display: flex;
  align-items: flex-end;
}
.reservation-card-content-weight .card-weight-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5rem;
  padding: 0.3rem;
  background-color: #f5f5f5;
  border-radius: 2px;
}
.reservation-card-content-weight .card-weight-unit {
  font-weight: 500;
  color: #989898;
  font-size: 0.625rem;
  text-transform: uppercase;
}
.reservation-card-content-weight .card-weight-value {
  font-weight: 500;
  color: #1c1c1c;
  font-size: 1rem;
}
.closed {
  border-left: 0.4rem solid #787878;
}
.to_approve {
  border-left: 0.4rem solid #e00000;
}
.approved {
  border-left: 0.4rem solid #36b359;
}
.reservations-list {
  background: #f2f2f2;
  min-height: 80%;
  min-width: 70rem;
  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;
}
.reservations-list .reservation-accordion-content-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
}
.reservations-list .reservation-paginator-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
}
.reservation-accordion {
  margin: 1rem;
  width: 95%;
  background: #FFFFFF;
  border: 1px solid rgba(120, 120, 120, 0.24);
}
.reservation-accordion .reservations-accordion-actionMenu {
  display: flex;
}
.reservation-accordion .standard-dropdown-menu {
  margin-left: 0.7rem;
  padding: 0.4rem;
  border-radius: 12px;
  background: #ebeff2;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.reservation-accordion-title {
  padding: 1rem 2rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reservation-accordion-title .reservation-vehiclePlate-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.reservation-accordion-title .reservation-vehiclePlate-title {
  font-size: 0.75rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 400;
}
.reservation-accordion-title .reservation-vehiclePlate-value {
  font-weight: 700;
  font-size: 1.125rem;
}
.reservation-accordion-title .reservation-date-section {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.reservation-accordion-title .reservation-date-value {
  color: #3C3C3B;
  font-weight: 500;
  font-size: 1rem;
}
.reservation-accordion-title .reservation-date-bold {
  font-weight: 700;
}
.reservation-accordion-title img.reservation-accordion-icon {
  padding: 0.5rem;
}
.reservation-accordion-title img.accordion-open {
  transform: rotate(180deg);
}
.reservation-accordion-content {
  padding: 0.5rem 2rem 1rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.reservation-accordion-content-loading {
  font-size: 0.75rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 1rem;
}
.reservation-filters {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reservation-filters .field {
  margin: 0 !important;
  margin-right: 1rem !important;
}
.reservation-filters .reservation-number-input {
  background-color: #FFFFFF;
  width: 13rem;
  margin-right: 2rem !important;
}
.reservation-filters .reservation-number-input > input[type='text'] {
  border: 1px solid rgba(120, 120, 120, 0.24) !important;
  border-radius: 2px !important;
  padding: 0.8rem 1rem 0.65rem 1rem !important;
}
.reservation-filters .reservation-filters-search,
.reservation-filters .reservation-filters-search:hover {
  margin-right: 1.5rem !important;
  background-color: #27AE60 !important;
  border-radius: 2px !important;
  color: #FFFFFF !important;
}
.reservation-filters .reservation-filters-search:hover {
  background-color: #219351 !important;
}
.reservation-filters .reservation-filters-reset {
  padding: 1rem !important;
}
.reservationsLine-list {
  width: 100%;
}
.reservationsLine-list table.ui.celled.table {
  border: 0;
}
.reservationsLine-list .ui.celled.table tr th,
.reservationsLine-list .ui.celled.table tr td {
  border-left: 0 !important;
}
.reservationsLine-list th.ui.celled.table {
  border-bottom: 1px solid rgba(120, 120, 120, 0.24);
}
.reservationsLine-list th {
  background: #3C3C3B !important;
  padding: 0.5rem 0.5rem 0.3rem !important;
}
.reservationsLine-list th .reservation-table-header {
  text-align: center;
  font-size: 0.75rem;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 600;
}
.reservationsLine-list .reservations-table-supplier {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.875rem;
  text-align: center;
  color: #3C3C3B;
}
.reservationsLine-list .reservations-table-supplier-name {
  font-weight: 600;
}
.reservationsLine-list .reservations-table-deliveryDate {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
  color: #3C3C3B;
}
.reservationsLine-list .reservations-table-deliveryDate-date {
  font-weight: 600;
}
.reservationsLine-list .reservations-table-deliveryDate-timeslot {
  font-weight: 400;
  font-size: 0.75rem;
}
.reservationsLine-list .reservations-table-orderNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.reservationsLine-list .reservations-table-orderNumber .order-number {
  font-weight: 500;
  color: #3C3C3B;
}
.reservationsLine-list .reservations-table-orderNumber .line-no {
  font-weight: 400;
  font-size: 0.875rem;
  color: #3C3C3B;
}
.reservationsLine-list .reservations-table-cerCode,
.reservationsLine-list .reservations-table-environmentalClass {
  font-weight: 500;
  font-size: 0.875rem;
  color: #3C3C3B;
  text-align: center;
}
.reservationsLine-list .reservations-table-vehiclePlate {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.reservationsLine-list .reservations-table-vehiclePlate-text {
  font-weight: 600;
  text-transform: uppercase;
  color: #3C3C3B;
  font-size: 0.875rem;
}
.reservationsLine-list .reservations-table-actionMenu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reservationsLine-list .reservations-table-weight {
  color: #3C3C3B;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.reservationsLine-list .reservations-table-weight .weight-icon {
  display: flex;
  align-items: baseline;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 400;
}
.reservationsLine-list .reservations-table-weight .weight-icon svg {
  margin-right: 0.5rem;
}
.reservationsLine-list .reservations-table-weight .weight-icon .weight-text {
  text-align: center;
  font-size: 1rem;
  color: #3C3C3B;
  font-weight: 700;
}
.reservationsLine-list .reservations-table-description {
  border-left: 2px solid rgba(128, 144, 160, 0.65);
  font-weight: 500;
  font-size: 1rem;
  color: #3C3C3B;
  padding-left: 0.5rem;
  text-align: center;
}
.reservationsLine-list .reservations-table-amount-value {
  text-align: center;
  font-size: 1rem;
  color: #3C3C3B;
  font-weight: 500;
}
.select-contact-modal .header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-contact-modal .header svg {
  margin-right: 1rem;
}
.select-contact-modal div.content {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.question.circle.icon.tooltip-password-icon {
  color: #FDC300;
  margin-left: 0.5rem;
}
.ui.popup.tooltip-password {
  background: white !important;
}
.ui.popup.tooltip-password .tooltip-password-content {
  color: black !important;
  font-size: 0.875rem !important;
}
.ui.popup.tooltip-password .tooltip-password-content li.tooltip-item {
  white-space: nowrap;
}
.upload-document-modal {
  /** Header */
  /** Subtitle text */
  /** field of the fileUploadField */
  /** crossIcon */
}
.upload-document-modal-header {
  display: flex;
  align-items: center;
}
.upload-document-modal-header svg {
  margin-right: 1rem;
}
.upload-document-modal div.noPaddingTop.content {
  padding-top: 0rem !important;
}
.upload-document-modal-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}
.upload-document-modal-form {
  padding: 0rem 1.5rem;
  margin-bottom: 2rem;
}
.upload-document-modal-form .date-input {
  display: flex;
  align-items: center;
}
.upload-document-modal-form .date-input .calendar-modal-button {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  border-radius: 100%;
  padding: 0.5rem;
}
.upload-document-modal-form .date-input .calendar-modal-button:hover {
  background: #e3e6e9;
}
.upload-document-modal-form .field {
  margin-bottom: 0.5rem !important;
}
.upload-document-modal-form .input-container {
  margin-bottom: 1rem;
}
.upload-document-modal-form .file-error {
  line-height: 1.5rem;
}
.upload-document-modal div.field.fileUploadField.fileNotSet {
  margin-bottom: 0.2rem !important;
  margin-top: 0.2rem;
}
.upload-document-modal button.ui.button.crossIcon {
  float: right;
  background-color: transparent;
  padding: 0.5rem !important;
}
.completeOrderTable-contianer {
  width: 95%;
}
.completeOrderTable-contianer table.ui.celled.table {
  border: 0;
}
.completeOrderTable-contianer .ui.celled.table tr th,
.completeOrderTable-contianer .ui.celled.table tr td {
  border-left: 0 !important;
}
.completeOrderTable-contianer th.ui.celled.table {
  border-bottom: 1px solid rgba(120, 120, 120, 0.24);
}
.completeOrderTable-contianer tr:nth-child(even) td {
  background: #ebeff2;
}
.completeOrderTable-contianer th {
  background: #3C3C3B !important;
  padding: 0.5rem 0.5rem 0.3rem !important;
}
.completeOrderTable-contianer th .order-table-header {
  text-align: center;
  font-size: 0.75rem;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 600;
}
.completeOrderTable-contianer th .sortable-header {
  cursor: pointer;
}
.completeOrderTable-contianer .sort-icon {
  margin-left: 0.3rem;
}
.completeOrderTable-contianer .sort-icon-rotate {
  margin-left: 0.3rem;
  transform: rotate(180deg);
}
.completeOrderTable-contianer .order-table-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.completeOrderTable-contianer .order-table-amount-icon {
  display: flex;
  align-items: self-end;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 400;
}
.completeOrderTable-contianer .order-table-amount-icon svg {
  margin-right: 0.5rem;
}
.completeOrderTable-contianer .order-table-amount-value {
  text-align: center;
  font-size: 1rem;
  color: #3C3C3B;
  font-weight: 600;
}
.completeOrderTable-contianer .order-table-item {
  font-weight: 400;
  font-size: 0.813rem;
  text-align: center;
}
.completeOrderTable-contianer .order-table-residual-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.completeOrderTable-contianer .order-table-residual-quantity-icon {
  display: flex;
  align-items: self-end;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 400;
}
.completeOrderTable-contianer .order-table-residual-quantity-icon svg {
  margin-right: 0.5rem;
}
.completeOrderTable-contianer .order-table-residual-quantity-value {
  text-align: center;
  font-size: 1rem;
  color: #3C3C3B;
  font-weight: 600;
}
.completeOrderTable-contianer .order-table-complete-percentage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.completeOrderTable-contianer .order-table-complete-percentage .circular-percentage {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 2.3rem;
  width: 2.3rem;
}
.completeOrderTable-contianer .order-table-complete-percentage .circular-percentage span.percentage-text {
  font-weight: 500;
  font-size: 0.75rem;
}
.completeOrderTable-contianer .order-table-complete-percentage .circular-percentage .MuiCircularProgress-colorPrimary {
  color: #FDC300;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.completeOrderTable-contianer .order-table-delivery-date,
.completeOrderTable-contianer .order-table-line-number {
  text-align: center;
  font-size: 0.875rem;
  color: #3C3C3B;
  text-transform: uppercase;
  font-weight: 400;
}
.completeOrderTable-contianer .order-table-delivery-date.expired-delivery-date {
  color: #EB5757;
  font-weight: 600;
  text-decoration: underline;
}
.completeOrderTable-contianer .order-table-action {
  font-weight: 600;
  font-size: 0.875rem;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
.orders-filters-bar {
  width: 95%;
}
.orders-filters-bar .ui.form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.orders-filters-bar .ui.form .field,
.orders-filters-bar .ui.form .field:last-child {
  margin-bottom: 0 !important;
}
.orders-filters-bar .ui.form #formik-semantic-ui-react-submit-button {
  height: unset !important;
  width: unset !important;
}
.orders-filters-bar .ui.form .orders-number-input {
  background-color: #FFFFFF;
  width: 13rem !important;
  margin-right: 1rem;
}
.orders-filters-bar .ui.form .orders-number-input > input[type='text'] {
  border: 1px solid rgba(120, 120, 120, 0.24) !important;
  border-radius: 2px !important;
  padding: 0.8rem 1rem 0.65rem 1rem !important;
}
.orders-filters-bar .ui.form .orders-date-input {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  width: 10rem !important;
  margin-right: 1rem;
}
.orders-filters-bar .ui.form .orders-date-input > input[type='text'] {
  border: 1px solid rgba(120, 120, 120, 0.24) !important;
  border-radius: 2px !important;
  padding: 0.8rem 1rem 0.65rem 1rem !important;
}
.orders-filters-bar .ui.form .orders-date-input .calendar-modal-button {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 100%;
  padding: 0.5rem;
}
.orders-filters-bar .ui.form .orders-date-input .calendar-modal-button:hover {
  background: #e3e6e9;
}
.orders-filters-bar .ui.form .orders .date-input {
  position: fixed;
  display: flex;
  align-items: center;
}
.orders-filters-bar .ui.form .orders .date-input .calendar-modal-button {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  border-radius: 100%;
  padding: 0.5rem;
}
.orders-filters-bar .ui.form .orders .date-input .calendar-modal-button:hover {
  background: #e3e6e9;
}
.orders-filters-bar .ui.form .orders-filters-search button,
.orders-filters-bar .ui.form .orders-filters-search:hover button {
  margin-right: 0.5 !important;
  background-color: #27AE60 !important;
  border-radius: 2px !important;
  color: #FFFFFF !important;
}
.orders-filters-bar .ui.form .orders-filters-search:hover button {
  background-color: #219351 !important;
}
.orders-filters-bar .ui.form .orders-filters-reset {
  padding: 0.5rem !important;
}
.orders-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  min-height: 80%;
  min-width: 70rem;
  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;
}
