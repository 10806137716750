div.admin-dashboard {
  margin: 1.5rem 3rem;

  div.calendar-header-dashboard {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 1rem;

    img.ui.image {
      width: 3rem;
    }

    span.title-text {
      color: @c-primary;
      font-size: @font-22;
      font-weight: 700;
      margin-left: 0.5rem;
    }

    img.ui.image.right-icon {
      margin-left: auto;
    }
  }

  .booking-calendar-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-top: 3rem;

    &-text {
      padding-top: 1rem;
      font-size: @font-16;
      font-weight: 600;
    }
  }

  .dashboard-reservation-card {
    cursor: pointer;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.5rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;

    border-bottom: 1px solid #d6d6d3;

    span {
      display: flex;
      align-items: center;
    }

    .dashboard-reservation-card-date {
      width: 30%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .delivery-date {
        font-weight: 600;
        font-size: @font-16;
      }

      .delivery-timeslot {
        font-weight: 500;
        font-size: @font-14;
      }
    }

    .dashboard-reservation-card-vehcilePlate {
      width: 30%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;

      font-weight: 600;
      font-size: @font-16;
    }

    .dashboard-reservation-card-bookingNumber {
      width: 40%;
      font-weight: 500;
      font-size: @font-14;
      text-decoration: underline;
      display: flex;
      justify-content: center;
    }
  }
}
