div.user-details-header {
  display: flex;
  align-items: center;

  padding: 1.5rem 2rem;

  span.user-complete-name {
    color: @c-white;
    font-size: @font-28;
    font-weight: 500;
    margin-right: 1rem;
  }

  span.vertical-divider {
    height: 1.5rem;
    margin-left: 1rem;
    border-left: 1px solid @c-grey-border;
    margin-right: 2rem;
  }

  span.vat-number-text {
    color: @c-white;
    font-weight: 400;
    font-size: @font-16;

    span.user-vat-number {
      font-weight: 600;
      margin-left: 1rem;
    }
  }
}

div.user-details-body {
  background: @c-grey-default;
  min-height: 80%;

  margin: 1rem 3rem;
  border-radius: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;

  div.user-details-main-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;

    margin-bottom: 1rem;

    span.form-title {
      color: @c-text-primary;
      font-weight: 700;
      font-size: @font-24;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    .user-details-form {
      padding: 2rem;
      max-width: min(85%, 100rem);
      .row {
        padding: 0.5rem 0;
      }

      div.field {
        label {
          text-align: left;
        }
      }

      .row.document-loader {
        margin-top: 1rem;
        div.ui.loader::after {
          border-color: @c-text-primary transparent transparent;
        }
      }

      div.file-loaded-box {
        width: 2rem;
        height: 2rem;
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        span.file-number {
          color: @c-white;
          font-weight: 700;
        }
      }

      div.not-loaded {
        background: @c-error;
      }

      div.loaded {
        background: @c-success;
      }

      div.file-name-text {
        height: 2rem;
        width: 25rem;

        display: flex;
        align-items: center;
        justify-content: left;

        margin-left: 1rem;

        font-weight: 500;
        color: @c-text-primary;
        font-size: @font-16;
      }

      img.image.download-file-icon {
        width: 2rem;

        border: 1px solid @c-text-primary;
        border-radius: 4px;

        cursor: pointer;
      }

      div.empty-image {
        width: 2rem;
      }
    }

    button.deleteButton {
      margin: 1rem 0rem;
    }
  }

  div.user-details-footer {
    margin-top: auto;

    background: @c-white;

    height: 5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem 2rem;

    div.footer-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: min(85%, 100rem);
    }
  }

  .documents-table {
    width: 100%;
  }
}
