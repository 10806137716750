.select-order-table-container {
  width: 100%;

  table.ui.celled.table {
    border: 0;
  }

  .ui.celled.table tr th,
  .ui.celled.table tr td {
    border-left: 0 !important;
  }
  th.ui.celled.table {
    border-bottom: 1px solid rgba(120, 120, 120, 0.24);
  }

  th {
    background: @c-primary !important;
    padding: 0.5rem 0.5rem 0.3rem !important;

    .select-order-table-header {
      text-align: center;
      font-size: @font-12;
      color: @c-white;
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  .select-order-table {
    &-orderNumber {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .order-number {
        font-weight: 500;
        color: @c-text-primary;
      }

      .line-no {
        font-weight: 400;
        font-size: @font-14;
        color: @c-text-primary;
      }
    }

    &-residual-quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &-icon {
        display: flex;
        align-items: baseline;
        justify-content: center;

        text-align: center;
        font-size: @font-12;
        color: @c-text-primary;
        text-transform: uppercase;
        font-weight: 400;
        svg {
          margin-right: 0.5rem;
        }
      }

      &-value {
        text-align: center;
        font-size: @font-16;
        color: @c-text-primary;
        font-weight: 700;
      }
    }

    &-item {
      font-weight: 600;
      font-size: @font-14;
      text-align: center;
    }

    &-delivery-date {
      text-align: center;
      font-size: @font-14;
      color: @c-text-primary;
      text-transform: uppercase;
      font-weight: 400;
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: center;

      .MuiCheckbox-colorPrimary.Mui-checked {
        color: @c-secondary !important;
      }

      .MuiCheckbox-colorPrimary.Mui-disabled {
        color: rgba(0, 0, 0, 0.06);
      }
    }
  }

  // Empty table section
  .select-order-table-empty {
    padding: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-weight: 500;
    font-size: @font-12;
  }
}
