.ui.pagination.menu.paginator {
  color: @c-primary;
  min-height: auto;
  background-color: transparent;

  border: 0px;
  box-shadow: none;

  .item {
    box-shadow: none;
    background-color: @c-white;
    border-radius: 2px !important;
    max-height: 2.5rem;
    margin-left: 0.4rem;
    color: @c-primary;
    font-size: @font-14;
    font-weight: 500;

    &::before {
      background: none;
    }
  }

  .active.item {
    background-color: @c-primary;
    color: @c-white;
    font-weight: 600;
  }
}
