.signup {
  &-type {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-logo {
      width: 12rem;
      margin-top: 2.5rem;
      margin-bottom: 3rem;
    }

    &-title {
      font-weight: 700;
      font-size: @font-24;
      color: @c-text-primary;
      margin-bottom: 2rem;
    }

    &-buttons {
      display: flex;
      flex-direction: row;

      .type-button {
        width: 15rem;
        height: 4rem;
        border: 1px solid @c-primary;
        border-radius: 2px;
        margin: 1rem;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        &-text {
          font-weight: 600;
          font-size: @font-16;
          color: @c-text-primary;
        }
      }

      .type-button:hover {
        background-color: @c-secondary;
        border: 2px solid @c-secondary;
      }
    }
  }

  &-dataform {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-logo {
      width: 16rem;
      margin-top: 2.5rem;
      margin-bottom: 4rem;
    }

    &-title {
      color: @c-text-primary;
      font-weight: 400;
      font-size: @font-24;

      span.bold-text {
        font-weight: 700;
      }
    }

    &-form {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 2rem 8rem 0rem 8rem;

      .selection.ui.dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
      }

      .signup-input {
        &-row {
          width: 100%;
          display: flex;
          align-items: top;
          justify-content: space-between;
          margin-bottom: 1rem;
        }

        &-container {
          width: 48%;
          text-align: left;
        }
      }

      .field {
        width: 100%;
        margin-bottom: 0.5rem !important;
        > label {
          text-align: left;
          font-size: @font-14;
          font-weight: 400;
          color: @c-text-primary;
          margin-left: 2px;
        }
      }

      .signup-button-container {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
      }
    }
  }

  &-documentform {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-logo {
      width: 16rem;
      margin-top: 2.5rem;
      margin-bottom: 4rem;
    }

    &-title {
      color: @c-text-primary;
      font-weight: 400;
      font-size: @font-24;

      span.bold-text {
        font-weight: 700;
      }
    }

    &-form {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 3rem 8rem 0rem 8rem;

      svg {
        margin-top: 1.6rem;
        margin-right: 0.5rem;
      }
    }
  }

  &-completed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    &-msm-logo {
      position: fixed !important;
      top: 3.5rem;
      max-width: unset !important;

      img {
        width: 15rem;
      }
    }

    &-title {
      margin-top: 3rem;
      font-weight: 700;
      font-size: @font-22;
      color: @c-text-primary;
    }

    &-subtitle {
      padding-top: 1.5rem;
      font-weight: 400;
      font-size: @font-16;
      color: @c-text-primary;
    }
  }
}
