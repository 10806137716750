.customer-article-phase-container {
  width: 100%;

  .customer-orgins-select-container {
    margin-bottom: 2rem;

    .selection.ui.dropdown {
      display: flex;
      align-items: center;
      justify-content: space-between;

      max-width: 40rem;
    }
  }

  // Empty table section
  .customer-articlePhase-table-empty {
    padding: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-weight: 500;
    font-size: @font-13;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: @c-secondary !important;
  }

  .MuiCheckbox-colorPrimary.Mui-disabled {
    color: rgba(0, 0, 0, 0.06);
  }

  .customer-articlePhase-table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.8rem;

    font-weight: 600;
    font-size: @font-16;
    color: @c-text-primary;
  }
}
