.file-input-field {
  text-align: left;
  width: 30rem;

  .input-field-label {
    color: @c-text-primary;
    text-align: left !important;
    font-weight: 400;
    font-size: @font-14;
    padding-bottom: 4px !important;
    text-align: left;
  }

  .field {
    background-color: @c-white;
  }

  .fileNotSet {
    cursor: pointer;
  }

  .fileUploadField {
    border: 1px solid @c-grey-border;
    text-align: left !important;

    button {
      height: 46px;
      padding: 0.7rem !important;
      background-color: transparent !important;
    }

    .crossButton {
      padding: 0 !important;
      .crossIcon {
        padding: 0 !important;
        color: @c-primary;
        opacity: 1 !important;
      }
    }

    .labelGrid {
      font-family: @sans-serif;
      padding-left: 1rem;

      .placeholder {
        font-size: @font-16;
        color: @c-grey-mid;
        opacity: 0.5;
        vertical-align: middle;
      }

      .fileUploaded {
        font-size: @font-16;
        font-weight: 500;
        color: @c-text-primary;
      }
    }
  }

  .disabled.field.fileUploadField {
    button {
      opacity: 0.5;
    }
  }
}
