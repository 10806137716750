#app {
  .reservations {
    &-page-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin: 0rem 2rem;
      padding: 1.5rem 0rem;

      // Title style
      span.title {
        font-family: @sans-serif;
        color: @c-white;
        font-size: @font-28;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
    }

    &-container {
      margin: 0rem 3rem;
    }
  }

  .reservations-container-segment {
    background-color: @c-grey-default;
    min-width: 75rem;
  }
}