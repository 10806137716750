div.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  // Relative dimension of main-logo in login page
  img.image {
    width: 70%;
    margin-bottom: 2em;
  }

  .ui.grid.login-form {
    // Password recovery link style
    .column.password-recovery-column {
      padding: 1rem 0 2rem 0;

      .password-recovery {
        text-align: right;
        margin-top: -4px;
        margin-bottom: 15px;
        font-family: @standard-font;
        font-style: normal;
        font-weight: bold;
        font-size: @font-14;
        line-height: 18px;
        text-decoration-line: underline;

        > a {
          color: @c-text-primary !important;
        }
      }
    }

    // Set div width to parent container
    div.hybridauth-container {
      width: 100%;
    }

    // Office365 Login button
    button.microsoft-button {
      font-family: @standard-font;
      font-weight: 700;
      text-align: center;
      color: #fff;
      background-color: @c-text-primary;
      border-radius: 0 !important;
      margin: 0;

      &:hover,
      &:focus,
      &:active {
        background-color: @c-text-primary;
        color: #fff;
      }
    }

    // Password recovery link style
    .column.new-user-column {
      margin-top: 0.5rem;
      .register {
        text-align: center;
        font-family: @standard-font;
        font-style: normal;
        font-size: @font-16;
        line-height: 18px;

        > a {
          color: @c-text-primary !important;
        }
      }
    }
  }
}
