.upload-document-modal {
  /** Header */
  &-header {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }
  }

  /** Subtitle text */
  div.noPaddingTop.content {
    padding-top: 0rem !important;
  }

  &-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-form {
    padding: 0rem 1.5rem;
    margin-bottom: 2rem;

    .date-input {
      //position: fixed;
      display: flex;
      align-items: center;
    
      .calendar-modal-button {
        cursor: pointer;
        position: absolute;
        right: 0.5rem;

        border-radius: 100%;
        padding: 0.5rem;
      }

      .calendar-modal-button:hover {
        background: @c-grey-light-darker;
      }
    }

    .field {
      margin-bottom: 0.5rem !important;
    }

    .input-container {
      margin-bottom: 1rem;
    }

    .file-error {
      line-height: 1.5rem;
    }
  }

  /** field of the fileUploadField */
  div.field.fileUploadField.fileNotSet {
    margin-bottom: 0.2rem !important;
    margin-top: 0.2rem;
  }

  /** crossIcon */
  button.ui.button.crossIcon {
    float: right;
    background-color: transparent;
    padding: 0.5rem !important;
  }
}
