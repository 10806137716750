.agent-dashboard-container {
  background: @c-grey-default;
  margin: 1.5rem 3rem;
  height: 80%;

  border-radius: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .selected-user-data-card {
    width: min(80%, 40rem);
    padding: 1rem 2rem;
    border-radius: 16px;

    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: @c-white;

    display: flex;
    flex-direction: column;

    .text-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin-bottom: 1rem;

      .title {
        text-align: center;
        font-weight: 600;
        font-size: @font-22;
        color: @c-text-primary;
        line-height: 1.5;
      }
    }

    .ui.selection.dropdown {
      width: 100%;
    }

    &-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;

      font-weight: 500;
      font-size: @font-16;
      color: @c-text-primary;

      svg {
        margin-right: 1rem;
      }
    }
  }
}

div.empty-supplier-dashboard {
  background: @c-grey-default;
  margin: 1.5rem 3rem;
  height: 80%;

  border-radius: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img.image {
    width: 7rem;
    margin-bottom: 3rem;
  }

  div.text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: 60%;

    margin-bottom: 2rem;

    div.title {
      text-align: center;
      font-weight: 700;
      font-size: @font-24;
      color: @c-text-primary;
      line-height: 1.5;
    }

    div.subtitle {
      text-align: center;
      font-weight: 500;
      font-size: @font-20;
      color: @c-text-primary;
      margin-top: 1rem;
    }
  }

  button.ui.button {
    margin: 0rem;
    height: 3.5rem;
  }
}

div.supplier-dashboard {
  margin: 1.5rem 3rem;

  .dashboard-reservation-card {
    cursor: pointer;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.5rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;

    border-bottom: 1px solid #d6d6d3;

    span {
      display: flex;
      align-items: center;
    }

    .dashboard-reservation-card-date {
      width: 220px;
      display: flex;
      flex-direction: column;

      .delivery-date {
        font-weight: 600;
        font-size: @font-16;
      }

      .delivery-timeslot {
        font-weight: 500;
        font-size: @font-14;
      }
    }

    .dashboard-reservation-card-vehcilePlate {
      display: flex;
      flex-direction: column;

      font-weight: 600;
      font-size: @font-16;
    }

    .dashboard-reservation-card-bookingNumber {
      font-weight: 500;
      font-size: @font-14;
      text-decoration: underline;
    }
  }

  .booking-calendar-segment {
    position: relative;

    .link-approved-reservation {
      padding: 0.3rem;
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;

      font-weight: 600;
      color: @c-primary;
      text-decoration: underline;

      cursor: pointer;
    }
  }

  div.calendar-header-dashboard {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 1rem;
    margin-bottom: 1rem;

    img.ui.image {
      width: 3rem;
    }

    span.title-text {
      color: @c-primary;
      font-size: @font-22;
      font-weight: 700;
      margin-left: 0.5rem;
    }

    .calendar-icon {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .booking-calendar-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-top: 3rem;

    &-text {
      padding-top: 1rem;
      font-size: @font-16;
      font-weight: 600;
    }
  }
}
