#app [class*='App__Wrapper'] {
  padding-top: 4.625rem;
  font-family: @sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;

  > div:not(.topbar-menu.menu) {
    padding: 0 !important;
  }

  // CONTENT WRAPPER
  .contentGrid,
  .executionContent {
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
}

// Remove standard margin on body
body {
  margin: 0;
}

div#app {
  height: 100%;
  overflow-y: auto;
}

.default-error-message {
  font-size: @font-14;
  color: @c-error;
  font-weight: 400;
}

// Main style for loading page
div.ui.page.dimmer.loading-page {
  background: @c-white-background;

  // Move the main logo over the loader
  img.image {
    padding-bottom: 13rem;
  }

  .ui.dimmer, .ui.loader:after {
    border-color: @c-secondary transparent transparent;
  }

  div.main-text {
    margin-top: 0.5rem;
    color: @c-text-primary;
    font-weight: 400;
    font-size: @font-22;
  }

  div.subtitle-text {
    margin-top: 0.5rem;
    color: @c-text-primary;
    font-weight: 400;
    font-size: @font-14;
  }
}

iframe {
  pointer-events: none;
}