.metal-resume-tables-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: @c-grey-default;
  min-width: 70rem;
  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;

  .metal-resume-loading {
    padding: 0.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-weight: 500;
    font-size: @font-12;
  }

  .metal-resume-title {
    color: @c-text-primary;
    font-size: @font-24;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 0rem 1rem;
    margin: 1rem 0;
  }

  .second-title {
    margin-top: 2rem;
  }

  .arrow-rotate-up {
    transform: rotate(180deg);
  }

  .actionMenu-button {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: @c-grey-light;
    padding: 0.6rem;
    border-radius: 8px;
    border: 1px solid @c-grey-mid;

    cursor: pointer;
  }

  .metal-resume-collapsed {
    tbody > :nth-child(n + 6) {
      visibility: collapse;
    }
  }

  .metal-resume-table-footer {
    margin-top: 1rem;
    cursor: pointer;
    font-weight: 600;
    font-size: @font-14;
    text-decoration: underline;
  }
}
