@font-face {
  font-family: 'Futura Std';
  src: url('/src/assets/fonts/FuturaStd-Book.woff') format('woff');  
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('/src/assets/fonts/FuturaStd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('/src/assets/fonts/FuturaStd-Heavy.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('/src/assets/fonts/FuturaStd-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}