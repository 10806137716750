div.contact-origin-header {
  display: flex;
  align-items: center;

  padding: 1.5rem 2rem;

  span.contact-origin-complete-name {
    color: @c-white;
    font-size: @font-28;
    font-weight: 500;
    margin-right: 1rem;
  }
}

div.contact-origin-details-body {
  background: @c-grey-default;
  min-height: 80%;

  margin: 1rem 3rem;
  border-radius: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;

  div.contact-origin-details-main-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;

    margin-bottom: 1rem;

    span.form-title {
      color: @c-text-primary;
      font-weight: 700;
      font-size: @font-24;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    .contact-origin-details-form {
      padding: 2rem;
      max-width: min(85%, 100rem);
      .row {
        padding: 0.5rem 0;
      }

      div.field {
        label {
          text-align: left;
        }
      }

      .documents-table {
        width: 100%;
      }
    }

    div.ui.selection.dropdown {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .document-row {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .input-field-error-message {
        padding-top: 0.5rem;
      }
    }
  }

  div.contact-origin-details-footer {
    margin-top: auto;

    background: @c-white;

    height: 5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem 2rem;

    div.footer-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: min(85%, 100rem);
    }
  }

  .origin-document-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    color: @c-text-primary;
    font-weight: 700;
    font-size: @font-24;
  }

  .new-origin-required-field {
    font-weight: 600;
    font-size: @font-16;
    color: @c-error;
    text-decoration: underline;
  }
}
