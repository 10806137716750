// Background-color for the grid
.ui.bottom.attached.segment.active.tab {
  background-color: @c-grey-default;
  min-width: 53rem;
}

// Background-color for the not selected tab
.ui.tabular.menu .item {
  background-color: rgba(242, 242, 242, 0.5) !important;
  margin-right: 1rem;
}

// Background-color for the selected tab
.ui.tabular.menu .active.item {
  background-color: @c-grey-default !important;
}

.ui.attached.tabular.menu {
  border-bottom: 0px;
}

div.user-requests-content {
  margin: 1rem 3rem;
}

div.ui.page.modals {
  .user-details-form {
    .row {
      padding: 0.5rem 0;
    }

    div.field {
      label {
        text-align: left;
      }
    }

    span.form-title {
      color: @c-text-primary;
      font-weight: 400;
      font-size: @font-24;
      margin-top: 2rem;
    }

    .row.document-loader {
      margin-top: 1rem;
      div.ui.loader::after {
        border-color: @c-text-primary transparent transparent;
      }
    }

    div.file-loaded-box {
      width: 2rem;
      height: 2rem;
      border-radius: 4px;

      display: flex;
      align-items: center;
      justify-content: center;

      span.file-number {
        color: @c-white;
        font-weight: 700;
      }
    }

    div.not-loaded {
      background: @c-error;
    }

    div.loaded {
      background: @c-success;
    }

    div.file-name-text {
      height: 2rem;
      width: 25rem;

      display: flex;
      align-items: center;
      justify-content: left;

      margin-left: 1rem;

      font-weight: 500;
      color: @c-text-primary;
      font-size: @font-16;
    }

    img.image.download-file-icon {
      width: 2rem;

      border: 1px solid @c-text-primary;
      border-radius: 4px;

      cursor: pointer;
    }

    div.empty-image {
      width: 2rem;
    }
  }
}
