.ui.label {
  /** Set label background to transparent */
  &.labelTransparent {
    background-color: transparent;
  }

  /** The label will file the container space */
  &.maxWidthHeight {
    width: 100%;
    height: 100%;
  }
}
