.reservation-card {
  height: 6rem;
  background-color: @c-white;
  border-radius: 3px;

  margin: 8px 6px;
  flex-grow: 1;
  display: inline-block;
  width: calc(100% * (1 / 2) - 12px - 1px);
  max-width: calc(100% * (1 / 2) - 12px - 1px);

  padding: 0.5rem;

  cursor: pointer;

  &-content {
    height: 5rem;
    width: 100%;

    display: flex;
    justify-content: space-between;

    &-description {
      display: flex;
      flex-direction: column;

      .card-description {
        &-orderNumber {
          display: flex;
          align-items: center;

          font-weight: 500;
          color: #1c1c1c;
          font-size: @font-14;

          svg {
            margin-right: 0.5rem;
          }
        }

        &-descriptionText {
          padding-top: 0.5rem;
          height: 3.5rem;

          font-weight: 600;
          color: @c-primary;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    &-weight {
      display: flex;
      align-items: flex-end;

      .card-weight {
        &-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 5rem;
          padding: 0.3rem;

          background-color: #f5f5f5;
          border-radius: 2px;
        }

        &-unit {
          font-weight: 500;
          color: #989898;
          font-size: @font-10;
          text-transform: uppercase;
        }

        &-value {
          font-weight: 500;
          color: #1c1c1c;
          font-size: @font-16;
        }
      }
    }
  }
}

.closed {
  border-left: 0.4rem solid #787878;
}

.to_approve {
  border-left: 0.4rem solid #e00000;
}

.approved {
  border-left: 0.4rem solid #36b359;
}
