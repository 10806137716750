// Tooltip icon
.question.circle.icon.tooltip-password-icon {
  color: @c-secondary;
  margin-left: 0.5rem;
}

// Tooltip content
.ui.popup.tooltip-password {
  background: white !important;

  .tooltip-password-content {
    color: black !important;
    font-size: @font-14 !important;

    li.tooltip-item {
      white-space: nowrap;
    }
  }
}
