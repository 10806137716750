.contact-origins-list {
  width: 100%;

  table.ui.celled.table {
    border: 0;
  }

  .ui.celled.table tr th,
  .ui.celled.table tr td {
    border-left: 0 !important;
  }
  th.ui.celled.table {
    border-bottom: 1px solid rgba(120, 120, 120, 0.24);
  }

  .ui.table td {
    padding: 0.6rem !important;
  }

  th {
    background: @c-primary !important;
    padding: 0.5rem 0.5rem 0.3rem !important;

    .origins-table-header {
      font-size: @font-12;
      color: @c-white;
      text-transform: uppercase;
      font-weight: 600;
    }

    .origins-table-header-center {
      font-size: @font-12;
      color: @c-white;
      text-transform: uppercase;
      font-weight: 600;

      display: flex;
      justify-content: center;
    }
  }

  .origins-table {
    &-businessName {
      display: flex;
      justify-content: center;
      flex-direction: column;

      font-size: @font-14;
      color: @c-primary;

      &-name {
        font-weight: 600;
      }
    }

    &-approved,
    &-email {
      display: flex;
      justify-content: center;

      font-size: @font-14;
      color: @c-primary;

      &-check,
      &-cross,
      &-pending {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        padding: 0.4rem;
      }

      &-check {
        background: @c-success;
      }

      &-cross {
        background: @c-error;
      }

      &-pending {
        background: @c-secondary;
      }
    }

    &-actionMenu {
      display: flex;
      align-items: center;
      justify-content: center;

      &-view-button {
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        padding: 0.4rem;

        border-radius: 12px;
        background: @c-grey-light;
      }
    }
  }

  .contact-origins-list-paginator-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding-top: 1rem;
  }
}
