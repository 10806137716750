/* COMMON FORM ELEMENTS
  ----------------------------- */
div.ui.label.dropdown[role='listbox'],
div:not(.select__input) > input[type='number'],
div:not(.select__input) > input[type='text'],
div:not(.select__input) > input[type='password'],
.select__control,
textarea {
  position: relative;
  background: transparent;
  border: 1px solid @c-grey-border !important;
  border-radius: 0 !important;
  line-height: normal !important;

  .text {
    font-size: @font-18;
    font-weight: 500;
    color: @c-primary;
  }
}

textarea:read-only,
input:read-only,
.ui.disabled.search {
  background: transparent !important;
  cursor: default;
}

.ui.form .disabled.field,
.ui.form .field.disabled > label,
.ui.disabled.search {
  opacity: 1 !important;
}

// Disable textarea resizable
.ui.form {
  textarea {
    resize: none;
  }
}

input[type='number'],
input[type='text'],
input[type='password'] {
  padding: 0.8rem 2rem 0.65rem 1rem !important;
  line-height: normal !important;
}

.ui.form .field > .hQllqh {
  width: 100%;
  padding: 0 !important;
}

div.error-message {
  font-size: @font-12;
  color: @c-error;
  margin-top: -0.5rem;
}

div.ui.selection.dropdown {
  padding: 0.8rem 1rem 0.65rem 1rem !important;
  line-height: normal !important;

  border: 1px solid @c-grey-border !important;
  border-radius: 0 !important;

  i.dropdown.icon {
    height: 100%;
    display: flex;
    align-items: center;
  }

  div.divider.text:not(.default) {
    color: @c-text-primary;
  }

  div.visible.menu {
    border-radius: 0;
    border: 1px solid @c-grey-border;
    border-top: 0;
  }

  div.active.selected.item {
    span.text {
      font-weight: 600;
    }
  }
}

/* LABEL
  ----------------------------- */
.ui.form .field label[for] {
  font-size: @font-14;
  font-weight: 500;
  color: @c-grey-mid;
  opacity: 1;
}

/* INPUT
  ----------------------------- */
input[type='number'],
input[type='text'],
input[type='hidden'],
input[type='password'] {
  color: @c-input-field !important;

  &:disabled,
  .field.disabled & {
    background: @c-grey-light !important;
    opacity: 0.75;
  }
}

/* TEXTAREA
  ----------------------------- */
textarea {
  font-family: @sans-serif;
}

/* SELECT
  ----------------------------- */
.select__control {
  .field.disabled & {
    background: @c-grey-light !important;
    opacity: 0.75;
  }

  .select__value-container {
    padding: 0;

    .select__single-value {
      padding: 0.8rem 2rem 0.65rem 1rem;
      width: 100%;
    }

    > div {
      margin: 0;
      padding: 0;

      input {
        border: 0;
        width: 100% !important;
      }
    }
  }
}

.select__menu {
  height: 12.5rem;
  overflow: hidden;

  > * {
    height: 100%;
  }
}

/* DATEPICKER
  ----------------------------- */
.formik-date-wrapper {
  border: 1px solid rgba(128, 144, 160, 0.65) !important;
  border-radius: 0 !important;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  font-size: 14px;
  width: 100%;
  padding: 0 !important;

  .MuiTextField-root {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;

    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      padding: 0.8rem 2rem 0.65rem 1rem !important;
      border: 0 !important;
    }

    .MuiButtonBase-root.MuiIconButton-root {
      padding: 0 0.25rem;
    }

    input {
      width: '85px';
      border: 0px solid !important;
    }

    button {
      border-radius: 0;
    }

    button:hover {
      background: transparent;
    }

    button:focus {
      background: transparent;
    }

    .MuiTouchRipple-root {
      display: none;
    }

    label {
      display: none;
    }

    .MuiInput-formControl {
      margin-top: 0;
    }

    p.Mui-error {
      position: absolute;
      bottom: -38px;
    }
  }
}

/* SEARCH ICON
  ----------------------------- */
i.icon.search.link.inverted.grey {
  opacity: 1;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: @c-grey-light !important;
  color: @c-primary !important;
  border-radius: 0.25rem !important;
}

/* DROPDOWN
  ----------------------------- */
.ui.selection.dropdown {
  border: 1px solid @c-grey-border;
  border-radius: 0;
  color: #000;

  &:hover {
    border-color: @c-grey-border;
  }

  &.active {
    background: #fff;
    border-color: @c-grey-border;

    i {
      color: #000;
    }
  }
}

.pageHeader {
  .ui.selection.dropdown {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.4);
    border-radius: 0;
    color: #fff;

    .text {
      font-size: @font-18;
      font-weight: 500;
      color: @c-primary;
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.6);
    }

    &.active {
      background: #fff;

      i {
        color: #000;
      }
    }
  }
}

// Upload file form
.fileUploadField {
  border: 1px solid @c-grey-border;
  cursor: pointer;

  button {
    padding: 0.7rem !important;
    background-color: transparent !important;
  }

  .labelGrid {
    font-family: @sans-serif;
    font-size: @font-18;
    color: black;
    opacity: 0.5;
  }
}

div.field {
  > label {
    font-size: @font-14 !important;
    font-weight: 400 !important;
    color: @c-text-primary !important;
    margin-left: 2px !important;
  }
}

/* CHECKBOX

  ----------------------------- */

.ui.checkbox.tableCheckbox {
  height: @checkbox-size;
  width: @checkbox-size;

  label:before {
    background: @c-grey-light !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 0.25rem;
    height: @checkbox-size;
    width: @checkbox-size;
  }

  &.checked {
    label:after {
      content: '' !important;
      height: 0.875rem;
      width: 0.875rem;
      background: @c-primary;
      border-radius: 0.2rem;
      top: 0.25rem;
      left: 0.25rem;
    }
  }

  &.indeterminate {
    label:after {
      left: 50%;
      transform: translate(-50%, 20%);
      color: @c-primary !important;
    }
  }
}

form.ui.form .field {
  // Message
  & + span {
    font-size: @font-12;
  }

  &.error + span {
    color: @c-error;
    margin-top: -0.5rem;
  }
}

div.ui.selection.dropdown.disabled {
  opacity: 1 !important;
  background: transparent !important;
}
