.customer-agent-dashboard-container {
  background: @c-grey-default;
  margin: 1.5rem 3rem;
  min-height: 80%;

  border-radius: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  padding-bottom: 1rem;

  .align-left {
    text-align: left !important;
  }

  .actionMenu-button {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: @c-grey-light;
    padding: 0.6rem;
    border-radius: 8px;
    border: 1px solid @c-grey-mid;

    cursor: pointer;
  }

  .margin-right {
    margin-right: 0.5rem;
  }
}