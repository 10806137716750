.customer-documents-table-empty {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-weight: 500;
  font-size: @font-12;
}

.customer-documents-footer {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  &-totalElements {
    font-size: @font-14;
    color: @c-text-primary;
    font-weight: 600;
  }
}

.customer-documents-container,
.customer-documents-modal {
  width: 100%;

  table.ui.celled.table {
    border: 0;
  }

  .ui.celled.table tr th,
  .ui.celled.table tr td {
    border-left: 0 !important;
  }
  th.ui.celled.table {
    border-bottom: 1px solid rgba(120, 120, 120, 0.24);
  }

  th {
    background: @c-primary !important;
    padding: 0.5rem 0.5rem 0.3rem !important;

    .customer-documents-container-header {
      text-align: center;
      font-size: @font-12;
      color: @c-white;
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  .sortable-header {
    cursor: pointer;
  }

  .sort-icon {
    margin-left: 0.3rem;
  }

  .sort-icon-rotate {
    margin-left: 0.3rem;
    transform: rotate(180deg);
  }

  .customer-documents-table {
    &-documentNumber {
      text-align: center;
      font-weight: 500;
      color: @c-text-primary;
      font-size: @font-14;
    }

    &-standard {
      text-align: center;
      color: @c-text-primary;
      font-weight: 400;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-payment {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: center;

      .actionMenu-button {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: @c-grey-light;
        padding: 0.6rem;
        border-radius: 8px;
        border: 1px solid @c-grey-mid;

        cursor: pointer;
      }

      .margin-right {
        margin-right: 0.5rem;
      }
    }

    &-residual-quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &-icon {
        display: flex;
        align-items: baseline;
        justify-content: center;

        text-align: center;
        font-size: @font-12;
        color: @c-text-primary;
        text-transform: uppercase;
        font-weight: 400;
        svg {
          margin-right: 0.5rem;
        }
      }

      &-value {
        text-align: center;
        font-size: @font-16;
        color: @c-text-primary;
        font-weight: 700;
      }
    }

    &-item {
      font-weight: 500;
      font-size: @font-14;
      text-align: center;
    }

    &-date {
      text-align: center;
      font-size: @font-14;
      color: @c-text-primary;
      text-transform: uppercase;
      font-weight: 500;
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: center;

      .MuiCheckbox-colorPrimary.Mui-checked {
        color: @c-secondary !important;
      }

      .MuiCheckbox-colorPrimary.Mui-disabled {
        color: rgba(0, 0, 0, 0.06);
      }
    }
  }
}

/** DETAILS MODAL */
.customer-documents-modal {
  /** Header */
  &-header {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }
  }

  /** Subtitle text */
  div.noPaddingTop.content {
    padding-top: 0rem !important;
  }

  &-loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /** crossIcon */
  button.ui.button.crossIcon {
    float: right;
    background-color: transparent;
    padding: 0.5rem !important;
  }

  .customer-documents-field-title {
    text-align: center;
    font-size: @font-16;
    color: @c-text-primary;
    font-weight: 600;
  }

  .customer-documents-field-data {
    text-align: center;
    font-size: @font-16;
    color: @c-text-primary;
    font-weight: 500;
  }

  li {
    margin-bottom: 0.4rem;
  }
  .customer-documents-table {
    margin-top: 1rem;
  }
}


.customer-personal-documents {
  background: @c-grey-default;
  min-height: 80%;
  min-width: 70rem;

  padding: 1.5rem;
  border-radius: 2px;

  &-title {
    font-weight: 500;
    font-size: @font-24;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }

  &-subtitle {
    font-weight: 500;
    font-size: @font-18;
  }

  .documents-table-footer {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    .button {
      border-radius: 16px;
    }
  }
}