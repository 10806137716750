.ui.dimmer.calendar-picker-dimmer {
  background-color: rgba(0, 0, 0, 0.05);
}

.ui.modal.calendar-picker-modal {
  box-shadow: 0px 0px 32px rgba(60, 60, 59, 0.18);
  width: 460px !important;
  height: 550px !important;

  .content {
    padding: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .calendar-picker {
      &-close {
        padding: 0.5rem;
        position: fixed;
        right: 0.5rem;
        top: 0.5rem;
        cursor: pointer;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 1.5rem;

        &-text {
          text-transform: capitalize;
          font-weight: 700;
          font-size: @font-18;
          color: @c-primary;
        }

        &-buttons {
          display: flex;
          align-items: center;
          justify-content: center;

          .only-icon-button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 1rem;
            height: 2.5rem;
            width: 2.5rem;
            padding: 0rem !important;
            border-radius: 50% !important;
            background-color: transparent !important;
          }

          .second-button {
            padding-right: 0rem !important;
            margin: 0 !important;
          }

          .only-icon-button:hover {
            background-color: @c-grey-light !important;
          }
        }
      }
    }
  }

  .confirm-date-picker {
    margin-top: 1rem;

    width: 97%;

    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem 0;
    background-color: @c-success;

    font-weight: 600;
    color: @c-white;
    font-size: @font-16;

    cursor: pointer;
  }

  /** ---- REACT DATE PICKER STYLE OVERRIDE ---- */
  .react-datepicker {
    width: 100%;
    border: none;
    border-radius: 0;

    font-family: @sans-serif;
    color: @c-primary;

    .react-datepicker__header {
      border-radius: 0;
      background-color: transparent;

      border: none;
    }

    .react-datepicker__month-container {
      width: 100%;
      float: none;
    }

    .react-datepicker__day,
    .react-datepicker__time-name {
      height: 3.2rem;
      width: 3.2rem;

      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;

      color: @c-primary;
      font-weight: 400;
      font-size: @font-14;
    }

    .react-datepicker__day-name {
      width: 3.2rem;
      height: 1.5rem;

      text-transform: capitalize;

      display: flex;
      justify-content: center;
      align-items: center;

      margin: 0;

      color: @c-primary;
      font-weight: 600;
      font-size: @font-16;
    }

    .react-datepicker__day--disabled {
      text-decoration: line-through;
      background-color: @c-grey-default;
    }

    .react-datepicker__day:hover {
      background-color: @c-grey-light;
      border-radius: 0;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .react-datepicker__day--selected {
      border-radius: 0;
      background-color: @c-secondary;
      font-weight: 600;
    }

    .react-datepicker__day--selected:hover {
      background-color: @c-secondary;
    }

    .react-datepicker__day--keyboard-selected {
      border-radius: 0rem;
      background-color: transparent;
      color: black;
    }

    /** --- BORDER MANAGMENT --- */
    .react-datepicker__month {
      .react-datepicker__week:first-child {
        .react-datepicker__day:first-child {
          border: 1px solid #DFDFDF;
        }

        .react-datepicker__day:not(:first-child) {
          border: 1px solid #DFDFDF;
          border-left: none;
        }
      }
      
      .react-datepicker__week:not(:first-child) {
        .react-datepicker__day:first-child {
          border: 1px solid #DFDFDF;
          border-top: none;
        }

        .react-datepicker__day:not(:first-child) {
          border: 1px solid #DFDFDF;
          border-left: none;
          border-top: none;
        }
      }
    }
  }
}
