div.ui.page.modals {
  div.ui.modal.language-update-modal {

    div.header {
      justify-content: center;
    }
    
    div.content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
