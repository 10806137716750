div.ui.page.modals {
  div.ui.modal.password-update-modal {

    div.content {
      padding: 1rem 2.5rem;

      div.field {
        width: 100%;

        div.ui.input {
          width: 100% !important;
        }
      }
    }
  }
}
