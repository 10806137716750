div.certificates-card {
  margin-top: 2rem;

  display: flex;
  flex-direction: row;

  background: @c-white;
  border-radius: 4px;

  div.resume-left-car {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    position: relative;
    width: clamp(20rem, 20%, 50rem);

    div.subtitle-row {
      margin-top: auto;

      div.subtitle-text {
        font-weight: 700;
        font-size: @font-22;
        color: @c-primary;
      }

      div.subtitle-link {
        align-items: center;
        display: flex;

        span.underline-text {
          margin-right: 2rem;

          cursor: pointer;
          font-weight: 600;
          text-decoration: underline;
          color: @c-text-primary;
        }

        span.link-button {
          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;
        }
      }
    }
  }

  // Border shorter than div
  div.resume-left-car:after {
    content: "";
    background: @c-grey-border;
    position: absolute;
    right: 0;
    top: 2.5rem;
    bottom: 2.5rem;
    width: 1px;
  }

  div.list-certificates-card {
    width: 100%;

    padding-left: 2rem;
    padding-right: 2rem;

    /**
      * Template GRID, use all the available space with minimum element size (5rem in this case)
      * If the next element cannot respect the minimum size it goes on the next line
      */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6rem,1fr));

    div.certificate-element {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 0.5rem;

      img.image {
        width: min(85%, 7rem);
      }

      div.certificate-link {
        margin-top: 1rem;
        cursor: pointer;
        font-weight: 600;
        text-decoration: underline;
        color: @c-text-primary;
      }
    }
  }
}
