.credits-limit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: @c-grey-default;
  min-height: 80%;
  min-width: 70rem;

  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;

  .customerCreditsLimit-loading {
    padding: 0.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-weight: 500;
    font-size: @font-12;
  }

  .credits-limit-main-data {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .credits-limit-card {
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    min-width: 20rem;
    border-radius: 20px;

    .card-title {
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      font-size: @font-16;
      margin-bottom: 0.5rem;
    }

    .card-data {
      text-align: center;
      font-weight: 500;
      text-transform: uppercase;
      font-size: @font-13;
    }
  }

  .expired-creditsLimit {
    font-weight: 600;
    color: @c-error;
  }
}
