.completeOrderTable-contianer {
  width: 95%;

  table.ui.celled.table {
    border: 0;
  }

  .ui.celled.table tr th,
  .ui.celled.table tr td {
    border-left: 0 !important;
  }
  th.ui.celled.table {
    border-bottom: 1px solid rgba(120, 120, 120, 0.24);
  }

  tr:nth-child(even) {
    td {
      background: @c-grey-light;
    }
  }

  th {
    background: @c-primary !important;
    padding: 0.5rem 0.5rem 0.3rem !important;

    .order-table-header {
      text-align: center;
      font-size: @font-12;
      color: @c-white;
      text-transform: uppercase;
      font-weight: 600;
    }

    .sortable-header {
      cursor: pointer;
    }
  }

  .sort-icon {
    margin-left: 0.3rem;
  }

  .sort-icon-rotate {
    margin-left: 0.3rem;
    transform: rotate(180deg);
  }

  .order-table {
    &-amount {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &-icon {
        display: flex;
        align-items: self-end;
        justify-content: center;

        text-align: center;
        font-size: @font-12;
        color: @c-text-primary;
        text-transform: uppercase;
        font-weight: 400;
        svg {
          margin-right: 0.5rem;
        }
      }

      &-value {
        text-align: center;
        font-size: @font-16;
        color: @c-text-primary;
        font-weight: 600;
      }
    }

    &-item {
      font-weight: 400;
      font-size: @font-13;
      text-align: center;
    }

    &-residual-quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &-icon {
        display: flex;
        align-items: self-end;
        justify-content: center;

        text-align: center;
        font-size: @font-12;
        color: @c-text-primary;
        text-transform: uppercase;
        font-weight: 400;
        svg {
          margin-right: 0.5rem;
        }
      }

      &-value {
        text-align: center;
        font-size: @font-16;
        color: @c-text-primary;
        font-weight: 600;
      }
    }

    &-complete-percentage {
      display: flex;
      align-items: center;
      justify-content: center;

      .circular-percentage {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        height: 2.3rem;
        width: 2.3rem;

        span.percentage-text {
          font-weight: 500;
          font-size: @font-12;
        }

        .MuiCircularProgress-colorPrimary {
          color: @c-secondary;

          width: 100% !important;
          height: 100% !important;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }

    &-delivery-date,
    &-line-number {
      text-align: center;
      font-size: @font-14;
      color: @c-text-primary;
      text-transform: uppercase;
      font-weight: 400;
    }

    &-delivery-date.expired-delivery-date {
      color: @c-error;
      font-weight: 600;
      text-decoration: underline;
    }

    &-action {
      font-weight: 600;
      font-size: @font-14;
      text-align: center;
      text-decoration: underline;

      cursor: pointer;
    }
  }
}
