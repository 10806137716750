.typeSelection-container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .typeSelection-title {
    text-transform: uppercase;
    margin-top: 3rem;
    font-weight: 700;
    font-size: @font-22;
    color: @c-text-primary;
    text-align: center;
  }

  .typeSelection-subtitle {
    margin-top: 0.8rem;
    font-weight: 400;
    font-size: @font-16;
    color: @c-text-primary;
    text-align: center;
  }

  .typeSelection-buttonsContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;

    .typeSelection-button {
      width: 15rem;
      height: 4rem;
      border: 1px solid @c-primary;
      border-radius: 2px;
      margin: 1rem;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      font-weight: 600;
      font-size: @font-16;
      color: @c-text-primary;
    }

    .typeSelection-button:hover {
      background-color: @c-secondary;
      border: 2px solid @c-secondary;
    }
  }
}
