/** TOPBAR */
#app .topbar.menu {
  // Color and style
  background-color: @c-white;
  border-bottom: 2px solid @c-secondary;

  // Position and dimension
  border-radius: 0px;
  height: @height-topbar !important;
  left: @width-sidebar; // Topbar start after sidebar
  margin: 0;
  position: fixed !important;
  right: 0;
  z-index: 1; // Always on top

  .header {
    padding: 1rem 1.5rem;
    width: 250px;

    img.image.topbar-logo {
      max-height: 3.5rem;
    }
  }

  .button.topbar-button {
    background-color: transparent;
    border-radius: 0 !important;
    padding: 1rem 1.5rem;
    margin-right: 0 !important;
    margin-left: 0.3rem;
  }

  .button.topbar-button:hover {
    background: @c-standard-focus;
  }

  .dropdown.topbar-dropdown {
    // Text style
    div.divider.text {
      font-weight: 500;
      color: @c-text-primary;
      font-size: @font-16;
    }

    img.ui.image {
      margin-left: 1rem;
    }

    div.menu.transition.visible {
      border-radius: 0;

      .item {
        display: flex;
        align-items: center;
        
        span.text {
          font-weight: 400 !important;
          color: @c-text-primary !important;
          font-size: @font-16 !important;
        }
      }
    }
  }

  .dropdown.topbar-dropdown:hover {
    background: @c-standard-focus;
  }

  .menu-right-element {
    padding: 0;

    .right.menu {
      height: 100%;
    }
    .menu-documentation-download {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 3rem;
    }

    .menu-documentation-download:hover {
      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

    div.dropdown.footer-select-language {
      padding: 0.35rem 0.6rem !important;
    img.image {
      width: 1.6rem;
    }
  }
}
