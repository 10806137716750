.dropdown.standard-dropdown-menu {
  // Dropdown
  div.menu.transition.visible {
    // Shadow for dropdown
    box-shadow: 4px 4px 32px rgba(120, 120, 120, 0.4);
  }
  // Margin for button that opens dropdown
  img.ui.image {
    margin: 1rem;
  }

  div.tab {
    margin: 1rem 0 !important;
  }
}