div.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  img.image {
    width: 70%;
    margin-bottom: 2em;
  }
}
