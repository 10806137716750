.reservation-accordion {
  margin: 1rem;
  width: 95%;
  background: @c-white;
  border: 1px solid rgba(120, 120, 120, 0.24);

  .reservations-accordion-actionMenu {
    display: flex;
  }

  .standard-dropdown-menu {
    margin-left: 0.7rem;
    padding: 0.4rem;

    border-radius: 12px;
    background: @c-grey-light;

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  &-title {
    padding: 1rem 2rem !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .reservation-vehiclePlate {
      &-section {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }

      &-title {
        font-size: @font-12;
        color: @c-text-primary;
        text-transform: uppercase;
        font-weight: 400;
      }

      &-value {
        font-weight: 700;
        font-size: @font-18;
      }
    }

    .reservation-date {
      &-section {
        display: flex;
        align-items: center;
        flex-direction: row;
      }

      &-value {
        color: @c-text-primary;
        font-weight: 500;
        font-size: @font-16;
      }

      &-bold {
        font-weight: 700;
      }
    }

    img.reservation-accordion-icon {
      padding: 0.5rem;
    }

    img.accordion-open {
      transform: rotate(180deg);
    }
  }

  &-content {
    padding: 0.5rem 2rem 1rem !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-loading {
      font-size: @font-12;
      color: @c-text-primary;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 1rem;
    }
  }
}
