.reservations-list {
  background: @c-grey-default;
  min-height: 80%;
  min-width: 70rem;

  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 2px;

  .reservation-accordion-content-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
  }

  .reservation-paginator-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding-top: 1rem;
  }
}
