.reservation-filters {
  margin-left: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  .field {
    margin: 0 !important;
    margin-right: 1rem !important;
  }

  .reservation {
    &-number-input {
      background-color: @c-white;
      width: 13rem;
      margin-right: 2rem !important;
      > input[type='text'] {
        border: 1px solid rgba(120, 120, 120, 0.24) !important;
        border-radius: 2px !important;
        padding: 0.8rem 1rem 0.65rem 1rem !important;
      }
    }

    &-filters-search,
    &-filters-search:hover {
      margin-right: 1.5rem !important;
      background-color: @c-success !important;
      border-radius: 2px !important;
      color: @c-white !important;
    }

    &-filters-search:hover {
      background-color: @c-success-hover !important;
    }

    &-filters-reset {
      padding: 1rem !important;
    }
  }
}
