/** Put the semantic table grid into a div with this class to set the standard layout */
.semantic-table-grid-standard {
  width: 95%;

  table.ui.celled.table {
    border: 0;
  }

  .ui.celled.table tr th,
  .ui.celled.table tr td {
    border-left: 0 !important;
  }
  th.ui.celled.table {
    border-bottom: 1px solid rgba(120, 120, 120, 0.24);
  }

  tr:nth-child(even) {
    td {
      background: @c-grey-light;
    }
  }

  th {
    background: @c-primary !important;
    padding: 0.5rem 0.5rem 0.3rem !important;

    text-align: center !important;
    font-size: @font-12 !important;
    color: @c-white !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;

    .sortable-header {
      cursor: pointer;
    }
  }

  .sort-icon {
    margin-left: 0.3rem;
  }

  .sort-icon-rotate {
    margin-left: 0.3rem;
    transform: rotate(180deg);
  }

  td {
    text-align: center !important;
    font-size: @font-14;
    color: @c-text-primary;
    font-weight: 400;
  }
}
