div.MuiDialog-root {
  .MuiPaper-rounded {
    border-radius: 0;
  }

  div.MuiPickersSlideTransition-transitionContainer {
    div {
      div.MuiPickersCalendar-week {
        div[role='presentation'] {
          border-right: 1px solid #dfdfdf;
          border-top: 1px solid #dfdfdf;
        }

        div[role='presentation']:first-child {
          border-left: 1px solid #dfdfdf;
        }
      }
      div.MuiPickersCalendar-week:last-child {
        div[role='presentation'] {
          border-bottom: 1px solid #dfdfdf;
        }
      }
    }
  }
}
