div.password-recovery-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  img.image {
    width: 70%;
    margin-bottom: 2em;
  }

  // Home Login button
  button.home-button {
    font-family: @standard-font;
    font-weight: 700;
    text-align: center;
    color: #fff;
    background-color: @c-text-primary;
    border-radius: 0 !important;
    margin: 0;

    &:hover,
    &:focus,
    &:active {
      background-color: @c-text-primary;
      color: #fff;
    }

    > a {
      color: #fff;
    }
  }

  .password-recovery-completed-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 2rem;
    img.image {
      width: 3rem;
    }

    span.title-text {
      // padding-top: 1rem;
      font-weight: 400;
      font-size: @font-16;
      color: @c-text-primary;
      white-space: nowrap;
    }
  
    span.subtitle-text {
      padding-bottom: 1.5rem;
      font-weight: 400;
      font-size: @font-12;
      color: @c-text-primary;
      white-space: nowrap;
    }
  }
}
